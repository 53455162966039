import './LeftIndex.scss'

import { animated, useTransition } from 'react-spring'

import React from 'react'
import KiteBorder from '../Icons/KiteBorder'

const LeftIndex = ({ show, section }) => {
  const sections = Array.from(Array(12).keys())
  const sectionColors = ['orange', 'dark-blue', 'green', 'light-blue', 'red', 'dark-green', 'purple', 'dark-teal', 'brown', 'yellow', 'blue']
  const transition = useTransition(show, (s) => s, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      {transition.map(
        ({ item, props }, i) =>
          item && (
            <animated.ul key={`left-index-${i}`} className="LeftIndex nosearch" style={props}>
              {sections
                ?.filter((r) => r > 0)
                .map((s) => (
                  <li key={`left-index-item-${s}`} className={`index-item ${section === s ? 'big' : 'small'}`}>
                    <KiteBorder color={sectionColors[section - 1]} />
                    <div className={`section-number montserrat bold ${section === s ? 'show' : 'hide'}`}>{s}</div>
                  </li>
                ))}
            </animated.ul>
          )
      )}
    </>
  )
}

export default LeftIndex
