import React from 'react'
import { colorToHex } from '../../data'

const KiteBorder = ({ color = 'orange', width = 34 }) => {
  return (
    <svg width={width} height={width * (31 / 34)} viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0459 27.0994L29.1738 19.2602L30.5 18.6902L30.1466 17.2821L27.0844 4.69648L26.6816 3.01533L25.0005 3.41808L12.7992 6.40116L11.7027 6.67112L11.5259 7.79192L8.68369 25.2276L8.17682 28.3214L11.0459 27.0994Z"
        fill="white"
        stroke={colorToHex(color)}
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default KiteBorder
