import './Home.scss'

import * as lottie_loader from '../../lotties/Loading/loader.json'

import React, { useEffect, useRef, useState } from 'react'

import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
import Lottie from 'react-lottie'
import { useGesture } from 'react-use-gesture'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import DotsGrill from '../../components/DotsGrill/DotsGrill'
import Kite from '../../components/Icons/Kite'
import LeftIndex from '../../components/LeftIndex/LeftIndex'
import Menu from '../../components/Menu/Menu'
import Modal from '../../components/Modal/Modal'
import MouseIcon from '../../components/MouseIcon/MouseIcon'
import VerticalWaves from '../../components/VerticalWaves/VerticalWaves'
import useScreenSize from '../../hooks/screen-size'
import UAMFirstSteps from '../1-UAMFirstSteps/UAMFirstSteps'
import AfterGrade from '../10-AfterGrade/AfterGrade'
import Utilities from '../11-Utilities/Utilities'
import DigitalEnvironment from '../2-DigitalEnvironment/DigitalEnvironment'
import StudiesCenter from '../3-StudiesCenter/StudiesCenter'
import WhoCanHelp from '../4-WhoCanHelp/WhoCanHelp'
import Resources from '../5-Resources/Resources'
import LiveAutonoma from '../6-LiveAutonoma/LiveAutonoma'
import ManageDayByDay from '../7-ManageDayByDay/ManageDayByDay'
import ProfessionalProfile from '../8-ProfessionalProfile/ProfessionalProfile'
import Norms from '../9-Norms/Norms'

const Home = () => {
  const scrollable = useRef()
  const { isDesktop } = useScreenSize()
  const [isOpen, setIsOpen] = useState(false)
  const [menuOffset, setMenuOffset] = useState(0)
  const [initOffset, setInitOffset] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState()
  const [lock, setLock] = useState(false)
  const [loader, setLoader] = useState(true)
  const [titleOffset, setTitleOffset] = useState(70)
  const [coord, setXY] = useState([0, 0])

  const logo_main = (section) => {
    return `translate3d(${coord[0] ? (coord[0] - window.innerWeight * section) * -0.1 : 0}px, ${
      coord[1] ? (coord[1] - window.innerHeight * section) * -0.1 : 0
    }px, 0px)`
  }

  useEffect(() => {
    // Lock the default scroll wheel behaviour for the main scrollable element
    const body = scrollable.current
    if (body && !lock) {
      const onWheel = (e) => e.preventDefault()
      body.addEventListener('wheel', onWheel)
      return () => body.removeEventListener('wheel', onWheel)
    }
  }, [lock])

  let initialLock = 0
  const pageHeight = document.getElementById('root').offsetHeight
  const bind = useGesture({
    onWheel: (e) => {
      // On desktop, the mouse wheel scrolls horizontally
      const { movement, last } = e
      if (isDesktop && !lock && !last) {
        scrollable.current.scrollLeft += (movement[0] + movement[1]) / 4
      }
    },
    onScroll: (e) => {
      const { last, xy } = e
      if (!lock) {
        initialLock = 0
        // On mobile, the components snap into view
        if (!isDesktop) {
          // Let's get 100vh for calculations
          setInitOffset(xy[1] < pageHeight / 2)
          setMenuOffset(Math.floor(xy[1] / pageHeight))
          setXY(xy)
        }
      } else {
        // Locking the main component
        if (!isDesktop) {
          if (last && !initialLock) initialLock = scrollable.current.scrollTop
          if (last) scrollable.current.scrollTop = initialLock
        } else {
          if (last && !initialLock) initialLock = scrollable.current.scrollLeft
          if (last) scrollable.current.scrollLeft = initialLock
        }
      }
    }
  })

  // Scroll to a specific section
  const setHorizontalIndex = (horizontalIndex) => {
    const offsetLeft = scrollable.current.children[horizontalIndex].offsetLeft - window.innerWidth / 2
    const offsetWidth = scrollable.current.children[horizontalIndex].offsetWidth / 2
    scrollable.current.scrollTo({
      left: offsetLeft + offsetWidth,
      behavior: 'smooth'
    })
  }

  const handleMenu = (section) => {
    setIsOpen(false)
    setTimeout(() => {
      const place = document.getElementById(section)
      scrollable.current.scrollTo({ left: place.offsetLeft, top: place.offsetTop, behavior: 'smooth' })
    }, 300)
  }

  const handleMenuFunction = () => {
    if (!lock || initOffset) setIsOpen(!isOpen)
    else horizontalScroll(true)
  }

  // Loader
  useEffect(() => {
    scrollSnapPolyfill()
    setTimeout(() => {
      setLoader(false)
    }, 2000)
  }, [isDesktop])

  // In mobile it enters or exits a section
  const horizontalScroll = (left) => {
    const mainPage = left ? 0 : 1
    const element = document.getElementById(`scroll-container-${menuOffset}`)
    console.log(element)
    element.scrollTo(mainPage * window.innerWidth, null)
  }

  const handleModal = (content) => {
    setModal(content)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setModal(null)
  }

  const params = {
    closeModal: () => handleCloseModal(),
    modalHandling: (content) => handleModal(content),
    handleScrollParent: setLock,
    scrollRight: (left) => horizontalScroll(left),
    parallax: { logo_main },
    lock: setLock,
    titleOffset: titleOffset
  }

  useEffect(() => {
    let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    const onresize = () => {
      const newViewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      if (newViewportHeight !== viewportHeight) {
        if (newViewportHeight < viewportHeight) {
          // Android Chrome address bar has appeared
          setTitleOffset(Math.abs(newViewportHeight - viewportHeight))
        } else {
          // Android Chrome address bar has disappeared
          setTitleOffset(0)
        }
      }
      viewportHeight = newViewportHeight
    }
    onresize()
    window.addEventListener('resize', () => onresize())
    return window.removeEventListener('resize', () => onresize())
  }, [])

  const Splash = (
    <div className="splash main">
      <div className="logo-back">
        <img src="/svg/front-pages/after-grade/romb-wave.svg" alt="" />
      </div>
      <div className="logo-main rings">
        <div className="top ring">
          <img src="/svg/front-pages/home/top-ring.svg" alt="" />
        </div>
        <div className="mid ring">
          <img src="/svg/front-pages/home/mid-ring.svg" alt="" />
        </div>
        <div className="bottom ring">
          <img src="/svg/front-pages/home/bottom-ring.svg" alt="" />
        </div>
      </div>
      <div className="logo-front">
        <img src="/svg/logo-uam/logo-uam-estudiantes-main.svg" alt="Guía de acogida a estudiantes UAM" />
      </div>
      <div className="cross">
        <img src="/svg/icons/green-cross.svg" alt="" />
      </div>
    </div>
  )

  const UAMFirstSteps_logo = (
    <div className="splash-item UAMFirstSteps" onClick={() => setHorizontalIndex(1)}>
      <div className="logo-back">
        <img src="/splash/svg/UAMFirstSteps_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/png/UAMFirstSteps_logo_main.png" className="illustration" alt="" />
        <img src="/splash/png/UAMFirstSteps.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <VerticalWaves color={'dark-blue'} />
      </div>
      <div className="logo-title text-semi-normal urbana">Primeros pasos en la UAM</div>
    </div>
  )

  const DigitalEnvironment_logo = (
    <div className="splash-item item-2 DigitalEnvironment" onClick={() => setHorizontalIndex(2)}>
      <div className="logo-back">
        <img src="/splash/svg/DigitalEnvironment_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/DigitalEnvironment_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/DigitalEnvironment.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/DigitalEnvironment_logo_front.svg" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Configura tu entorno digital</div>
    </div>
  )

  const StudiesCenter_logo = (
    <div className="splash-item item-3 StudiesCenter" onClick={() => setHorizontalIndex(3)}>
      <div className="logo-back">
        <img src="/splash/svg/StudiesCenter_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/StudiesCenter_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/StudiesCenter.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <VerticalWaves color={'blue'} />
      </div>
      <div className="logo-title text-semi-normal urbana">Conoce tu centro de estudios</div>
    </div>
  )

  const WhoCanHelp_logo = (
    <div className="splash-item item-4 WhoCanHelp" onClick={() => setHorizontalIndex(4)}>
      <div className="logo-back">
        <DotsGrill color={'brown'} />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/WhoCanHelp_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/WhoCanHelp.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/WhoCanHelp_logo_front.svg" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Averigua quién puede ayudarte</div>
    </div>
  )

  const UAM_logo = (
    <div className="logo-uam">
      <div className="background-big-logo">
        <div className="kite">
          <Kite color="uam-yellow" />
        </div>
        <div className="inner-logo">
          <img src="/svg/front-pages/home/logo-white.svg" alt="" />
        </div>
      </div>
    </div>
  )

  const Resources_logo = (
    <div className="splash-item item-5 Resources" onClick={() => setHorizontalIndex(5)}>
      <div className="logo-back">
        <img src="/splash/svg/Resources_logo_back.svg" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/Resources_logo_front.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/Resources_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Resources.png" className="image" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Aprovecha los recursos de la UAM</div>
    </div>
  )

  const LiveAutonoma_logo = (
    <div className="splash-item item-6 LiveAutonoma" onClick={() => setHorizontalIndex(6)}>
      <div className="logo-back">
        <img src="/splash/svg/LiveAutonoma_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/LiveAutonoma_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/LiveAutonoma.png" className="image" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Vive la autónoma</div>
    </div>
  )

  const ManageDayByDay_logo = (
    <div className="splash-item item-7 ManageDayByDay" onClick={() => setHorizontalIndex(7)}>
      <div className="logo-back">
        <img src="/splash/svg/ManageDayByDay_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/ManageDayByDay_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/ManageDayByDay.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/ManageDayByDay_logo_front.svg" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">
        Gestiona tu <span className="nowrap">dí a</span> a <span className="nowrap">dí a</span>
      </div>
    </div>
  )

  const ProfessionalProfile_logo = (
    <div className="splash-item item-8 ProfessionalProfile" onClick={() => setHorizontalIndex(8)}>
      <div className="logo-back">
        <img src="/splash/svg/ProfessionalProfile_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/ProfessionalProfile_logo_main.png" className="illustration" alt="" />
        <img src="/splash/png/ProfessionalProfile.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/ProfessionalProfile_logo_front.svg" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Completa tu perfil profesional</div>
    </div>
  )

  const Norms_logo = (
    <div className="splash-item item-9 Norms" onClick={() => setHorizontalIndex(9)}>
      <div className="logo-back">
        <img src="/splash/svg/Norms_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/Norms_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Norms.png" className="image" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Normativa</div>
    </div>
  )

  const AfterGrade_logo = (
    <div className="splash-item item-10 AfterGrade" onClick={() => setHorizontalIndex(10)}>
      <div className="logo-back">
        <img src="/splash/svg/AfterGrade_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/AfterGrade_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/AfterGrade.png" className="image" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Más allá del grado</div>
    </div>
  )

  const Utilities_logo = (
    <div className="splash-item item-11 Utilities" onClick={() => setHorizontalIndex(11)}>
      <div className="logo-back">
        <img src="/splash/svg/Utilities_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/Utilities_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Utilities.png" className="image" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/Utilities_logo_front.svg" alt="" />
      </div>
      <div className="logo-title text-semi-normal urbana">Utilidades</div>
    </div>
  )

  return (
    <>
      {loader ? (
        <div className="loader">
          <div className="logo-container">
            <img src="/svg/logo-uam/logo-uam-estudiantes-green.svg" alt="UAM Estudiantes" />
          </div>
          <div className="lottie-container">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: lottie_loader.default,
                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
              }}
              width={300}
              height={300}
              speed={1}
            />
          </div>
        </div>
      ) : null}

      <div className={`Home ${isDesktop ? 'desktop' : ''}`}>
        {!isDesktop && (
          <div className={`menu-handler ${isOpen ? 'open' : 'closed'} ${!lock || initOffset ? 'black' : 'white back'}`} onClick={() => handleMenuFunction()}>
            <div className="bar top" />
            <div className="bar medium" />
            <div className="bar bottom" />
          </div>
        )}
        <Menu handle={(data) => handleMenu(data)} open={isOpen} />

        {/* Side index in the left of the screen */}
        <LeftIndex show={!(lock || initOffset)} section={menuOffset} color={menuOffset} />

        <div className={`content-container ${lock ? 'lock' : 'unlock'}`} id="content-container">
          <div {...bind()} ref={scrollable} className={`horizontal-container`}>
            {isDesktop ? (
              <div className={`home-section main-sec home-desktop`}>
                <div className="inner">
                  <div className="sections">
                    {UAMFirstSteps_logo}
                    {DigitalEnvironment_logo}
                    {StudiesCenter_logo}
                    {WhoCanHelp_logo}
                    {Resources_logo}
                    {UAM_logo}
                    {LiveAutonoma_logo}
                    {ManageDayByDay_logo}
                    {ProfessionalProfile_logo}
                    {Norms_logo}
                    {AfterGrade_logo}
                    {Utilities_logo}
                  </div>
                </div>
                <div className="words-container">
                  <p className="urbana uppercase">Guí a de estudiantes</p>
                </div>
                <div className="logo-main-uam">
                  <img src="/svg/logos/logo-general-uam.png" alt="" />
                </div>
                <div className="mouse-icon">
                  <MouseIcon />
                  <p className="heroic">AVANZA PARA VER LAS SECCIONES</p>
                </div>
              </div>
            ) : (
              <div className={`home-section main-sec home-mobile`}>
                <div className="logo-general-uam">
                  <img src="/svg/logos/logo-general-uam.png" alt="" />
                </div>
                <div className={`front-logo-container-home`}>
                  <div className="background">
                    <div className="div1"></div>
                    <div className="div2"></div>
                  </div>
                  {Splash}
                </div>
                <div className="title-container">
                  <h1 className="urbana uppercase">Guí a de estudiantes</h1>
                  <div className="square">
                    <img src="/svg/front-pages/home/square-home.svg" alt="" />
                  </div>
                </div>
                <div className="arrows-down-container">
                  <ArrowsDown color="black" />
                </div>
              </div>
            )}
            {/* All the different sections below */}
            <UAMFirstSteps {...params} section={1} />
            <DigitalEnvironment {...params} section={2} />
            <StudiesCenter {...params} section={3} />
            <WhoCanHelp {...params} section={4} />
            <Resources {...params} section={5} />
            <LiveAutonoma {...params} section={6} />
            <ManageDayByDay {...params} section={7} />
            <ProfessionalProfile {...params} section={8} />
            <Norms {...params} section={9} />
            <AfterGrade {...params} section={10} />
            <Utilities {...params} section={11} />
          </div>
        </div>
        <Modal open={showModal} goBack={handleCloseModal}>
          {modal}
        </Modal>
      </div>
    </>
  )
}

export default Home
