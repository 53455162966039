import React from 'react'
import { colorToHex } from '../../data'

const ChatNur = ({ color = 'yellow', width = 47 }) => {
  return (
    <svg width={width} height={width * (45 / 47)} viewBox="0 0 47 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2664 26.3531H32.3365V35.9413C32.3365 36.6864 32.0689 37.3139 31.5337 37.8237C30.9985 38.3335 30.2986 38.608 29.4341 38.6472H12.2664L5.10285 44.1178V38.6472H3.6825C2.90028 38.6472 2.24156 38.3727 1.70636 37.8237C1.17115 37.2746 0.882966 36.6472 0.841797 35.9413V18.1766C0.841797 17.4315 1.12998 16.7844 1.70636 16.2354C2.28273 15.6864 2.94145 15.4315 3.6825 15.4707H12.2664V26.3531ZM43.7611 0.470718C44.5845 0.470718 45.2638 0.72562 45.799 1.23542C46.3342 1.74523 46.6018 2.41189 46.6018 3.23542V20.9413C46.6018 21.6864 46.3342 22.3139 45.799 22.8237C45.2638 23.3335 44.5845 23.608 43.7611 23.6472H42.3407V29.1178L35.1772 23.6472H15.1688V3.23542C15.1688 2.45111 15.4364 1.78444 15.9716 1.23542C16.5068 0.686405 17.1861 0.431503 18.0095 0.470718H43.7611Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default ChatNur
