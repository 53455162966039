import React from 'react'
import { colorToHex } from '../../data'

const ExternalPage = ({ color = 'dark-green', width = 15 }) => {
  return (
    <svg width={width} height={width * (width / 14)} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4987 3.66634H6.66536V5.33301H2.4987V11.9997H9.16536V7.83301H10.832V11.9997C10.832 12.4417 10.6564 12.8656 10.3439 13.1782C10.0313 13.4907 9.60739 13.6663 9.16536 13.6663H2.4987C2.05667 13.6663 1.63275 13.4907 1.32019 13.1782C1.00763 12.8656 0.832031 12.4417 0.832031 11.9997V5.33301C0.832031 4.89098 1.00763 4.46706 1.32019 4.1545C1.63275 3.84194 2.05667 3.66634 2.4987 3.66634ZM11.3437 1.99967H7.4987V0.333008H14.1654V6.99967H12.4987V3.20134L8.05203 7.64801L6.8737 6.46967L11.3437 1.99967Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default ExternalPage
