import './Utilities.scss'

import * as lottie_arrives from '../../lotties/Utilities/arrive.json'
import * as lottie_contacts from '../../lotties/Utilities/contacts.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import Bike from '../../components/Icons/Bike'
import ContactCard from '../../components/ContactCard/ContactCard'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import KiteIcon from '../../components/KiteIcon/KiteIcon'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import MapsBanner from '../../components/MapsBanner/MapsBanner'
import Plug from '../../components/Icons/Plug'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const Utilities = ({
  color = 'blue',
  secondary = 'yellow',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  // https://assets7.lottiefiles.com/private_files/lf30_Pc7YxP.json
  const lottieContacts = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_contacts.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // https://assets2.lottiefiles.com/packages/lf20_aSLIN9.json
  const lottieArrives = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_arrives.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const cantoblanco = (
    <div className="desplegable-container cantoblanco">
      <MapsBanner color={color} secondary={secondary} address="https://goo.gl/maps/cK7yBWd9Vkmmr1LW6" text="Abrir en Google Maps" />

      <div className="block">
        <KiteIcon color={secondary} icon="car" />
        <p className="text-normal">Ctra. M-607 (MadridColmenar), km. 15,5. 28049, Madrid</p>
      </div>
      <div className="block">
        <KiteIcon color={secondary} icon="bus" />
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 714: </span>Plaza de Castilla – UAM Cantoblanco
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 827: </span>Madrid (Canillejas)- Aeropuerto T4 – Alcobendas – UAM Cantoblanco – Tres Cantos{' '}
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 827A: </span>San Sebastián de los Reyes – Alcobendas – UAM Cantoblanco
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 828: </span>Madrid (Canillejas) – Alcobendas – UAM Cantoblanco
        </p>
      </div>
      <div className="block">
        <KiteIcon icon="renfe" color={secondary} />
        <p className="text-normal">Los trenes de Cercanías conectan las estaciones más importantes con el Campus</p>
        <p className="text-normal dotted">
          <span className="colored bold blue">Línea C-4a: </span>Parla - Atocha - Alcobendas/San Sebastián de los Reyes
        </p>
        <p className="text-normal dotted">
          <span className="colored bold blue">Línea C-4b: </span>Parla - Atocha - Colmenar
        </p>
      </div>
    </div>
  )

  const medina = (
    <div className="desplegable-container medina">
      <MapsBanner color={color} secondary={secondary} address="https://goo.gl/maps/z6yUaCVuXy3x3oPg8" text="Abrir en Google Maps" />
      <div className="block">
        <KiteIcon color={secondary} icon="car" />
        <p className="text-normal">Ctra. M-607 (MadridColmenar), km. 15,5. 28049, Madrid</p>
      </div>
      <div className="block">
        <KiteIcon color={secondary} icon="bus" />
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 67: </span>Plaza de Castilla - Barrio de Peñagrande
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 124: </span>Cuatro Caminos - Lacoma
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 132: </span>Moncloa - Hospital La Paz
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 135: </span>Plaza de Castilla - Hospital Ramón y Cajal
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 137: </span>Puerta de Hierro (Madrid) - Fuencarral
        </p>
        <p className="dotted text-normal">
          <span className="colored bold blue">Línea 714: </span>UAM Cantoblanco - Plaza de Castilla
        </p>
      </div>
      <div className="block">
        <KiteIcon icon="metro" color={secondary} />
        <p className="text-normal dotted">
          <span className="colored bold blue">Línea 10: </span>Begoña
        </p>
      </div>
    </div>
  )

  const autonoBus = (
    <div className="desplegable-container autonobus">
      <ListItem color={secondary} custom={<Plug color={secondary} />}>
        <p className="text-normal">
          Un vehículo <span className="bold">eléctrico sin conductor</span> que circula por el campus realizando varias paradas. Este autobús está especialmente
          adaptado para que puedan <span className="bold">utilizarlo personas con movilidad reducida.</span>
        </p>
      </ListItem>
    </div>
  )

  const theBike = (
    <div className="desplegable-container the-bike">
      <ListItem color={secondary} custom={<Bike color={secondary} />}>
        <p className="text-normal">
          La bici es un medio de transporte muy común en el campus, donde encontrarás muchos <b>aparcabicis</b>, y es una de las maneras más{' '}
          <b>sostenibles y cómodas</b> de trasladarse en este entorno.
        </p>
      </ListItem>
    </div>
  )

  // Modals area
  const contactsModal = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Listín de contacto</h3>
          <div className="actual-text">
            {lottieContacts}
            {contacts && Object.values(contacts).map((c) => <ContactCard key={c.id} data={c} />)}
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const arrive = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Cómo llegar a la UAM</h3>
          <div className="actual-text">
            {lottieArrives}
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="A Cantoblanco">
                {cantoblanco}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="A Medicina">
                {medina}
              </Desplegable>
              <DidYouKnow color={color}>
                <p className="text-normal">
                  El <b>acceso en coche</b> es el principal responsable de la huella de carbono de la UAM. <b>Prioriza el transporte público</b> o los modos no
                  motorizados. En la UAM existen <b>puntos de recarga de coches, bicicletas y patinetes</b>.
                </p>
              </DidYouKnow>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const movement = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Desplazamiento en el Campus</h3>
          <div className="actual-text">
            <p className="text-normal">
              Una de las mayores ventajas de los campus UAM es que <span className="bold">¡todo está cerca!</span> En Cantoblanco, el más grande, no tardarás
              mucho más de 10 minutos en ir de una punta a otra. Si prefieres agilizar los trayectos, existen dos{' '}
              <span className="bold">medios de comunicación</span> totalmente <span className="bold">ecológicos</span> que puedes utilizar.
            </p>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="El autobús autónomo">
                {autonoBus}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="La Bici">
                {theBike}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'contacts') isDesktop ? setModal(contactsModal) : modalHandling(contactsModal)
    else if (modalContent === 'arrive') isDesktop ? setModal(arrive) : modalHandling(arrive)
    else if (modalContent === 'movement') isDesktop ? setModal(movement) : modalHandling(movement)
    else isDesktop && setModalContent('contacts')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/Utilities_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/Utilities_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Utilities.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <img src="/splash/svg/Utilities_logo_front.svg" alt="" />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop Utilities ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Utilidades</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'contacts' ? 'selected' : ''}`}
                              onClick={() => setModalContent('contacts')}
                            >
                              1. Listín de contacto{' '}
                              <div className="arrows-container">
                                <ArrowsDown color="purple" />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'arrive' ? 'selected' : ''}`}
                              onClick={() => setModalContent('arrive')}
                            >
                              2. Cómo llegar a la UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color="purple" />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'movement' ? 'selected' : ''}`}
                              onClick={() => setModalContent('movement')}
                            >
                              3. Desplazamiento en el campus{' '}
                              <div className="arrows-container">
                                <ArrowsDown color="purple" />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>

                      <div className="mock-log">
                        <img src="/splash/png/Utilities_logo_main.png" alt="" />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="utilities-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`Utilities main-page ${color}`} id="utilities">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header" id="utilities-header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="utilities-logo">
                {Splash}
              </div>

              <div className="footer" id="utilities-footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Utilidades</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="utilities-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Utilidades</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('contacts')}>
                      <span>1. Listín de contacto</span>
                      <div className="arrows-container">
                        <ArrowsDown color="purple" />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('arrive')}>
                      <span>2. Cómo llegar a la UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color="purple" />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('movement')}>
                      <span>3. Desplazamiento en el campus</span>
                      <div className="arrows-container">
                        <ArrowsDown color="purple" />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Utilities
