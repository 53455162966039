import React from 'react'
import { colorToHex } from '../../data'

const Running = ({ color = 'orange', width = 113 }) => (
  <svg width={width} height={width * (width / 78)} viewBox="0 0 113 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M97.8885 23.2536C101.825 23.2536 105.015 20.0628 105.015 16.1268C105.015 12.1908 101.825 9 97.8885 9C93.9525 9 90.7617 12.1908 90.7617 16.1268C90.7617 20.0628 93.9525 23.2536 97.8885 23.2536Z"
      fill={colorToHex(color)}
    />
    <path
      d="M93.5296 40.96C94.3476 42.1789 95.521 43.1166 96.8903 43.6457C98.2596 44.1749 99.7586 44.2698 101.184 43.9176L113 40.9635L111.279 34.0505L99.4626 37.0046L94.5487 29.639C93.4988 28.066 91.8695 26.9721 90.0161 26.5959L76.3112 23.8521C74.778 23.5444 73.1862 23.75 71.7815 24.4372C70.3769 25.1244 69.2375 26.2549 68.5394 27.6542L62.6313 39.4705L69.0063 42.6597L74.9144 30.8399L81.9236 32.2439L63.8001 62.4508H48V69.5776H63.8001C66.2874 69.5776 68.6285 68.2521 69.9114 66.1176L76.746 54.729L95.1652 58.4135L101.636 77.8305L108.393 75.5749L101.925 56.1614C101.526 54.9696 100.82 53.9039 99.8775 53.0719C98.9353 52.2399 97.7906 51.6708 96.5585 51.4221L85.7293 49.2591L92.0436 38.7328L93.5296 40.96V40.96Z"
      fill={colorToHex(color)}
    />
    <path
      d="M36.8422 10.5261C39.7489 10.5261 42.1052 8.16977 42.1052 5.26306C42.1052 2.35635 39.7489 0 36.8422 0C33.9355 0 31.5791 2.35635 31.5791 5.26306C31.5791 8.16977 33.9355 10.5261 36.8422 10.5261Z"
      fill={colorToHex(color)}
    />
    <path
      d="M33.6231 23.602C34.2272 24.5022 35.0937 25.1947 36.1049 25.5854C37.1162 25.9762 38.2232 26.0463 39.2756 25.7862L48.0018 23.6046L46.7307 18.4995L38.0046 20.681L34.3757 15.2416C33.6004 14.08 32.3971 13.2721 31.0284 12.9943L20.9075 10.968C19.7753 10.7408 18.5997 10.8927 17.5624 11.4001C16.5251 11.9076 15.6837 12.7425 15.1681 13.7759L10.8051 22.502L15.5129 24.8573L19.876 16.1285L25.0522 17.1653L11.6682 39.4728H0V44.7358H11.6682C13.505 44.7358 15.2339 43.7569 16.1813 42.1806L21.2286 33.7702L34.831 36.4913L39.6098 50.8305L44.5992 49.1647L39.823 34.8281C39.5282 33.9479 39.0067 33.161 38.3109 32.5465C37.6152 31.9321 36.7698 31.5119 35.8599 31.3282L27.8627 29.7308L32.5257 21.9573L33.6231 23.602V23.602Z"
      fill={colorToHex(color)}
    />
  </svg>
)

export default Running
