import React from 'react'
import { colorToHex } from '../../data'

const Plug = ({ color = 'orange', width = 35 }) => {
  return (
    <svg width={width} height={width * (27 / 35)} viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.86115 23.5376L5.50581 25.6735C5.62502 26.0685 6.04496 26.2917 6.44273 26.1717L7.87444 25.7396C8.06527 25.6816 8.22544 25.5507 8.31982 25.3758C8.4142 25.2009 8.43507 24.9962 8.37785 24.8066L7.73318 22.6708C7.32047 21.3034 7.90523 19.0827 9.40926 17.8415L8.50337 14.8401C5.40822 16.6082 3.98753 20.6432 4.86115 23.5376ZM18.1848 5.04951L15.3685 8.85231C14.9821 9.37342 14.4368 9.75737 13.8136 9.94548L10.472 10.9541C9.67496 11.1946 9.22241 12.0313 9.46126 12.8226L11.1788 18.5131C11.4172 19.303 12.2571 19.7496 13.0541 19.509L16.3957 18.5005C17.0189 18.3124 17.6856 18.3305 18.2958 18.5508L22.7471 20.16C23.8137 20.546 24.9787 20.577 26.0673 20.2485L28.6981 19.4544L23.5339 2.34447L20.9031 3.1385C19.8146 3.46704 18.8607 4.13767 18.1848 5.04951ZM25.8306 4.7627L26.6913 7.61436L30.9993 6.31408L30.1386 3.46242L25.8306 4.7627ZM29.2734 16.1694L33.5815 14.8691L32.7208 12.0174L28.4127 13.3177L29.2734 16.1694Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Plug
