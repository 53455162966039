import React from 'react'
import './MapsBanner.scss'
import LocationPin from '../Icons/LocationPin'

const MapsBanner = ({ color, secondary, address, text }) => {
  return (
    <a href={address} target="_blank" rel="noopener noreferrer" className={`MapsBanner ${color}`}>
      <div className="icon-container-maps">
        <LocationPin color={color} />
      </div>
      <p className={`heroic italic uppercase text-semi-normal ${secondary}`}>{text}</p>
    </a>
  )
}

export default MapsBanner
