import React from 'react'
import ArrowsDown from '../ArrowsDown/ArrowsDown'
import './MouseIcon.scss'

const MouseIcon = () => {
  return (
    <div className="MouseIcon">
      <div className="arrows-container">
        <div className="top arrow">
          <ArrowsDown color="dark-gray" />
        </div>
        <div className="wheel" />
        <div className="bottom arrow">
          <ArrowsDown color="dark-gray" />
        </div>
      </div>
    </div>
  )
}

export default MouseIcon
