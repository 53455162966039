import './AssociationDetail.scss'

import ExternalLink from '../../ExternalLink/ExternalLink'
import Facebook from '../../Icons/Facebook'
import Instagram from '../../Icons/Instagram'
import Network from '../../Icons/Network'
import React from 'react'
import Sendvelope from '../../Icons/Sendvelope'
import X from '../../Icons/X'
import useScreenSize from '../../../hooks/screen-size'

const AssociationDetail = ({ association, open, color, secondary, closeAssociation }) => {
  const { isDesktop } = useScreenSize()

  /**
   * Banner with all the contact data provided by the UAM.
   * Render conditionally all the social media and links for joining the association.
   */
  const contactSection = association && (
    <div className="contact-section">
      {association.join.length > 0 && <ExternalLink url={association.join} color={secondary} secondary={color} text="Únete a esta asociación" />}
      <div className="icons-container">
        {association.web.length > 0 && (
          <div className="web-icon contact-icon">
            <a href={association.web} target="_blank" rel="noopener noreferrer">
              <Network color={secondary} />
            </a>
          </div>
        )}
        {association.email.length > 0 && (
          <div className="email-icon contact-icon">
            <a href={`mailto:${association.email}`} target="_blank" rel="noopener noreferrer">
              <Sendvelope color={secondary} />
            </a>
          </div>
        )}
        {association.instagram.length > 0 && (
          <div className="instagram-icon contact-icon">
            <a href={association.instagram} target="_blank" rel="noopener noreferrer">
              <Instagram color={secondary} />
            </a>
          </div>
        )}
        {association.twitter.length > 0 && (
          <div className="twitter-icon contact-icon">
            <a href={association.twitter} target="_blank" rel="noopener noreferrer">
              <X color={secondary} />
            </a>
          </div>
        )}
        {association.facebook.length > 0 && (
          <div className="facebook-icon contact-icon">
            <a href={association.facebook} target="_blank" rel="noopener noreferrer">
              <Facebook width={20} color={secondary} />
            </a>
          </div>
        )}
      </div>
    </div>
  )

  /**
   * Image displayed when there is no video in Desktop
   */
  const splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/LiveAutonoma_logo_back.svg" alt="" />
      </div>
      <div className="logo-main">
        <img src="/splash/svg/LiveAutonoma_logo_main.svg" alt="" className="illustration" />
        <img src="/splash/png/LiveAutonoma.png" alt="" className="image" />
      </div>
    </>
  )

  return (
    <div className={`AssociationDetail ${open ? 'open' : 'closed'}`}>
      {association &&
        (isDesktop ? (
          <div className={`dektop-inner-container ${color}`}>
            <div className="header-modal">
              <p className="text-medium heroic association-name italic uppercase">{association.name}</p>
              <div className="delete-association-container" onClick={() => closeAssociation()}>
                <div className={`delete-association-button ${secondary}`}>-</div>
              </div>
            </div>
            <div className="content-association">
              <div className="left-association">
                {association.video.length > 0 ? (
                  <div className="video-container">
                    <iframe
                      title={association.name}
                      width={window.innerWidth * 0.35 - 52}
                      height={window.innerHeight * 0.35}
                      src={association.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                ) : (
                  <div className="splash-container">{splash}</div>
                )}
                {contactSection}
              </div>
              <div className="right-association">
                <div className="description-container">
                  {association.description.map((p) => (
                    <p className="text-normal" key={p}>
                      {p}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`mobile-inner-container ${color}`}>
            <div className="header-modal">
              <p className="text-semi-normal heroic association-name italic uppercase">{association.name}</p>
              <div className="delete-association-container" onClick={() => closeAssociation()}>
                <div className={`delete-association-button ${secondary}`}>-</div>
              </div>
            </div>
            {association.video.length > 0 && (
              <div className="video-container">
                <iframe
                  title={association.name}
                  width={window.innerWidth - 67}
                  src={association.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            )}
            {contactSection}
            <div className="description-container">
              {association.description.map((p) => (
                <p className="text-normal" key={p}>
                  {p}
                </p>
              ))}
            </div>
            {contactSection}
          </div>
        ))}
    </div>
  )
}

export default AssociationDetail
