import React from 'react'
import './KiteIcon.scss'
import KiteBorder from '../Icons/KiteBorder'

const KiteIcon = ({ color = 'orange', icon }) => {
  return (
    <div className="KiteIcon">
      <div className="kite-container">
        <KiteBorder width={55} color={color} />
      </div>
      <div className="icon-container">
        <img src={`/svg/icons/${icon}.svg`} alt="" />
      </div>
    </div>
  )
}

export default KiteIcon
