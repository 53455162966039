import React from 'react'
import { colorToHex } from '../../data'

const Envelope = ({ color = 'orange', width = 20 }) => {
  return (
    <svg width={width} height={width * (width / 19)} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2857 0.867188H0.714286C0.319196 0.867188 0 1.21555 0 1.64675V17.238C0 17.6691 0.319196 18.0175 0.714286 18.0175H19.2857C19.6808 18.0175 20 17.6691 20 17.238V1.64675C20 1.21555 19.6808 0.867188 19.2857 0.867188ZM18.3929 3.56641V16.2635H1.60714V3.56641L0.991071 3.04265L1.8683 1.8124L2.82366 2.62363H17.1786L18.1339 1.8124L19.0112 3.04265L18.3929 3.56641ZM17.1786 2.6212L10 8.71151L2.82143 2.6212L1.86607 1.80997L0.988839 3.04021L1.60491 3.56398L9.22991 10.0343C9.44919 10.2203 9.71897 10.3212 9.99665 10.3212C10.2743 10.3212 10.5441 10.2203 10.7634 10.0343L18.3929 3.56641L19.0089 3.04265L18.1317 1.8124L17.1786 2.6212Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Envelope
