import './ManageDayByDay.scss'

import * as lottie_caffe from '../../lotties/ManageDayByDay/caffe.json'
import * as lottie_package from '../../lotties/ManageDayByDay/package.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import Lottie from 'react-lottie'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const ManageDayByDay = ({
  color = 'purple',
  secondary = 'green',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties
  const lottieCaffe = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_caffe.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottiePackage = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_package.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Modals
  const attention = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Centro de atención a usuarios</h3>
          <div className="actual-text">
            <p className="text-normal">
              Si tienes problemas con tu cuenta de correo, la conexión wifi o cualquier otro aspecto informático, puedes ponerte en contacto con el CAU, donde
              atenderán tu consulta y te indicarán cómo solucionar cualquier incidencia.
            </p>

            <DidYouKnow color={color} title={'¡Atención!'}>
              <div className="phising-upper-container">
                <p className="text-normal">
                  Por cierto, recuerda que <span className="bold">nunca te pediremos ninguna contraseña personal por correo</span>, por lo que nunca se la des a
                  nadie… ¡así evitarás ser presa de <span className="bold">phishing</span>!
                </p>
                <div className="phising-container">
                  <img src="/svg/icons/phising.svg" alt="" />
                </div>
              </div>
            </DidYouKnow>
            <AddressBookComp color={color} data={contacts['cauti']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const reprography = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Reprografía</h3>
          <div className="actual-text">
            <p className="text-normal">
              El <b>Centro de Servicios Documentales</b> está ubicado en la <b>primera planta de la Plaza Mayor</b> (también puedes mandar tus documentos para
              imprimir al correo electrónico que aparece abajo), y en todos los centros hay máquinas en los pasillos para fotocopiar, escanear e imprimir.
            </p>
            <p className="text-normal">
              Para usarlas, <b>activa tu tarjeta</b> en el <b>centro de reprografía del campus</b>.
            </p>
          </div>
          <AddressBookComp color={color} data={contacts['reprografia']} />
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const banks = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Bancos y cajeros</h3>
          <div className="actual-text">
            <p className="text-normal">
              En campus de la UAM encontrarás diferentes cajeros del <span className="bold">Banco Santander</span> —que también tiene una sucursal en la Plaza
              Mayor UAM— y <span className="bold">Bankia</span>. Consulta el mapa para conocer su ubicación.
            </p>

            <div className="logo santander">
              <img src="/svg/logos/banco-santander.svg" alt="" />
            </div>

            <div className="logo bankia">
              <img src="/svg/logos/bankia.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const mailbox = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Oficina de Correos</h3>
          <div className="actual-text">
            <p className="text-normal">
              Además de los servicios propios de una oficina de Correos, ofrece servicios bancarios y servicio de{' '}
              <span className="bold">recogida gratuita de paquetes para estudiantes alojados en la residencia Erasmo.</span>
            </p>

            <div className="logo correos">
              <img src="/svg/logos/correos.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const postbox = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Recogida de paquetes</h3>
          <div className="actual-text">
            <div className="logo package">{lottiePackage}</div>
            <p className="text-normal">
              Además de la oficina de Correos, el <b>Campus de Cantoblanco</b> cuenta con <b>tres puntos de recogida/depósito de paquetería</b> (lockers) de
              InPost para que te puedas organizar según tus horarios y accesos al campus. Puedes consultar la ubicación de estos lockers en el mapa del apartado
              "Localización", dentro de la sección "CONOCE TU CENTRO DE ESTUDIOS"
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const coffee = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Comedores y cafeterías</h3>
          <div className="actual-text">
            {lottieCaffe}

            <p className="text-normal">
              En todas las Facultades y en la EPS existen comedores en los que puedes <span className="bold">calentar tu propia comida.</span> Además, las
              cafeterías de estos centros, la de Rectorado, el Polideportivo y la Plaza Mayor ofrecen <span className="bold">menú del día</span> y muchas otras
              opciones a precios asequibles.
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const library = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Librería</h3>
          <div className="actual-text">
            <p className="text-normal">
              La UAM cuenta con una librería donde puedes adquirir <span className="bold">libros, prensa y material de papelería</span>: está situada en la
              planta baja del Edificio Plaza Mayor (tiene una sucursal más pequeña en la facultad de Medicina). En ella se aplican{' '}
              <span className="bold">descuentos especiales a los miembros de la comunidad universitaria.</span>
            </p>
          </div>
          <AddressBookComp color={color} data={contacts['libreria']} />
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const insurance = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Seguro escolar</h3>
          <div className="actual-text">
            <p className="text-normal">
              Existe un <span className="bold">seguro escolar</span> que protege a los estudiantes menores de 28 años que cursen estudios oficiales hasta el
              final del tercer ciclo universitario (doctorado) mediante prestaciones sanitarias y económicas, en caso de enfermedad, accidente escolar e
              infortunio familiar. El seguro escolar comienza a surtir efecto desde el momento en el que formalizas tu matrícula.
            </p>

            <ExternalLink color={secondary} secondary={color} url="http://www.seg-social.es/wps/portal/wss/internet/FAQ/48581/48766" text="Más info aquí" />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'attention') isDesktop ? setModal(attention) : modalHandling(attention)
    else if (modalContent === 'reprography') isDesktop ? setModal(reprography) : modalHandling(reprography)
    else if (modalContent === 'banks') isDesktop ? setModal(banks) : modalHandling(banks)
    else if (modalContent === 'mailbox') isDesktop ? setModal(mailbox) : modalHandling(mailbox)
    else if (modalContent === 'postbox') isDesktop ? setModal(postbox) : modalHandling(postbox)
    else if (modalContent === 'coffee') isDesktop ? setModal(coffee) : modalHandling(coffee)
    else if (modalContent === 'library') isDesktop ? setModal(library) : modalHandling(library)
    else if (modalContent === 'insurance') isDesktop ? setModal(insurance) : modalHandling(insurance)
    else isDesktop && setModalContent('attention')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/ManageDayByDay_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/ManageDayByDay_logo_main.svg" className="illustration" />
        <img src="/splash/png/ManageDayByDay.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <img src="/splash/svg/ManageDayByDay_logo_front.svg" alt="" />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop ManageDayByDay ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">
                Gestiona tu <span className="nowrap">dí a</span> a <span className="nowrap">dí a</span>
              </h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'attention' ? 'selected' : ''}`}
                              onClick={() => setModalContent('attention')}
                            >
                              1. Centro de atención a usuarios{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'reprography' ? 'selected' : ''}`}
                              onClick={() => setModalContent('reprography')}
                            >
                              2. Reprografía{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'banks' ? 'selected' : ''}`} onClick={() => setModalContent('banks')}>
                              3. Bancos y cajeros{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'mailbox' ? 'selected' : ''}`}
                              onClick={() => setModalContent('mailbox')}
                            >
                              4. Oficina de correos{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'postbox' ? 'selected' : ''}`}
                              onClick={() => setModalContent('postbox')}
                            >
                              5. Recogida de paquetes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'coffee' ? 'selected' : ''}`}
                              onClick={() => setModalContent('coffee')}
                            >
                              5. Comedores y cafeterías{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'library' ? 'selected' : ''}`}
                              onClick={() => setModalContent('library')}
                            >
                              6. Librería{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'insurance' ? 'selected' : ''}`}
                              onClick={() => setModalContent('insurance')}
                            >
                              7. Seguro escolar{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="manage-day-by-day-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`ManageDayByDay main-page ${color}`} id="manage-day-by-day">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="manage-day-by-day">
                {Splash}
              </div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>
                      Gestiona tu <span className="nowrap">dí a</span> a <span className="nowrap">dí a</span>
                    </span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="manage-day-by-day-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>
                    Gestiona tu <span className="nowrap">dí a</span> a <span className="nowrap">dí a</span>
                  </h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('attention')}>
                      <span>1. Centro de atención a usuarios</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('reprography')}>
                      <span>2. Reprografía</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('banks')}>
                      <span>3. Bancos y cajeros</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('mailbox')}>
                      <span>4. Oficina de correos</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('postbox')}>
                      <span>5. Recogida de paquetes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('coffee')}>
                      <span>6. Comedores y cafeterías</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('library')}>
                      <span>7. Librería</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('insurance')}>
                      <span>8. Seguro escolar</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ManageDayByDay
