import './ContactCard.scss'

import React, { useEffect, useState } from 'react'

import AngleLeft from '../Icons/AngleLeft'
import Envelope from '../Icons/Envelope'
import LocPin from '../Icons/LocPin'
import MapCardNumber from '../MapCard/MapCard.number'
import Network from '../Icons/Network'
import Phone from '../Icons/Phone'

const ContactCard = ({ data, plegable, children }) => {
  const { color, address, emails, web, phone, title } = data
  const [parsedPhone, setParsedPhone] = useState('')
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (phone?.length > 0) {
      const first = phone.split('/')[0]
      setParsedPhone(first)
    }
  }, [phone])

  const titlePhrase = <p className="uppercase underline text-normal bold title montserrat">{title}</p>

  return (
    <div className={`ContactCard ${color}`}>
      <div className="title-container" onClick={() => setCollapsed(!collapsed)}>
        <span className="first-part">
          {plegable && <MapCardNumber number={plegable} color={color} />}
          {titlePhrase}
        </span>
        {plegable && (
          <span className={`arrow-container ${collapsed ? 'collapsed' : 'not-collapsed'}`}>
            <AngleLeft color={color} />
          </span>
        )}
      </div>
      <div className={`data-container ${plegable && collapsed ? 'hide' : 'show'}`}>
        {address?.length > 0 && (
          <p className="address item text-normal montserrat">
            <span className="icon-container">
              <LocPin color={color} />
            </span>
            {address}
          </p>
        )}

        {phone?.length > 0 && (
          <a href={`tel:${parsedPhone}`} className="montserrat item text-normal phone">
            <span className="icon-container">
              <Phone color={color} />
            </span>
            {phone}
          </a>
        )}

        {emails?.length > 0 &&
          emails.map((e, i) => (
            <a key={`${e}-${i}-${title}`} href={`mailto:${e}`} className="montserrat item email text-normal underline">
              <span className="icon-container">
                <Envelope color={color} />
              </span>
              {e}
            </a>
          ))}

        {web?.length > 0 && (
          <a href={web} target="_blank" rel="noopener noreferrer" className="montserrat item text-normal underline web">
            <span className="icon-container">
              <Network color={color} />
            </span>
            {web}
          </a>
        )}
        {children}
      </div>
    </div>
  )
}

export default ContactCard
