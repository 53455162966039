import './StudiesCenter.scss'

import * as lottie_building from '../../lotties/StudiesCenter/building.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import Blocks from '../../components/Icons/Blocks'
import Desplegable from '../../components/Desplegable/Desplegable'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import Forum from '../../components/Icons/Forum'
import ListItem from '../../components/ListItem/ListItem'
// Lotties
import Lottie from 'react-lottie'
// Maps
import MapCard from '../../components/MapCard/MapCard'
import VerticalWaves from '../../components/VerticalWaves/VerticalWaves'
import { contacts } from '../../data'
import plano_biologicas from '../../components/Maps/EDIFICO_BIOLOGICAS.pdf'
import plano_ciencias from '../../components/Maps/FACULTAD_CIENCIAS.pdf'
import plano_derecho_a from '../../components/Maps/FACULTAD_DERECHO.pdf'
import plano_derecho_b from '../../components/Maps/FACULTAD_DERECHO_EDIFICIO_JURIDICAS.pdf'
import plano_economicas from '../../components/Maps/FACULTAD_CIENCIAS_ECONOMICAS_Y_EMPRESARIALES.pdf'
import plano_filosofia from '../../components/Maps/FACULTAD_FILOSOFIA_Y_LETRAS.pdf'
import plano_medicina from '../../components/Maps/FACULTAD_DE_MEDICINA.pdf'
import plano_politecnica_a from '../../components/Maps/ESCUELA_POLITECNICA_SUPERIOR.pdf'
import plano_politecnica_b from '../../components/Maps/ESCUELA_POLITECNICA_SUPERIOR_EDIF.C.pdf'
import plano_profesorado from '../../components/Maps/FACULTAD_PROFESORADO_Y_EDUCACION.pdf'
import plano_psicologia from '../../components/Maps/FACULTAD_PSICOLOGIA.pdf'
import plano_uam from '../../components/Maps/plano_uam.pdf'
import useScreenSize from '../../hooks/screen-size'

const StudiesCenter = ({
  color = 'green',
  secondary = 'brown',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  useEffect(() => {
    setChange(!change)
  }, [fullScreen])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  // https://lottiefiles.com/22469-campus-library-school-building-office-mocca-animation
  const lottieBuilding = (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        animationData: lottie_building.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const adminStructure = (
    <div className="desplegable-container admin-structure">
      {lottieBuilding}
      <p className="text-normal">
        Los centros (Facultades y EPS) tienen una estructura administrativa que <span className="bold">se centraliza en la secretaría</span> (también llamada
        Oficina de Gestión de Alumnos, de Gestión de Grado o Administración) donde se atienden todas las cuestiones que no están relacionadas con la docencia y
        la evaluación, por ejemplo, la <span className="bold">gestión de matrícula, pagos, turnos de clase, expedientes</span>, etc.
      </p>
    </div>
  )

  const academicStructure = (
    <div className="desplegable-container academic-structure">
      <p className="text-normal">
        Además, los centros también tienen una estructura académica, que <span className="bold">se encarga de</span> todo lo que tiene que ver con{' '}
        <span className="bold">los estudios</span> propiamente dichos.
      </p>
      <p className="text-normal">
        <span className="bold">La dirección está a cargo de los equipos decanales</span> (o, en el caso de la EPS, del equipo de dirección) encabezados por el
        decano/a o director/a. Sus miembros forman parte del profesorado y <span className="bold">son elegidos por la comunidad universitaria</span> para
        atender áreas específicas organizadas en los vicedecanatos o subdirecciones que puedes consultar en la sección de órganos de gobierno de la página web
        de tu centro.
      </p>
      <p className="text-normal">
        <span className="bold">Todo el profesorado</span>, a su vez, <span className="bold">está vinculado a distintos departamentos</span> que se dedican a
        áreas específicas de conocimiento y organizan e imparten la docencia de las asignaturas de su especialidad (por ejemplo, la asignatura de Mecánica
        Cuántica del Grado en Física la imparten profesores y profesoras del Departamento de Física Teórica de la Facultad de Ciencias).
      </p>
    </div>
  )

  const vicerrector = (
    <>
      <p className="text-normal">
        A este pertenecen los/as vicerrectores/as <span className="bold">responsables de áreas específicas de trabajo</span>. Actualmente, existen los
        siguientes vicerrectorados:
      </p>
      <div className="desplegable-container vicerrector">
        <ul className="kite-list">
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Campus e Infraestructuras</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Compromiso Social y Sostenibilidad</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Estudiantes y Empleabilidad</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Estudios de Grado</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Estudios de Posgrado y Formación Continua</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Internacionalización</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Investigación y Biblioteca</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Transferencia, Innovación y Cultura</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Personal Docente e Investigador</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Planificación y Estrategia Digital</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Vicerrectorado de <span className="bold">Política Científica</span>
            </ListItem>
          </li>
          <li>
            <ListItem color={color} kiteColor={secondary}>
              Adjuntía para <span className="bold">Calidad e Innovación Docente</span>
            </ListItem>
          </li>
        </ul>

        <ExternalLink color={secondary} secondary={color} url="https://www.uam.es/uam/organos-gobierno/equipo-de-gobierno-uam" text="¡Ponles cara!" />
      </div>
    </>
  )

  const governmentStructure = (
    <div className="desplegable-container government">
      <p className="text-normal">
        El Equipo de Gobierno <span className="bold">marca la dirección estratégica</span> y persigue los objetivos fijados, pero{' '}
        <span className="bold">las decisiones más importantes son tomadas de manera democrática</span> por órganos colegiados —es decir, compuestos por una
        pluralidad de personas de la Universidad— donde <span className="bold">todos los colectivos de la UAM tienen voz</span>.
      </p>
      <p className="text-normal">De manera simplificada, la estructura podría representarse así:</p>

      <div className={`university-council structure-schema ${color}`}>
        <div className="icon-container">
          <Forum color="green" />
        </div>
        <h4 className="uppercase urbana text-semi-normal">Claustro universitario</h4>
        <p className="text-normal">Máximo órgano de representación. Está formado por más de 300 miembros y se encarga de supervisar la gestión.</p>

        <div className="government-council">
          <div className="icon-container">
            <Blocks color="green" />
          </div>
          <h4 className="uppercase urbana text-semi-normal">Consejo de gobierno</h4>
          <p className="text-normal">
            El principal órgano de gobierno de la universidad. Aprueba las líneas estratégicas y establece procedimientos de aplicación.
          </p>

          <div className="inner-container">
            <div className="block">
              <h4 className="uppercase urbana text-semi-normal">Juntas de centro</h4>
              <p className="text-normal">
                Guían y aprueban las estrategias de gestión de cada centro, supervisando la actuación de su dirección o equipo decanal.
              </p>
            </div>

            <div className="block">
              <h4 className="uppercase urbana text-semi-normal">Consejo social</h4>
              <p className="text-normal">
                Es el órgano de relación con la sociedad. Le corresponde la supervisión de las actividades económicas y la aprobación de nuevas titulaciones
                oficiales.
              </p>
            </div>

            <div className="block">
              <h4 className="uppercase urbana text-semi-normal">Consejo de estudiantes</h4>
              <p className="text-normal">El Consejo de Estudiantes (CEUAM) está formado por representantes de estudiantes de cada centro de la UAM.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  // Modals area
  const location = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Localización</h3>
          <div className="location-diagram">
            <img src="/svg/meet-studies-center/location-diagram.png" alt="" />
          </div>
          <div className="actual-text">
            <p className="text-normal">
              El campus de la UAM cuenta con <span className="bold">ocho centros</span> distribuidos en <span className="bold">dos sedes</span>: la de Medicina
              (junto al Hospital La Paz), en la que se encuentra la Facultad de Medicina; y la sede de Cantoblanco, donde se ubican el resto de facultades
              –Ciencias, Ciencias Económicas y Empresariales, Derecho, Filosofía y Letras, Formación de Profesorado y Educación, Psicología– y la Escuela
              Politécnica Superior.
            </p>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Plano del campus UAM">
                <MapCard color={color} secondary={secondary} text="Plano campus UAM" map={plano_uam} contacts={contacts} setFullScreen={setFullScreen} />
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Planos de los centros UAM">
                <MapCard color={color} secondary={secondary} text="Edificio Biológicas" map={plano_biologicas} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Escuela Politécnica Superior" map={plano_politecnica_a} setFullScreen={setFullScreen} />
                <MapCard
                  color={color}
                  secondary={secondary}
                  text="Escuela Politécnica Superior (Edif. C)"
                  map={plano_politecnica_b}
                  setFullScreen={setFullScreen}
                />
                <MapCard color={color} secondary={secondary} text="Facultad de Ciencias Económicas" map={plano_economicas} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Ciencias" map={plano_ciencias} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Medicina" map={plano_medicina} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Derecho" map={plano_derecho_a} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Derecho (Edif. Jurídicas)" map={plano_derecho_b} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Filosofía y Letras" map={plano_filosofia} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Profesorado y Educación" map={plano_profesorado} setFullScreen={setFullScreen} />
                <MapCard color={color} secondary={secondary} text="Facultad de Psicología" map={plano_psicologia} setFullScreen={setFullScreen} />
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && !fullScreen && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const centerOrganised = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">¿Cómo está organizado tu centro?</h3>
          <div className="actual-text">
            <p className="text-normal">
              Si consigues un ejemplar físico de esta guía (pásate por UAM Estudiantes) encontrarás una sección con los planos de tu centro que te ayudará a
              familiarizarte con la distribución de aulas y la localización de las zonas comunes más importantes.
            </p>
            <p className="text-normal">
              Además de la organización espacial, debes conocer cómo funciona la gestión de tu centro, que tiene una parte administrativa y otra académica.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Estructura administrativa">
                {adminStructure}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Estructura académica">
                {academicStructure}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const uamGovernment = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">El Gobierno de la UAM</h3>
          <div className="actual-text">
            <p className="text-normal">
              Como puedes imaginar, tu centro de estudios solo es una pequeña parte de la Universidad. Del mismo modo que todos los miembros de una Facultad
              eligen al equipo de personas que administran el centro, cada cuatro años la comunidad universitaria al completo elige al Rector o Rectora, que es
              la máxima autoridad académica de la Universidad y quien nombra al resto del Equipo de Gobierno de la Universidad.
            </p>

            {vicerrector}

            <div className="questions-container-list">
              {/* <Desplegable secondary={secondary} color={color} title="Vicerrectorado">
                                {vicerrector}
                            </Desplegable> */}
              <Desplegable secondary={secondary} color={color} title="Estructura de gobierno">
                {governmentStructure}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'location') isDesktop ? setModal(location) : modalHandling(location)
    else if (modalContent === 'center-organised') isDesktop ? setModal(centerOrganised) : modalHandling(centerOrganised)
    else if (modalContent === 'uam-government') isDesktop ? setModal(uamGovernment) : modalHandling(uamGovernment)
    else isDesktop && setModalContent('location')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/StudiesCenter_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/StudiesCenter_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/StudiesCenter.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <VerticalWaves color={secondary} />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop StudiesCenter ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Conoce tu centro de estudios</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'location' ? 'selected' : ''}`}
                              onClick={() => setModalContent('location')}
                            >
                              1. Localización{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'center-organised' ? 'selected' : ''}`}
                              onClick={() => setModalContent('center-organised')}
                            >
                              2. ¿Cómo está organizado tu centro?{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'uam-government' ? 'selected' : ''}`}
                              onClick={() => setModalContent('uam-government')}
                            >
                              3. El gobierno de la UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="studies-center-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`StudiesCenter main-page ${color}`} id="studies-center">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="studies-center">
                {Splash}
              </div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Conoce tu centro de estudios</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="studies-center-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Conoce tu centro de estudios</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('location')}>
                      <span>1. Localización</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('center-organised')}>
                      <span>2. ¿Cómo está organizado tu centro?</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('uam-government')}>
                      <span>3. El gobierno de la UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>

              <div className="bottom-container" >
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default StudiesCenter
