import React, { useState } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { associationsData } from '../../data'
import AngleLeft from '../Icons/AngleLeft'
import Birrete from '../Icons/Birrete'
import Chip from '../Icons/Chip'
import Book from '../Icons/Book'
import Microscope from '../Icons/Microscope'
import Medical from '../Icons/Medical'
import Statistics from '../Icons/Statistics'
import Diploma from '../Icons/Diploma'
import Brain from '../Icons/Brain'
import AssociationDetail from './AssociationDetail/AssociationDetail'
import './Associations.scss'

const AssociationCard = ({ association, color, secondary, openAssociation }) => (
  <ReactVisibilitySensor>
    {({ isVisible }) => (
      <div className={`association-card ${color} ${isVisible ? 'visible' : 'hidden'}`} onClick={() => openAssociation(association)}>
        <p className="bold">{association.name}</p>
        <div className={`open-modal-icon ${secondary}`}>+</div>
      </div>
    )}
  </ReactVisibilitySensor>
)

const Associations = ({ color, secondary, showBackButton }) => {
  const [openTabs, setOpenTabs] = useState([])
  const [selectedAssociation, setSelectedAssociation] = useState()
  const categories = [
    {
      name: 'Interfacultativa',
      icon: <Birrete color={secondary} />
    },
    {
      name: 'Escuela Politécnica Superior',
      icon: <Chip color={secondary} />
    },
    {
      name: 'Facultad de Ciencias',
      icon: <Microscope color={secondary} />
    },
    {
      name: 'Facultad de Filosofía y Letras',
      icon: <Book color={secondary} />
    },
    {
      name: 'Facultad de Ciencias Económicas y Empresariales',
      icon: <Statistics color={secondary} />
    },
    {
      name: 'Facultad de Medicina',
      icon: <Medical color={secondary} />
    },
    {
      name: 'Facultad de Derecho',
      icon: <Diploma color={secondary} />
    },
    {
      name: 'Facultad de Psicología',
      icon: <Brain color={secondary} />
    }
  ]

  /**
   * Open the tab if closed, close it if open
   * @param {string} tab Group of associations
   */
  const toggleTab = (tab) => {
    const provTabs = openTabs
    const index = provTabs.indexOf(tab)
    if (index >= 0) provTabs.splice(index, 1)
    else provTabs.push(tab)
    setOpenTabs([...provTabs])
  }

  const openAssociation = (association) => {
    setSelectedAssociation(association)
    showBackButton(false)
  }

  const closeAssociation = () => {
    setSelectedAssociation(null)
    showBackButton(true)
  }

  return (
    <>
      <div className="Associations">
        {categories.map((cat, i) => (
          <div key={i}>
            <div className="group-title-container" onClick={() => toggleTab(cat.name)}>
              <div className="icon-container">{cat.icon}</div>
              <div className="main-container">
                <p className={`text-semi-normal heroic italic uppercase ${color}`}>{cat.name}</p>
                <div className={`arrow-container ${openTabs.indexOf(cat.name) >= 0 ? 'open' : 'closed'}`}>
                  <AngleLeft width={20} color={secondary} />
                </div>
              </div>
            </div>
            <div className={`associations-list ${openTabs.indexOf(cat.name) >= 0 ? 'open' : 'closed'}`}>
              {associationsData
                .filter((ass) => ass.center === cat.name)
                .map((association) => (
                  <AssociationCard key={association.name} association={association} color={color} secondary={secondary} openAssociation={openAssociation} />
                ))}
            </div>
          </div>
        ))}
      </div>
      <AssociationDetail open={selectedAssociation} closeAssociation={closeAssociation} association={selectedAssociation} color={color} secondary={secondary} />
    </>
  )
}

export default Associations
