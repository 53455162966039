import React from 'react'
import { colorToHex } from '../../data'

const Book = ({ width = 41, color = 'orange' }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.5007 5.02441H23.834C22.6 5.02859 21.4117 5.49202 20.5007 6.32441C19.5896 5.49202 18.4013 5.02859 17.1673 5.02441H5.50065C5.05862 5.02441 4.6347 5.20001 4.32214 5.51257C4.00958 5.82513 3.83398 6.24905 3.83398 6.69108V31.6911C3.83398 32.1331 4.00958 32.557 4.32214 32.8696C4.6347 33.1822 5.05862 33.3577 5.50065 33.3577H15.0973C15.974 33.3577 16.834 33.7144 17.454 34.3344L19.3223 36.2027C19.3373 36.2177 19.3573 36.2227 19.3723 36.2377C19.5157 36.3694 19.6757 36.4861 19.8623 36.5644H19.8657C20.2723 36.7328 20.7291 36.7328 21.1357 36.5644H21.139C21.3257 36.4861 21.4857 36.3694 21.629 36.2377C21.644 36.2227 21.664 36.2177 21.679 36.2027L23.5473 34.3344C24.1738 33.7116 25.0205 33.3607 25.904 33.3577H35.5007C35.9427 33.3577 36.3666 33.1822 36.6792 32.8696C36.9917 32.557 37.1673 32.1331 37.1673 31.6911V6.69108C37.1673 6.24905 36.9917 5.82513 36.6792 5.51257C36.3666 5.20001 35.9427 5.02441 35.5007 5.02441ZM15.0973 30.0244H7.16732V8.35775H17.1673C18.0873 8.35775 18.834 9.10608 18.834 10.0244V31.1727C17.7297 30.4281 16.4291 30.0285 15.0973 30.0244ZM33.834 30.0244H25.904C24.5723 30.0244 23.264 30.4344 22.1673 31.1727V10.0244C22.1673 9.10608 22.914 8.35775 23.834 8.35775H33.834V30.0244Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Book
