import React from 'react'
import { colorToHex } from '../../data'

const SparkleLight = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 40)} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3574 33.459H25.9766V35.5828H15.3574V33.459Z" fill={colorToHex(color)} />
      <path d="M17.4805 37.707H23.852V39.8309H17.4805V37.707Z" fill={colorToHex(color)} />
      <path
        d="M20.6668 10.0977C17.8504 10.0977 15.1494 11.2165 13.1579 13.2079C11.1665 15.1994 10.0477 17.9004 10.0477 20.7168C9.97585 22.2601 10.2715 23.7984 10.9102 25.2052C11.549 26.6119 12.5126 27.8469 13.7219 28.8086C14.7838 29.7962 15.3572 30.359 15.3572 31.3359H17.4811C17.4811 29.382 16.3023 28.2882 15.1555 27.237C14.163 26.4769 13.372 25.4851 12.8517 24.3484C12.3315 23.2117 12.0979 21.9647 12.1715 20.7168C12.1715 18.4637 13.0665 16.3029 14.6597 14.7097C16.2529 13.1165 18.4137 12.2215 20.6668 12.2215C22.9199 12.2215 25.0807 13.1165 26.6739 14.7097C28.2671 16.3029 29.1621 18.4637 29.1621 20.7168C29.2344 21.9657 28.9992 23.2132 28.4771 24.35C27.955 25.4867 27.162 26.4781 26.1675 27.237C25.0313 28.2989 23.8525 29.3608 23.8525 31.3359H25.9764C25.9764 30.359 26.5392 29.7962 27.6117 28.798C28.8201 27.838 29.7833 26.6048 30.4221 25.1999C31.0608 23.795 31.3569 22.2585 31.2859 20.7168C31.2859 19.3223 31.0113 17.9414 30.4776 16.653C29.9439 15.3647 29.1617 14.194 28.1757 13.2079C27.1896 12.2219 26.0189 11.4397 24.7306 10.906C23.4422 10.3723 22.0613 10.0977 20.6668 10.0977Z"
        fill={colorToHex(color)}
      />
      <path d="M20.4717 6.73214V0" stroke={colorToHex(color)} strokeWidth="3" strokeMiterlimit="10" />
      <path d="M11.1036 10.6036L6.33496 5.83496" stroke={colorToHex(color)} strokeWidth="3" strokeMiterlimit="10" />
      <path d="M7.23214 19.9717H0.5" stroke={colorToHex(color)} strokeWidth="3" strokeMiterlimit="10" />
      <path d="M33.7676 19.9717H40.4997" stroke={colorToHex(color)} strokeWidth="3" strokeMiterlimit="10" />
      <path d="M29.8965 10.6036L34.609 5.83496" stroke={colorToHex(color)} strokeWidth="3" strokeMiterlimit="10" />
    </svg>
  )
}

export default SparkleLight
