import './UAMFirstSteps.scss'

import * as lottie_mail from '../../lotties/UAMFirstSteps/mail.json'
import * as lottie_message from '../../lotties/UAMFirstSteps/message.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import CardMockup from '../../components/CardMockup/CardMockup'
import Desplegable from '../../components/Desplegable/Desplegable'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import MailBox from '../../components/MailBox/MailBox'
import VerticalWaves from '../../components/VerticalWaves/VerticalWaves'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const UAMFirstSteps = ({
  color = 'orange',
  secondary = 'dark-blue',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)

  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  // https://lottiefiles.com/16763-animated-mail-illustration
  const lottieMail = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_mail.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  // https://lottiefiles.com/24910-new-message-notification
  const lottieMessage = (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        animationData: lottie_message.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Modals area
  const idUAM = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Activa tu ID-UAM</h3>
          {lottieMessage}
          <p className="text-normal">
            Después de matricularte, <span className="bold">habrás recibido un mensaje</span> en el que se te informa de tu identificador asignado (ID-UAM),{' '}
            <span className="bold">así como del código con el que poder activarlo</span>.
          </p>
          <p className="text-normal">
            Utilizarás el ID-UAM para <span className="bold">acceder a los diferentes servicios telemáticos</span> de la Autónoma,{' '}
            <span className="bold">incluido el correo electrónico</span>.
          </p>
          <p className="text-normal">
            <span className="bold">Para activarlo</span>, teclea en el navegador <span className="bold">“autoservicio uam”</span>, haz clic en el primer
            resultado y sigue las instrucciones del mensaje.
          </p>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const emailAccount = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Accede a tu cuenta de correo electrónico</h3>
          <p>
            Como estudiante de la UAM, debes usar el <span className="bold">ID-UAM como tu</span> dirección personal de correo electrónico:
          </p>
          {lottieMail}
          <div className="mail-box">
            <p>nombre.apellido@estudiante.uam.es</p>
          </div>
          <p>
            <span className="bold">Es muy importante que accedas cuanto antes</span> y la uses siempre que escribas sobre asuntos relacionados con tus estudios.
            Si todavía no lo has hecho, <span className="bold">teclea “webmail uam” en el navegador</span> e introduce tu correo y contraseña.
          </p>
          <p>
            <span className="bold">No olvides revisar con frecuencia tu bandeja de entrada</span>: quizá no todos los mensajes sean de tu interés, pero en el
            entorno universitario <span className="bold">este es el canal a través del que recibirás información crucial</span> que deberías leer a tiempo.
          </p>
          <MailBox text={'correoweb.uam.es'} />
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const uniCarnet = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Solicita tu carné universitario</h3>
          <div className="actual-text">
            <p className="text-normal">
              Es necesario para acreditarte y usar los servicios que integra. El carné tiene validez hasta que finalizas los estudios y no tiene{' '}
              <span className="bold">ningún coste</span>.
            </p>
            <CardMockup />
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title={'¿Cómo obtenerlo?'}>
                <div className="desplegable-container how-obtain">
                  <p className="text-normal">
                    El carné es <span className="bold">completamente digital</span> y puedes visualizarlo a través de la UAM app (más info en sección CONFIGURA
                    TU ENTORNO DIGITAL). Por defecto, en el Carné de Estudiante no se muestra ninguna foto personal, pero si quieres que aparezca una, deberás{' '}
                    <span className="bold">reservar cita</span> para tomarte una foto de manera acreditada durante la campaña específica que se organiza al
                    inicio del curso, tanto en el campus de Cantoblanco como en el de Medicina. Presta atención al correo electrónico para conocer las fechas.
                  </p>
                  <p className="text-normal">
                    Este año se realizará 
                    <span className="bold">entre el 9 de septiembre y el 11 de octubre</span>, en el Campus <span className="bold">de Cantoblanco</span> (1ª
                    planta Edificio Plaza Mayor UAM)
                  </p>
                  <ExternalLink
                    url="https://outlook.office365.com/owa/calendar/UniversidadAutonomadeMadridCarnetUniversitario@dauam.onmicrosoft.com/bookings/"
                    text="Reserva tu cita aquí"
                    color={secondary}
                    secondary={color}
                  />
                  <p className="text-normal">
                    y <span className="bold">del 16 al 20 de septiembre</span>, en el Campus <span className="bold">de Medicina</span> (hall de la Facultad)
                  </p>

                  <ExternalLink
                    url="https://outlook.office365.com/owa/calendar/UniversidadAutonomadeMadridCarnetUniversitarioMedicina@dauam.onmicrosoft.com/bookings/"
                    text="Reserva tu cita aquí"
                    color={secondary}
                    secondary={color}
                  />

                  <p className="text-normal">
                    <span className="bold">Importante</span>: recuerda que si no acudes con cita no se visualizará tu foto en el carné.
                  </p>
                  <p className="text-normal">
                    Una vez finalizada la campaña podrás subir tu foto a través de{' '}
                    <a className="bold" href="https://www.uam.es/uam/tecnologias-informacion/servicios/foto-digital">
                      este enlace
                    </a>
                    , pero no será una foto acreditada y en el carné se mostrará un <span className="bold">logotipo</span> en lugar de la foto personal.
                  </p>
                  <p className="text-normal">
                    Una vez hayas subido tu foto, acreditada o no, podrás disponer del carné en formato digital descargando{' '}
                    <span className="bold">la AppUAM</span>.
                  </p>

                  <div className="logo-container uam-app">
                    <img src="/svg/logos/logo-square-uam.svg" alt="" />
                  </div>
                  <div className="logo-container google-play">
                    <img src="/svg/logos/google-play.png" alt="" />
                  </div>
                  <div className="logo-container apple-store">
                    <img src="/svg/logos/apple-store.png" alt="" />
                  </div>

                  <ExternalLink text={'Más info sobre el carné'} url={'https://www.uam.es/uam/carne'} color={color} secondary={secondary} />

                  <p className="text-normal">También estaremos disponibles para resolver tus dudas en la Oficina UAM Estudiantes</p>
                  <AddressBookComp
                    color={color}
                    data={{
                      title: contacts['uam-estudiantes'].title,
                      address: contacts['uam-estudiantes'].address,
                      map: contacts['uam-estudiantes'].map,
                      web: contacts['uam-estudiantes'].web
                    }}
                  />
                </div>
              </Desplegable>
              <hr />
              <Desplegable secondary={secondary} color={color} title={'¿Para qué sirve el carné UAM?'}>
                <div className="desplegable-container what-is-it-for">
                  <ul className={`kite-list ${color}`}>
                    <li>
                      <ListItem color={color} kiteColor={secondary}>
                        Para el <span className="bold">préstamo de libros y portátiles</span> en las bibliotecas de la UAM.
                      </ListItem>
                    </li>
                    <li>
                      <ListItem color={color} kiteColor={secondary}>
                        Para reservar y utilizar las <span className="bold">instalaciones deportivas</span>.
                      </ListItem>
                    </li>
                    <li>
                      <ListItem color={color} kiteColor={secondary}>
                        Como <span className="bold">monedero</span> para realizar pequeños pagos en el campus, por ejemplo, en las fotocopiadoras situadas en
                        los pasillos de cada centro.
                      </ListItem>
                    </li>
                    <li>
                      <ListItem color={color} kiteColor={secondary}>
                        Para <span className="bold">acreditar tu condición de estudiante</span> tanto dentro como fuera de la Universidad (y acceder así a las
                        ventajas que ofrecen muchos museos e instituciones).
                      </ListItem>
                    </li>
                  </ul>
                  <p className="text-normal">
                    Puedes cargar el monedero en la <b>reprografía</b> (mira la sección GESTIONA TU DÍA A DÍA) y <b>online</b> a través de esta web
                  </p>
                  <ExternalLink url="https://recarga-impresion.uam.es" color={secondary} secondary={color} text="Cargar el monedero" />
                  <p className="text-normal">
                    Escribe al <span className="bold">CAUTI</span> para solicitar un código de registro.
                  </p>
                  <ExternalLink url={`mailto://${contacts['cauti'].emails[0]}`} color={secondary} secondary={color} text={contacts['cauti'].emails[0]} />
                </div>
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'uni-carnet') isDesktop ? setModal(uniCarnet) : modalHandling(uniCarnet)
    else if (modalContent === 'email-account') isDesktop ? setModal(emailAccount) : modalHandling(emailAccount)
    else if (modalContent === 'id-uam') isDesktop ? setModal(idUAM) : modalHandling(idUAM)
    else isDesktop && setModalContent('id-uam')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/UAMFirstSteps_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/png/UAMFirstSteps_logo_main.png" className="illustration" alt="" />
        <img src="/splash/png/UAMFirstSteps.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <VerticalWaves color={secondary} />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop UAMFirstSteps ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block" onClick={() => setOpenDesktop(true)}>
            <div className="left side">
              <h1 className="urbana uppercase">Primeros pasos en la UAM</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'id-uam' ? 'selected' : ''}`}
                              onClick={() => setModalContent('id-uam')}
                            >
                              1. Activa tu ID UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'email-account' ? 'selected' : ''}`}
                              onClick={() => setModalContent('email-account')}
                            >
                              2. Accede a tu cuenta de correo electrónico{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'uni-carnet' ? 'selected' : ''}`}
                              onClick={() => setModalContent('uni-carnet')}
                            >
                              3. Obtén tu Carné de Estudiante{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="first-steps-logo">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side">
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`UAMFirstSteps main-page ${color}`} id="first-steps">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="first-steps-logo">
                {Splash}
              </div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Primeros pasos en la UAM</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="first-steps-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Primeros pasos en la UAM</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('id-uam')}>
                      <span>1. Activa tu ID UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('email-account')}>
                      <span>2. Accede a tu cuenta de correo electrónico</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('uni-carnet')}>
                      <span>3. Solicita tu carné universitario</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>

              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UAMFirstSteps
