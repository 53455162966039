import React from 'react'
import './BackContainer.scss'
import AngleLeft from '../Icons/AngleLeft'

const BackContainer = ({ goBack = () => console.log('Go back!'), color = 'dark-blue', negative, secondary }) => {
  return (
    <div className={`back-container ${secondary} ${negative ? 'negative' : ''} nosearch`}>
      <div className="back-button" onClick={() => goBack()}>
        <AngleLeft color={negative ? 'white' : color} />
        <p className="text-normal">Volver</p>
      </div>
      <div className="logo-container">
        <img src={`/svg/logo-uam/logo-uam-estudiantes-${negative ? 'negative' : color}.svg`} alt="" />
      </div>
    </div>
  )
}

export default BackContainer
