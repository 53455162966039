import React from 'react'
import { colorToHex } from '../../data'

const X = ({ color = 'orange', width = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width={width}
      height={width * (512 / 462)}
      viewBox="0 0 512 462.799"
    >
      <path
        fillRule="nonzero"
        fill={colorToHex(color)}
        d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
      ></path>
    </svg>
  )
}

export default X
