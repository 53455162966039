import React from 'react'
import './Kites.scss'
import VisibilitySensor from 'react-visibility-sensor'

const Kites = () => {
  return (
    <VisibilitySensor>
      {({ isVisible }) => (
        <div className={`Kites ${isVisible ? 'visible' : 'hidden'}`}>
          <div className="ceal kite">
            <img src="/svg/kites/ceal.svg" alt="" />
          </div>
          <div className="sicue kite">
            <img src="/svg/kites/sicue.svg" alt="" />
          </div>
          <div className="erasmus kite">
            <img src="/svg/kites/erasmus.svg" alt="" />
          </div>
          <div className="semp kite">
            <img src="/svg/kites/semp.svg" alt="" />
          </div>
          <div className="spacer-kites" />
        </div>
      )}
    </VisibilitySensor>
  )
}

export default Kites
