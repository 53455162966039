import './App.scss'

import React from 'react'
import Home from './containers/Home/Home'
import { ModalProvider } from './contexts'

function App() {
  return (
    <div className="App">
      <ModalProvider
        value={{
          showModalBackButton: true
        }}
      >
        <Home />
      </ModalProvider>
    </div>
  )
}

export default App
