import React from 'react'
import { colorToHex } from '../../data'

const Cursor = ({ color = 'purple' }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6781 0.265914C3.80812 0.1723 3.96348 0.120312 4.12366 0.116815C4.28384 0.113318 4.44131 0.158473 4.5753 0.246325L23.0424 12.3257C23.1715 12.4101 23.2732 12.5304 23.335 12.6718C23.3967 12.8133 23.4158 12.9696 23.3899 13.1218C23.364 13.2739 23.2942 13.4151 23.1892 13.5281C23.0841 13.6411 22.9483 13.721 22.7985 13.7579L14.827 15.739L10.4229 22.675C10.3402 22.8048 10.2214 22.9078 10.0811 22.9712C9.94081 23.0346 9.78507 23.0558 9.63294 23.0321C9.4808 23.0084 9.33887 22.9409 9.2245 22.8378C9.11013 22.7347 9.02827 22.6005 8.98895 22.4517L3.37555 1.11079C3.33494 0.956133 3.34208 0.79281 3.39604 0.642292C3.44999 0.491775 3.54824 0.36111 3.67785 0.267478L3.6781 0.265914Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Cursor
