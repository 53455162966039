import React from 'react'
import { colorToHex } from '../../data'

const Sendvelope = ({ color = 'orange' }) => {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 12.7719H15.5V9.82456L20.5 14.2456L15.5 18.6667V15.7193H11.5V12.7719ZM18.5 0H2.5C1.96957 0 1.46086 0.207017 1.08579 0.575509C0.710714 0.944002 0.5 1.44379 0.5 1.96491V13.7544C0.5 14.2755 0.710714 14.7753 1.08579 15.1438C1.46086 15.5123 1.96957 15.7193 2.5 15.7193H9.5V13.7544H2.5V3.92982L10.5 8.8421L18.5 3.92982V9.82456H20.5V1.96491C20.5 1.44379 20.2893 0.944002 19.9142 0.575509C19.5391 0.207017 19.0304 0 18.5 0ZM10.5 6.87719L2.5 1.96491H18.5L10.5 6.87719Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Sendvelope
