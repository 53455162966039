import './AddressBook.scss'

import React, { useState } from 'react'

import Envelope from '../Icons/Envelope'
import LocationPin from '../Icons/LocationPin'
import Network from '../Icons/Network'
import Phone from '../Icons/Phone'
import Sendvelope from '../Icons/Sendvelope'

const AddressBookComp = ({ data, color }) => {
  const { emails, phone, title, web, address, map } = data
  const [hovered, setHovered] = useState('')
  const [showEmailList, setShowEmailList] = useState(false)

  const parsePhone = (phone) => {
    const parsed = phone.split('/')[0].trim()
    return parsed
  }

  const handleEmail = () => {
    if (emails?.length === 1) window.location.href = `mailto:${emails[0]}`
    else setShowEmailList(!showEmailList)
  }

  const handleAddress = () => {
    if (map?.length > 0) window.open(map, '_blank')
  }

  return (
    <div className={`AddressBookComp ${color || data.color}`}>
      <div className="top">
        <p className="bold heroic italic uppercase text-center text-medium">{title}</p>
      </div>
      {address?.length > 0 && (
        <div className="middle" onClick={() => handleAddress()} title={address}>
          <div className="icon-container">
            <LocationPin width={20} color={color || data.color} />
          </div>
          <p className="text-semi-normal uppercase heroic italic address-string">{address}</p>
        </div>
      )}
      <div className="bottom">
        {phone && (
          <a
            href={`tel:${parsePhone(phone)}`}
            className="item-container phone"
            onMouseEnter={() => setHovered('phone')}
            onMouseLeave={() => setHovered('')}
            title={phone}
          >
            <div className={`icon-container phone ${hovered === 'phone' ? 'twinkle' : ''}`}>
              <Phone width={30} color={color || data.color} />
            </div>
            <p className="subtitle">{phone}</p>
          </a>
        )}

        {web && (
          <a
            href={web}
            target="_blank"
            rel="noopener noreferrer"
            className="item-container web"
            onMouseEnter={() => setHovered('web')}
            onMouseLeave={() => setHovered('')}
            title={web}
          >
            <div className={`icon-container phone ${hovered === 'web' ? 'twinkle' : ''}`}>
              <Network width={30} color={color || data.color} />
            </div>
            <p className="subtitle">{web?.length > 20 ? web.split('').slice(0, 19).join('') + '...' : web}</p>
          </a>
        )}

        {emails?.length > 0 && (
          <div
            className="item-container emails"
            onMouseEnter={() => setHovered('email')}
            onMouseLeave={() => setHovered('')}
            onClick={() => handleEmail()}
            title={emails[0]}
          >
            <div className={`icon-container ${hovered === 'email' ? 'twinkle' : ''}`}>
              <Envelope width={30} color={color || data.color} />
            </div>
            {emails.length === 1 ? (
              <p className="subtitle">{emails[0]}</p>
            ) : (
              <p className="subtitle">{showEmailList ? 'Ocultar' : 'Ver'} las direcciones de e-mail</p>
            )}
          </div>
        )}
      </div>
      {emails?.length > 1 && (
        <div
          className={`emails-container ${showEmailList ? 'open' : 'close'}`}
          style={{
            maxHeight: showEmailList ? `${60 * emails.length}px` : 0
          }}
        >
          {emails.map((mail, i) => (
            <div
              className="email-box"
              title={mail}
              key={mail}
              style={{
                animationDelay: `${i * 0.05 + 0.3}s`
              }}
            >
              <div className="icon-container">
                <Sendvelope color={color || data.color} />
              </div>
              <a href={`mailto:${mail}`}>{mail}</a>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AddressBookComp
