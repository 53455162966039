import React from 'react'
import { colorToHex } from '../../data'

const Network = ({ color = 'green', width = 21 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4536 12.0693C14.5331 11.4093 14.5927 10.7493 14.5927 10.0693C14.5927 9.3893 14.5331 8.72931 14.4536 8.06931L17.812 8.06931C17.9709 8.70931 18.0703 9.3793 18.0703 10.0693C18.0703 10.7593 17.9709 11.4293 17.812 12.0693L14.4536 12.0693ZM12.6949 17.6293C13.2911 16.5193 13.7482 15.3193 14.0661 14.0693H16.9972C16.0346 15.7376 14.5074 17.0013 12.6949 17.6293ZM12.4465 12.0693H7.7965C7.69714 11.4093 7.63753 10.7493 7.63753 10.0693C7.63753 9.3893 7.69714 8.71931 7.7965 8.06931L12.4465 8.06931C12.536 8.71931 12.6055 9.3893 12.6055 10.0693C12.6055 10.7493 12.536 11.4093 12.4465 12.0693ZM10.1215 18.0293C9.29684 16.8293 8.63113 15.4993 8.22375 14.0693H12.0193C11.6119 15.4993 10.9462 16.8293 10.1215 18.0293ZM6.14713 6.06932H3.24583C4.19854 4.39654 5.72465 3.13081 7.53817 2.50933C6.94201 3.61932 6.49489 4.81932 6.14713 6.06932ZM3.24583 14.0693L6.14713 14.0693C6.49489 15.3193 6.94201 16.5193 7.53817 17.6293C5.72845 17.0009 4.2045 15.737 3.24583 14.0693ZM2.43108 12.0693C2.2721 11.4293 2.17274 10.7593 2.17274 10.0693C2.17274 9.3793 2.2721 8.70931 2.43108 8.06931H5.78944C5.70995 8.72931 5.65033 9.3893 5.65033 10.0693C5.65033 10.7493 5.70995 11.4093 5.78944 12.0693H2.43108ZM10.1215 2.09933C10.9462 3.29933 11.6119 4.63932 12.0193 6.06932L8.22375 6.06932C8.63113 4.63932 9.29684 3.29933 10.1215 2.09933ZM16.9972 6.06932L14.0661 6.06932C13.7551 4.83078 13.2946 3.63522 12.6949 2.50933C14.5232 3.13933 16.0434 4.40932 16.9972 6.06932ZM10.1215 0.0693359C4.62693 0.0693359 0.185547 4.56932 0.185547 10.0693C0.185547 12.7215 1.23237 15.265 3.09573 17.1403C4.01837 18.0689 5.1137 18.8055 6.31919 19.3081C7.52468 19.8106 8.81671 20.0693 10.1215 20.0693C12.7567 20.0693 15.284 19.0157 17.1473 17.1403C19.0107 15.265 20.0575 12.7215 20.0575 10.0693C20.0575 8.75609 19.8005 7.45573 19.3012 6.24248C18.8018 5.02923 18.07 3.92684 17.1473 2.99826C16.2247 2.06968 15.1293 1.33308 13.9239 0.830538C12.7184 0.327993 11.4263 0.0693359 10.1215 0.0693359Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Network
