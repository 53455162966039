import './ProfessionalProfile.scss'

import * as lottie_professional_profile_open_aula from '../../lotties/ProfessionalProfile/open_aula.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import Award from '../../components/Icons/Award'
import BackContainer from '../../components/BackContainer/BackContainer'
import Book from '../../components/Icons/Book'
import Briefcase from '../../components/Icons/Briefcase'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import Diplom from '../../components/Icons/Diplom'
import Diploma from '../../components/Icons/Diploma'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import PeopleNur from '../../components/Icons/PeopleNur'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const ProfessionalProfile = ({
  color = 'dark-teal',
  secondary = 'red-covid',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  const lottieOpenAula = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_professional_profile_open_aula.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // ======= Desplegables area ======= \\
  const short_term_courses = (
    <div className="desplegable-container short_term_courses">
      <div className="center-icon">
        <Award color={secondary} />
      </div>
      <p className="text-normal">
        Si quieres completar tu currículum, con nuestros cursos podrás adquirir una <b>formación complementaria</b> y especializada en una gran variedad de
        temáticas. Son cursos diseñados para la <b>adquisición de nuevas habilidades</b> o conocimientos, o sencillamente para obtener una mayor formación en
        algún ámbito concreto no contemplado en los planes de estudio oficiales. <b>Será lo que te diferencie</b> de otras candidaturas en el mercado laboral.
      </p>
      <p className="text-normal">
        Ten en cuenta, además, que a través de muchos de estos cursos podrás obtener <b>reconocimiento de créditos</b> para tus estudios de Grado.
      </p>
      <p className="text-normal">Podrás beneficiarte de descuentos en el caso de ser alumno UAM, AlumniUAM+, AlumniUAM+ Plus o Amigos de la UAM.</p>
    </div>
  )

  const long_term_courses = (
    <div className="desplegable-container long_term_courses">
      <div className="center-icon">
        <Diplom color={secondary} />
      </div>
      <p className="text-normal">
        También tienes a tu disposición un amplio catálogo de enseñanzas propias de larga duración <b>(de 20 a 120 ECTS)</b>. Se trata de una formación
        orientada a la especialización académica o profesional. Estos programas están diseñados en colaboración con profesionales de alto prestigio y están
        pensados para responder de manera ágil a las necesidades del mercado de trabajo y la sociedad. Completar la formación y reciclarse es el objetivo
        principal de estos estudios, que se organizan en los siguientes tipos: <b>Diploma de Experto</b>, <b>Diploma de Especialización</b> o{' '}
        <b>Máster de Formación Permanente</b>.
      </p>
    </div>
  )

  const micro_credentials = (
    <div className="desplegable-container micro_credentials">
      <div className="center-icon">
        <Diploma color={secondary} />
      </div>
      <p className="text-normal">
        Las microcredenciales son <b>cursos breves y flexibles</b>, que permiten la adquisición específica de capacidades y competencias que responden a
        necesidades sociales y del mercado laboral. El resultado de ese aprendizaje se <b>certifica digitalmente</b>, en un formato compatible con plataformas
        como <b>Europass</b>, para asegurar su validez y reconocimiento en el ámbito europeo, y para favorecer su portabilidad.
      </p>
    </div>
  )

  const stamp = (
    <div className="desplegable-container stamp">
      <div className="center-icon">
        <Award color={secondary} />
      </div>
      <p className="text-normal">
        La UAM te ofrece un sistema propio para <b>acreditar la participación en actividades</b> que mejoran la empleabilidad y el desarrollo de las
        competencias transversales (soft skills) que tanto <b>valoran las entidades privadas y públicas</b>. El Sello de Empleabilidad UAM funciona como un
        <b>complemento al título</b> que favorecerá tu inserción laboral. Es algo <b>voluntario</b> y puedes conseguirlo durante tus estudios.
      </p>
      <p className="text-normal">
        Entre las actividades en las que puedes participar para obtener el Sello encontrarás cursos de formación en competencias, foros de empleo, jornadas de
        orientación profesional, un programa de mentoring, las actividades de la Semana de la Empleabilidad, talleres y otras iniciativas que serán publicadas
        en la <b>web de la OPE</b> cada curso académico, donde deberás <b>inscribirte previamente</b> para solicitar que tu asistencia a estas actividades sea
        tenida en cuenta.
      </p>

      <p className="text-normal">
        Si decides optar al Sello podrás conseguirlo participando en <b>al menos 10 actividades</b>. Si asistes a <b>16 o más</b> recibirás el{' '}
        <b>Sello de Empleabilidad UAM+</b>, una categoría superior que acredita un alto interés y compromiso con tu empleabilidad y el desarrollo profesional.
        No olvides revisar todos los detalles en la web antes de comenzar y... ¡a por ello!
      </p>
    </div>
  )

  const orientation = (
    <div className="desplegable-container orientation">
      <div className="center-icon">
        <PeopleNur color={secondary} />
      </div>
      <p className="text-normal">
        La UAM ofrece <b>sesiones gratuitas</b> de asesoramiento laboral con orientadores profesionales y especializados de forma personalizada a los
        estudiantes de <b>tercero y cuarto curso de Grado y Máster</b>, en las que podrán aprender desde <b>cómo realizar un CV</b> o{' '}
        <b>enfrentarse a una entrevista laboral</b>, hasta qué salida profesional es la más adecuada para cada estudiante dependiendo de su titulación,
        inquietudes y perfil.
      </p>
    </div>
  )

  const uamempleability = (
    <div className="desplegable-container uamempleability">
      <div className="center-icon">
        <Briefcase color={secondary} />
      </div>
      <p className="text-normal">
        La Oficina de Prácticas Externas y Empleabilidad (OPE) también ofrece <b>cursos</b> que pueden interesarte: los cursos UAMempleabilidad y los cursos
        online gratuitos de competencias profesionales. Con ellos podrás <b>enriquecer tu currículum</b> mejorando áreas transversales como motivación,
        inteligencia emocional, organización o liderazgo, así como acceder a <b>formación específica</b> en distintas áreas.
      </p>
    </div>
  )

  // Modals area
  const continueFormation = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Estudios propios y formación continua</h3>
          <div className="actual-text">
            <p className="text-normal">
              La formación no ocupa una única etapa de tu vida, ni tus estudios acaban cuando finalizas el Grado o Posgrado que estás cursando. En todo momento,
              puedes <b>complementar y enriquecer tu currículum</b> con la amplia oferta del Centro de Formación Continua de la UAM. Esta{' '}
              <b>formación flexible, innovadora</b> y de calidad da respuesta a las necesidades de nuestra sociedad.
            </p>
            <p className="text-normal">Estas enseñanzas se presentan en las siguientes modalidades:</p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Microcredenciales UAM">
                {micro_credentials}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Cursos de corta duración">
                {short_term_courses}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Títulos propios de larga duración">
                {long_term_courses}
              </Desplegable>
            </div>

            <p className="text-normal">Échale un vistazo a su web:</p>

            <ExternalLink url="https://www.uam.es/CentroFormacionContinua/Home" color={secondary} secondary={color} />

            <AddressBookComp color={color} data={contacts['formacion-continua']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const online = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Formación online</h3>
          <div className="actual-text">
            <p className="text-normal">
              <b>¿Quieres formación online y gratuita?</b> Aprovecha los MOOC (Massive Open Online Courses) que la UAM pone a disposición del público. En la
              plataforma <b>UAMx</b> dispones de recursos docentes interactivos a distancia. Puedes consultar la oferta, cada vez más amplia, en:
            </p>

            <ExternalLink color={secondary} secondary={color} url="https://www.edx.org/school/uamx" />

            <AddressBookComp color={color} data={contacts['uamx']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const microTitle = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Formación online</h3>
          <div className="actual-text">
            <p className="text-normal">
              Son una oportunidad que te ofrece la UAM para completar tu formación mientras cursas tu Grado. Se trata de programas formativos de{' '}
              <b>corta duración</b>
              (entre 24 y 36 ECTS) constituidos por asignaturas de diferentes planes oficiales de Grado, que te permitirán adquirir competencias y conocimientos
              <b>diferentes a los del Grado</b>, y que te ayudarán a enriquecer tu perfil profesional o a completar esa formación que siempre deseaste adquirir.
            </p>
            <p className="text-normal">
              Podrás solicitar la admisión y matricularte <b>a partir del segundo curso</b>, siempre y cuando tengas superados 60 ECTS. Podrás cursar hasta{' '}
              <b>12 ECTS</b> por curso académico, <b>6 ECTS</b> por semestre. Las asignaturas superadas podrán constar en tu expediente oficial y en el
              Suplemento Europeo al Título, previa solicitud de la transferencia de los créditos. Si has completado los créditos necesarios, podrás solicitar un
              diploma al finalizar tu Grado.
            </p>
            <div className="center-icon">
              <Book color={secondary} />
            </div>
            <p className="text-normal">Actualmente, la UAM ya cuenta con una oferta variada de microtítulos:</p>
            <ul className={`kite-list ${color}`}>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Programación</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Electrónica Digital</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Organización Básica de la Empresa</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Historia de España</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Filosofía, Política y Ética</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Literaturas Hispánicas</b>
                </ListItem>
              </li>
              <li>
                <ListItem color={color} kiteColor={secondary}>
                  Microtítulo en <b>Especialización en Lengua Española</b>: Teoría y Práctica
                </ListItem>
              </li>
            </ul>

            <ExternalLink color={secondary} secondary={color} text={'Más información sobre Microtítulos'} url="https://www.uam.es/uam/estudios/microtitulos" />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const openAula = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Aula abierta</h3>
          <div className="actual-text">
            {lottieOpenAula}
            <p className="text-normal">
              Para aquellos estudiantes que <b>no puedan o quieran cursar un microtítulo</b>, pero tengan interés en obtener formación sobre algún área del
              conocimiento específica, la Universidad Autónoma de Madrid también cuenta con el Programa <b>“Aula Abierta”</b> que pretende facilitar, tanto a
              sus estudiantes como a cualquier persona interesada, el <b>acceso abierto a las aulas</b> en las que se imparten determinadas asignaturas
              pertenecientes a <b>estudios oficiales de Grado o de Máster</b> que, por su interés social, cultural o profesional, permiten la adquisición de
              competencias, conocimientos y destrezas
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const jobs = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Prácticas y empleo</h3>
          <div className="actual-text">
            <p className="text-normal">
              <b>¿Te gustaría realizar prácticas externas en entidades colaboradoras?</b> Si es así, la Oficina de Prácticas Externas y Empleabilidad (OPE) te
              ayuda a encontrar las que más se ajusten a tu perfil.
            </p>
            <p className="text-normal">
              “Anímate a conocer <b>JobTeaser UAM</b>, tu plataforma online de búsqueda de empleo, en la que además podrás mejorar tu empleabilidad con{' '}
              <b>eventos y webinars</b> sobre orientación profesional, y a participar en los <b>Foros de Empleo</b> que anualmente se celebran en el campus de
              Cantoblanco. Quién sabe, ¡quizás encuentres tu primer trabajo sin salir de la Autónoma!
            </p>

            <ExternalLink url="https://uam.jobteaser.com/es/onboarding/studies-info" text={'Accede a JobTeaser'} color={secondary} secondary={color} />

            <DidYouKnow color={color}>
              <p className="text-normal">También puedes realizar prácticas externas en proyectos internacionales de Cooperación al Desarrollo.</p>
            </DidYouKnow>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Sello de empleabilidad UAM">
                {stamp}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Programa de orientación profesional personalizada">
                {orientation}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Cursos de competencias">
                {uamempleability}
              </Desplegable>
            </div>

            <DidYouKnow color={color}>
              <p className="text-normal">
                Si tienes un <b>espíritu emprendedor</b> la Universidad te ayuda a que crees tu propio <b>auto-empleo.</b> Conoce los Programas de “UAM
                Emprende”.
              </p>
              <p className="text-normal">
                Más información en:{' '}
                <a href="https://www.uamemprende.es" target="_blank" rel="noopener noreferrer" className="bold">
                  https://www.uamemprende.es
                </a>
              </p>
            </DidYouKnow>
            <AddressBookComp color={color} data={contacts['practicas-empleabilidad']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'continue') isDesktop ? setModal(continueFormation) : modalHandling(continueFormation)
    else if (modalContent === 'online') isDesktop ? setModal(online) : modalHandling(online)
    else if (modalContent === 'micro') isDesktop ? setModal(microTitle) : modalHandling(microTitle)
    else if (modalContent === 'aula') isDesktop ? setModal(openAula) : modalHandling(openAula)
    else if (modalContent === 'jobs') isDesktop ? setModal(jobs) : modalHandling(jobs)
    else isDesktop && setModalContent('continue')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/ProfessionalProfile_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/ProfessionalProfile_logo_main.png" className="illustration" alt="" />
        <img src="/splash/png/ProfessionalProfile.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <img src="/splash/svg/ProfessionalProfile_logo_front.svg" alt="" />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop ProfessionalProfile ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Completa tu perfil profesional</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'continue' ? 'selected' : ''}`}
                              onClick={() => setModalContent('continue')}
                            >
                              1. Estudios propios y de formación continua{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'online' ? 'selected' : ''}`}
                              onClick={() => setModalContent('online')}
                            >
                              2. Formación online{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'micro' ? 'selected' : ''}`} onClick={() => setModalContent('micro')}>
                              3. Microtítulos (MiTs){' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'aula' ? 'selected' : ''}`} onClick={() => setModalContent('aula')}>
                              4. Aula Abierta{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'jobs' ? 'selected' : ''}`} onClick={() => setModalContent('jobs')}>
                              5. Prácticas y empleo{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="professional-profile-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`ProfessionalProfile main-page ${color}`} id="professional-profile">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container">{Splash}</div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Completa tu perfil profesional</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="professional-profile-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Completa tu perfil profesional</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('continue')}>
                      <span>1. Estudios propios y de formación continua</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('online')}>
                      <span>2. Aula Abierta</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('micro')}>
                      <span>3. Microtítulos (MiTs)</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('aula')}>
                      <span>4. Aula Abierta</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('jobs')}>
                      <span>5. Prácticas y empleo</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfessionalProfile
