import React from 'react'
import { colorToHex } from '../../data'

const Star = ({ color = 'red', width = 35 }) => {
  return (
    <svg width={width} height={width * (width / 33)} viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.9728 11.793L23.0548 10.3516L18.6212 1.3633C18.5001 1.1172 18.3009 0.917983 18.0548 0.796889C17.4376 0.492202 16.6876 0.746108 16.379 1.3633L11.9454 10.3516L2.02748 11.793C1.75404 11.832 1.50404 11.961 1.31263 12.1563C1.08123 12.3941 0.953723 12.7141 0.958118 13.0459C0.962513 13.3777 1.09845 13.6942 1.33607 13.9258L8.51185 20.9219L6.81654 30.8008C6.77678 31.0306 6.80221 31.2669 6.88995 31.483C6.97768 31.6991 7.1242 31.8863 7.3129 32.0234C7.5016 32.1604 7.72493 32.2418 7.95755 32.2584C8.19018 32.275 8.4228 32.2261 8.62904 32.1172L17.5001 27.4531L26.3712 32.1172C26.6134 32.2461 26.8947 32.2891 27.1642 32.2422C27.8439 32.125 28.3009 31.4805 28.1837 30.8008L26.4884 20.9219L33.6642 13.9258C33.8595 13.7344 33.9884 13.4844 34.0275 13.211C34.1329 12.5274 33.6564 11.8945 32.9728 11.793ZM23.4689 19.9375L24.879 28.1524L17.5001 24.2774L10.1212 28.1563L11.5314 19.9414L5.56263 14.1211L13.8126 12.9219L17.5001 5.44923L21.1876 12.9219L29.4376 14.1211L23.4689 19.9375Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Star
