import React from 'react'
import { colorToHex } from '../../data'

const File = ({ color = 'red', width = 31 }) => {
  return (
    <svg width={width} height={width * (width / 42)} viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.05 10.95L19.55 0.45C19.25 0.15 18.95 0 18.5 0H3.5C1.85 0 0.5 1.35 0.5 3V39C0.5 40.65 1.85 42 3.5 42H27.5C29.15 42 30.5 40.65 30.5 39V12C30.5 11.55 30.35 11.25 30.05 10.95ZM18.5 3.6L26.9 12H18.5V3.6ZM27.5 39H3.5V3H15.5V12C15.5 13.65 16.85 15 18.5 15H27.5V39Z"
        fill={colorToHex(color)}
      />
      <path d="M6.5 30H24.5V33H6.5V30Z" fill={colorToHex(color)} />
      <path d="M6.5 21H24.5V24H6.5V21Z" fill={colorToHex(color)} />
    </svg>
  )
}

export default File
