import React from 'react'
import { colorToHex } from '../../data'

const Chip = ({ width = 41, color = 'orange' }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 15.0244H25.5V25.0244H15.5V15.0244Z" fill={colorToHex(color)} />
      <path
        d="M33.834 10.0241C33.834 8.18576 32.339 6.69076 30.5007 6.69076H27.1673V3.35742H23.834V6.69076H17.1673V3.35742H13.834V6.69076H10.5007C8.66232 6.69076 7.16732 8.18576 7.16732 10.0241V13.3574H3.83398V16.6908H7.16732V23.3574H3.83398V26.6908H7.16732V30.0241C7.16732 31.8624 8.66232 33.3574 10.5007 33.3574H13.834V36.6908H17.1673V33.3574H23.834V36.6908H27.1673V33.3574H30.5007C32.339 33.3574 33.834 31.8624 33.834 30.0241V26.6908H37.1673V23.3574H33.834V16.6908H37.1673V13.3574H33.834V10.0241ZM10.5007 30.0241V10.0241H30.5007L30.504 30.0241H10.5007Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Chip
