import React from 'react'
import { colorToHex } from '../../data'

const AngleLeft = ({ color = '#004970', width = 14 }) => {
  return (
    <svg width={width} height={width * (width / 20)} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1824 2.25129V0.211528C13.1824 0.0347308 12.9765 -0.0629034 12.8374 0.0452857L0.783993 9.33635C0.681582 9.41495 0.598715 9.5156 0.541712 9.63061C0.48471 9.74562 0.455078 9.87197 0.455078 10C0.455078 10.128 0.48471 10.2544 0.541712 10.3694C0.598715 10.4844 0.681582 10.585 0.783993 10.6636L12.8374 19.9547C12.9791 20.0629 13.1824 19.9653 13.1824 19.7885V17.7487C13.1824 17.6194 13.1209 17.4954 13.0192 17.4162L3.39361 10.0013L13.0192 2.58377C13.1209 2.50461 13.1824 2.38059 13.1824 2.25129Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default AngleLeft
