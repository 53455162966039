import React from 'react'
import { colorToHex } from '../../data'

const ArrowCircle = ({ color = 'orange' }) => {
  return (
    <svg width="97" height="108" viewBox="0 0 97 108" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 101.25C55.5315 101.25 67.5497 96.2719 76.4108 87.4108C85.2719 78.5497 90.25 66.5315 90.25 54C90.25 41.4685 85.2719 29.4503 76.4108 20.5892C67.5497 11.7281 55.5315 6.75 43 6.75C30.4685 6.75 18.4503 11.7281 9.5892 20.5892C0.72811 29.4503 -4.25 41.4685 -4.25 54C-4.25 66.5315 0.72811 78.5497 9.5892 87.4108C18.4503 96.2719 30.4685 101.25 43 101.25ZM43 108C57.3217 108 71.0568 102.311 81.1838 92.1838C91.3107 82.0568 97 68.3217 97 54C97 39.6783 91.3107 25.9432 81.1838 15.8162C71.0568 5.68927 57.3217 0 43 0C28.6783 0 14.9432 5.68927 4.81623 15.8162C-5.31073 25.9432 -11 39.6783 -11 54C-11 68.3217 -5.31073 82.0568 4.81623 92.1838C14.9432 102.311 28.6783 108 43 108Z"
        fill={colorToHex(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3955 76.6394C45.7098 76.3259 45.9592 75.9534 46.1293 75.5434C46.2995 75.1334 46.3871 74.6938 46.3871 74.2499C46.3871 73.8059 46.2995 73.3664 46.1293 72.9563C45.9592 72.5463 45.7098 72.1739 45.3955 71.8604L27.5283 53.9999L45.3955 36.1394C45.7093 35.8256 45.9582 35.4531 46.1281 35.0431C46.2979 34.6331 46.3853 34.1936 46.3853 33.7499C46.3853 33.3061 46.2979 32.8667 46.1281 32.4567C45.9582 32.0467 45.7093 31.6742 45.3955 31.3604C45.0817 31.0466 44.7092 30.7977 44.2992 30.6278C43.8892 30.458 43.4498 30.3706 43.006 30.3706C42.5623 30.3706 42.1228 30.458 41.7128 30.6278C41.3028 30.7977 40.9303 31.0466 40.6165 31.3604L20.3665 51.6104C20.0522 51.9239 19.8029 52.2963 19.6327 52.7063C19.4626 53.1164 19.375 53.5559 19.375 53.9999C19.375 54.4438 19.4626 54.8834 19.6327 55.2934C19.8029 55.7034 20.0522 56.0759 20.3665 56.3894L40.6165 76.6394C40.93 76.9537 41.3025 77.203 41.7125 77.3732C42.1225 77.5433 42.5621 77.6309 43.006 77.6309C43.45 77.6309 43.8895 77.5433 44.2996 77.3732C44.7096 77.203 45.082 76.9537 45.3955 76.6394Z"
        fill={colorToHex(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.625 54C66.625 53.1049 66.2694 52.2465 65.6365 51.6135C65.0036 50.9806 64.1451 50.625 63.25 50.625H29.5C28.6049 50.625 27.7465 50.9806 27.1135 51.6135C26.4806 52.2465 26.125 53.1049 26.125 54C26.125 54.8951 26.4806 55.7536 27.1135 56.3865C27.7465 57.0194 28.6049 57.375 29.5 57.375H63.25C64.1451 57.375 65.0036 57.0194 65.6365 56.3865C66.2694 55.7536 66.625 54.8951 66.625 54Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default ArrowCircle
