import React, { useState, useEffect } from 'react'
import './DotsGrill.scss'

const DotsGrill = ({ number = 25, color = 'orange' }) => {
  const [num, setNum] = useState([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    const arr = Array.from(Array(number).keys())
    setNum(arr)
  }, [number])

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setShow(true)
        } else {
          setShow(false)
        }
      },
      { threshold: [0.8] }
    )
    observer.observe(document.getElementById('who-can-help'))
  }, [])

  return (
    <div className={`DotsGrill ${color} ${show ? 'show' : 'hide'}`} style={{ width: number * 5 + 'px' }}>
      {num.map((n) => (
        <div key={n} className={`dot dot-${n}`} style={{ margin: number / 4 + 'px', animationDelay: n * 0.02 + 's' }} />
      ))}
    </div>
  )
}

export default DotsGrill
