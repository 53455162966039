import React from 'react'
import { colorToHex } from '../../data'

const Blocks = ({ color = 'orange' }) => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.5031 15.7661L23.9889 12.7675V4.17592C23.986 3.99567 23.9312 3.82008 23.8312 3.67008C23.7312 3.52009 23.5902 3.40201 23.4249 3.32992L16.375 0.0869271C16.2512 0.0296615 16.1165 0 15.9802 0C15.8438 0 15.7091 0.0296615 15.5854 0.0869271L8.53537 3.32992C8.37221 3.40543 8.23412 3.52615 8.13749 3.67777C8.04085 3.82938 7.98973 4.00553 7.99017 4.18532V12.7769L1.50419 15.7661C1.34102 15.8416 1.20293 15.9623 1.1063 16.1139C1.00967 16.2656 0.958542 16.4417 0.958987 16.6215V25.8147C0.958542 25.9945 1.00967 26.1706 1.1063 26.3222C1.20293 26.4738 1.34102 26.5946 1.50419 26.6701L8.55417 29.9131C8.67791 29.9703 8.81263 30 8.94897 30C9.08532 30 9.22003 29.9703 9.34377 29.9131L15.999 26.8487L22.6541 29.9131C22.7779 29.9703 22.9126 30 23.0489 30C23.1853 30 23.32 29.9703 23.4437 29.9131L30.4937 26.6701C30.6569 26.5946 30.795 26.4738 30.8916 26.3222C30.9883 26.1706 31.0394 25.9945 31.0389 25.8147V16.6215C31.0402 16.4427 30.9905 16.2673 30.8956 16.1158C30.8007 15.9643 30.6645 15.843 30.5031 15.7661ZM27.8617 16.6215L23.0583 18.8117L18.255 16.6215L23.0583 14.4125L27.8617 16.6215ZM15.999 1.96692L20.8024 4.17592L15.999 6.38492L11.205 4.17592L15.999 1.96692ZM8.94897 14.4031L13.7524 16.6121L8.94897 18.8117L4.14558 16.6215L8.94897 14.4031ZM15.059 25.2037L8.94897 28.0237L2.83898 25.2037V18.0879L8.55417 20.6917C8.67536 20.7462 8.80671 20.7743 8.93957 20.7743C9.07244 20.7743 9.20379 20.7462 9.32497 20.6917L15.0402 18.0597L15.059 25.2037ZM9.88897 12.7675V5.65172L15.6042 8.28371C15.7279 8.34098 15.8626 8.37064 15.999 8.37064C16.1353 8.37064 16.27 8.34098 16.3938 8.28371L22.1089 5.65172V12.7769L15.999 15.5969L9.88897 12.7675ZM29.1683 25.2131L23.0583 28.0331L16.939 25.2131V18.0785L22.6635 20.6917C22.7834 20.7448 22.9131 20.7722 23.0442 20.7722C23.1754 20.7722 23.3051 20.7448 23.4249 20.6917L29.1401 18.0597L29.1683 25.2131Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Blocks
