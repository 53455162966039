import React from 'react'
import { colorToHex } from '../../data'

const Briefcase = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 33)} viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.0714 6.47796H29.0714V2.19224L27.6429 0.763672H13.3571L11.9286 2.19224V6.47796H1.92857L0.5 7.90653V30.7637L1.92857 32.1922H39.0714L40.5 30.7637V7.90653L39.0714 6.47796ZM14.7857 3.62081H26.2143V6.47796H14.7857V3.62081ZM37.6429 9.3351V11.5065L26.2143 17.9065V16.478L24.8143 15.0494H16.2429L14.7857 16.478V17.9065L3.35714 11.3637V9.3351H37.6429ZM23.3571 17.9065V20.7637H17.6429V17.9065H23.3571ZM3.35714 29.3351V14.6494L14.7857 21.1922V22.1922L16.2143 23.6208H24.7857L26.2143 22.1922V21.3065L37.6429 14.7922V29.3351H3.35714Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Briefcase
