import React from 'react'
import './ArrowsDown.scss'
import AngleLeft from '../Icons/AngleLeft'

const ArrowsDown = ({ color = 'purple' }) => {
  const arrows = Array.from(Array(3).keys())
  return (
    <div className={`ArrowsDown ${color}`}>
      {arrows?.map((a, i) => (
        <div
          key={`arrow-${a}-${i}`}
          className="arrow"
          style={{
            animationDelay: i * 0.2 + 's'
          }}
        >
          <AngleLeft color={color} />
        </div>
      ))}
    </div>
  )
}

export default ArrowsDown
