import React from 'react'
import { colorToHex } from '../../data'

const Birrete = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5004 9.75L20.1091 9.86875L4.48414 15.1037L0.931641 16.275L3.00039 16.9375V27.875C2.25414 28.3087 1.75039 29.1 1.75039 30.025C1.75039 30.688 2.01378 31.3239 2.48262 31.7928C2.95146 32.2616 3.58735 32.525 4.25039 32.525C4.91343 32.525 5.54932 32.2616 6.01816 31.7928C6.487 31.3239 6.75039 30.688 6.75039 30.025C6.75039 29.1 6.24664 28.3087 5.50039 27.875V17.8L8.00039 18.6187V25.025C8.00039 26.05 8.62539 26.9 9.36789 27.4875C10.1104 28.0712 11.0329 28.4837 12.1404 28.8537C14.3579 29.5912 17.2804 30.025 20.5004 30.025C23.7204 30.025 26.6429 29.5925 28.8604 28.8525C29.9679 28.4837 30.8904 28.0712 31.6329 27.4862C32.3754 26.9 33.0004 26.05 33.0004 25.025V18.6187L36.5166 17.4462L40.0691 16.275L36.5154 15.1025L20.8904 9.86875L20.5004 9.75ZM20.5004 12.3687L32.2191 16.275L20.5004 20.1812L8.78164 16.275L20.5004 12.3687ZM10.5004 19.4787L20.1104 22.6812L20.5004 22.7987L20.8916 22.68L30.5004 19.4775V25.025C30.5004 25.0375 30.5054 25.1825 30.1091 25.4937C29.7141 25.8062 29.0054 26.2 28.0779 26.51C26.2254 27.1262 23.4941 27.525 20.5004 27.525C17.5066 27.525 14.7754 27.1275 12.9216 26.5087C11.9966 26.2 11.2866 25.805 10.8916 25.4937C10.4941 25.1812 10.5004 25.0375 10.5004 25.025V19.4775V19.4787Z"
        fill={colorToHex(color)}
      />
      {/* <path d="M20.4994 0.725586L20.1082 0.844336L4.48316 6.07933L0.930664 7.25059L2.99941 7.91308V18.8506C2.25316 19.2843 1.74941 20.0756 1.74941 21.0006C1.74941 21.6636 2.01281 22.2995 2.48165 22.7683C2.95049 23.2372 3.58637 23.5006 4.24941 23.5006C4.91246 23.5006 5.54834 23.2372 6.01718 22.7683C6.48602 22.2995 6.74941 21.6636 6.74941 21.0006C6.74941 20.0756 6.24566 19.2843 5.49941 18.8506V8.77559L7.99941 9.59433V16.0006C7.99941 17.0256 8.62441 17.8756 9.36691 18.4631C10.1094 19.0468 11.0319 19.4593 12.1394 19.8293C14.3569 20.5668 17.2794 21.0006 20.4994 21.0006C23.7194 21.0006 26.6419 20.5681 28.8594 19.8281C29.9669 19.4593 30.8894 19.0468 31.6319 18.4618C32.3744 17.8756 32.9994 17.0256 32.9994 16.0006V9.59433L36.5157 8.42184L40.0682 7.25059L36.5144 6.07809L20.8894 0.844336L20.4994 0.725586ZM20.4994 3.34434L32.2182 7.25059L20.4994 11.1568L8.78066 7.25059L20.4994 3.34434ZM10.4994 10.4543L20.1094 13.6568L20.4994 13.7743L20.8907 13.6556L30.4994 10.4531V16.0006C30.4994 16.0131 30.5044 16.1581 30.1082 16.4693C29.7132 16.7818 29.0044 17.1756 28.0769 17.4856C26.2244 18.1018 23.4932 18.5006 20.4994 18.5006C17.5057 18.5006 14.7744 18.1031 12.9207 17.4843C11.9957 17.1756 11.2857 16.7806 10.8907 16.4693C10.4932 16.1568 10.4994 16.0131 10.4994 16.0006V10.4531V10.4543Z" fill={colorToHex(color)} /> */}
    </svg>
  )
}

export default Birrete
