import React from 'react'
import { colorToHex } from '../../data'

const House = ({ color = 'yellow', height = 55, width = 47 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 55 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.598 0.389293C28.2802 0.137156 27.887 0 27.482 0C27.077 0 26.6838 0.137156 26.366 0.389293L0.5 20.6493L2.732 23.5049L5.9 21.0108V43.3854C5.9 44.344 6.27928 45.2634 6.95442 45.9413C7.62955 46.6192 8.54522 47 9.5 47H45.5C46.4548 47 47.3705 46.6192 48.0456 45.9413C48.7207 45.2634 49.1 44.344 49.1 43.3854V21.0289L52.268 23.5049L54.5 20.6674L28.598 0.389293ZM31.1 43.3854H23.9V28.9268H31.1V43.3854ZM34.7 43.3854V28.9268C34.7 27.9682 34.3207 27.0488 33.6456 26.3709C32.9705 25.693 32.0548 25.3122 31.1 25.3122H23.9C22.9452 25.3122 22.0295 25.693 21.3544 26.3709C20.6793 27.0488 20.3 27.9682 20.3 28.9268V43.3854H9.5V18.1914L27.5 4.11237L45.5 18.2094V43.3854H34.7Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default House
