import './Modal.scss'

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'
import { animated, useSpring, useTransition } from 'react-spring'

const Modal = ({ children, open }) => {
  const modal = useRef(null)
  const [spring, set] = useSpring(() => ({ x: 0 }))
  const transition = useTransition(open, null, {
    from: { transform: 'translateX(100vw)', opacity: 0 },
    enter: { transform: 'translateX(0)', opacity: 1 },
    leave: { transform: 'translateX(100vw)', opacity: 0 }
  })

  useEffect(() => {
    if (open) disableBodyScroll()
    else clearAllBodyScrollLocks()
    return () => {
      clearAllBodyScrollLocks()
      set({ x: 0 })
    }
  }, [open, set])

  return (
    <>
      {transition.map(
        ({ item, props }, i) =>
          item && (
            // <animated.div {...bind()} ref={modal} key={`modal-selected-${i}`} className='Modal' style={{ ...props, transform: spring.x.interpolate((x) => `translateX(${x}px)`) }}>
            <animated.div
              ref={modal}
              key={`modal-selected-${i}`}
              className="Modal"
              style={{
                ...props,
                transform: spring.x.interpolate((x) => `translateX(${x}px)`)
              }}
            >
              {children}
            </animated.div>
          )
      )}
    </>
  )
}

export default Modal
