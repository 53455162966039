import React from 'react'
import { colorToHex } from '../../data'

const LocationPin = ({ color = 'orange' }) => {
  return (
    <svg width="29" height="41" viewBox="0 0 29 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M26.5 13.3C26.5 11.5534 26.1637 9.82394 25.5104 8.21031C24.8571 6.59668 23.8995 5.1305 22.6924 3.89548C21.4852 2.66046 20.0521 1.68079 18.4749 1.0124C16.8977 0.344015 15.2072 0 13.5 0C11.7928 0 10.1023 0.344015 8.52511 1.0124C6.94788 1.68079 5.51477 2.66046 4.30761 3.89548C3.10045 5.1305 2.14288 6.59668 1.48957 8.21031C0.836255 9.82394 0.5 11.5534 0.5 13.3C0.5 15.9353 1.26143 18.3863 2.55214 20.4535H2.53729C6.92014 27.474 13.5 38 13.5 38L24.4627 20.4535H24.4497C25.7876 18.3186 26.4992 15.836 26.5 13.3ZM13.5 19C12.0224 19 10.6052 18.3995 9.5604 17.3305C8.51556 16.2616 7.92857 14.8117 7.92857 13.3C7.92857 11.7883 8.51556 10.3384 9.5604 9.26949C10.6052 8.20053 12.0224 7.6 13.5 7.6C14.9776 7.6 16.3947 8.20053 17.4396 9.26949C18.4844 10.3384 19.0714 11.7883 19.0714 13.3C19.0714 14.8117 18.4844 16.2616 17.4396 17.3305C16.3947 18.3995 14.9776 19 13.5 19Z"
          fill={colorToHex(color)}
        />
      </g>
      <defs>
        <filter id="filter0_d" x="0.5" y="0" width="28" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="2" dy="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.796078 0 0 0 0 0.129412 0 0 0 0 0.172549 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default LocationPin
