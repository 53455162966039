import './Resources.scss'

import * as lottie_informatica from '../../lotties/Resources/informatica.json'
import * as lottie_intercozy from '../../lotties/Resources/intercozy.json'
import * as lottie_languages from '../../lotties/Resources/languages.json'
import * as lottie_library from '../../lotties/Resources/library.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import Briefcase from '../../components/Icons/Briefcase'
// import Computer from '../../components/Icons/Computer'
import Lottie from 'react-lottie'
import VisibilitySensor from 'react-visibility-sensor'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import Diplom from '../../components/Icons/Diplom'
import Earth from '../../components/Icons/Earth'
import File from '../../components/Icons/File'
import Football from '../../components/Icons/Football'
import Microscope from '../../components/Icons/Microscope'
import Plane from '../../components/Icons/Plane'
import RobotArm from '../../components/Icons/RobotArm'
import Santander from '../../components/Icons/Santander'
import SparkleLight from '../../components/Icons/SparkleLight'
import Star from '../../components/Icons/Star'
import Kites from '../../components/Kites/Kites'
import ListItem from '../../components/ListItem/ListItem'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const Resources = ({
  color = 'red',
  secondary = 'light-blue',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties
  const lottieLibrary = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_library.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieLanguages = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_languages.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieIntercozy = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_intercozy.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieInformatica = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_informatica.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const socioeconomic = (
    <div className="desplegable-container socioeconomic">
      <p className="text-normal">
        La convocatoria de ayudas socioeconómicas está en <b>constante revisión y ampliación</b> para adaptarse a las circunstancias del momento, por lo que{' '}
        <b>es importante que la consultes con frecuencia</b>:
      </p>

      <VisibilitySensor partialVisibility minTopValue={50}>
        {({ isVisible }) => (
          <div className={`animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <div className="romb-container">
              <div className="numbered-romb">
                <img src="/svg/front-pages/resources/numbered-rombs/first.svg" alt="" />
              </div>
            </div>
            <div className={`box ${secondary} fat`}>
              <p className="text-semi-normal bold">Ayudas con cargo al Fondo Social de estudiantes UAM</p>
            </div>
            <p className="text-normal text-subnormal">
              Estas ayudas están orientadas a <b>cubrir los costes de matrícula</b> de estudiantes de títulos oficiales en situaciones desfavorables
            </p>
          </div>
        )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility minTopValue={50}>
        {({ isVisible }) => (
          <div className={`animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <div className="romb-container">
              <div className="numbered-romb">
                <img src="/svg/front-pages/resources/numbered-rombs/second.svg" alt="" />
              </div>
            </div>
            <div className={`box ${secondary} fat`}>
              <p className="text-semi-normal bold">Ayudas para atención psicológica en el Centro de Psicología Aplicada de la UAM</p>
            </div>
            <p className="text-normal text-subnormal">
              Para estudiantes que <b>no pueden acceder al servicio</b> por motivos económicos.
            </p>
          </div>
        )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility minTopValue={50}>
        {({ isVisible }) => (
          <div className={`animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <div className="romb-container">
              <div className="numbered-romb">
                <img src="/svg/front-pages/resources/numbered-rombs/third.svg" alt="" />
              </div>
            </div>
            <div className={`box ${secondary} fat`}>
              <p className="text-semi-normal bold">Ayudas de estudios a estudiantes con estatus de refugio y/o solicitantes de asilo</p>
            </div>
            <p className="text-normal text-subnormal">
              Para estudiantes matriculados/as en la UAM que estando en posesión del estatus de <b>refugiado o de petición de asilo, necesiten de apoyo</b> para
              la continuidad o inicio de estudios.
            </p>
          </div>
        )}
      </VisibilitySensor>

      <VisibilitySensor partialVisibility minTopValue={50}>
        {({ isVisible }) => (
          <div className={`animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <div className="romb-container">
              <div className="numbered-romb">
                <img src="/svg/front-pages/resources/numbered-rombs/forth.svg" alt="" />
              </div>
            </div>
            <div className={`box ${secondary} fat`}>
              <p className="text-semi-normal bold">Préstamo de material informático</p>
            </div>
            <p className="text-normal text-subnormal">
              Esta convocatoria de ayudas está orientada a <b>facilitar los recursos informáticos y de conexión a internet</b> que los estudiantes puedan
              necesitar para mantener el desempeño académico adecuado cuando <b>no disponen de medios propios por causas económicas.</b>
            </p>
          </div>
        )}
      </VisibilitySensor>
    </div>
  )

  const grade = (
    <div className="desplegable-container grade questions-container-list">
      <div className="youth-container">
        <div className={`youth-block ${color}`}>
          <div className="icon-container">
            <Star />
          </div>
          <ListItem color={secondary}>
            <h5 className="text-semi-normal heroic italic uppercase">Premios UAM Talento Joven</h5>
            <p className="text-normal text-subnormal">
              Supone la <b>exención de las tasas de matrícula</b>. Este programa cuenta con diferentes convocatorias orientadas a premiar tanto el rendimiento
              como el esfuerzo personal en situaciones adversas al estudio.
            </p>
          </ListItem>
        </div>
      </div>
      <div className={`separator-programs ${color}`} />
      <div className={`enterpreneur-container ${color}`}>
        <div className={`enterpreneur-block ${color}`}>
          <div className="icon-container">
            <SparkleLight />
          </div>
          <ListItem color={secondary}>
            <h5 className="text-semi-normal heroic italic uppercase">Programa UAM Talento Emprendedor</h5>
            <p className="text-normal text-subnormal">
              La UAM también concede premios para apoyar a los nuevos estudiantes que presenten <b>proyectos innovadores y de alto valor social</b>. Si obtienes
              uno de los premios te asignaremos un <b>mentor profesional</b> para desarrollar tu proyecto y tendrás acceso gratuito al{' '}
              <b>Curso de Emprendimiento Digital UAM Emprende</b>. Puedes consultar toda la información en{' '}
            </p>
          </ListItem>
        </div>

        <ExternalLink secondary={color} color={secondary} url="https://uamemprende.es/" text="https://uamemprende.es/" />

        <AddressBookComp color={color} data={contacts['uam-emprende']} />
      </div>
    </div>
  )

  const internalization = (
    <div className="desplegable-container internalization new-system-list questions-container-list">
      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Plane />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Ayudas para movilidad</h5>
          <p className="text-normal text-subnormal">
            Para realizar estudios en instituciones <b>fuera de la UE</b> con las que la UAM tenga convenio.
          </p>
        </ListItem>
      </div>

      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Santander />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Becas del Programa UAM-Santander del Centro de Estudios de América Latina (CEAL)</h5>
          <p className="text-normal text-subnormal">
            Para realizar estudios en instituciones de <b>América Latina</b> que forman parte de este programa.
          </p>
        </ListItem>
      </div>

      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Earth />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Ayudas para actividades de internacionalización</h5>
          <p className="text-normal text-subnormal">
            Para estudiantes que realicen actividades que contribuyan a la <b>internacionalización</b> de la UAM.
          </p>
        </ListItem>
      </div>
    </div>
  )

  const other = (
    <div className="desplegable-container internalization new-system-list questions-container-list">
      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Microscope color={color} />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Ayudas económicas</h5>
          <p className="text-normal text-subnormal">
            Para actividades del <b>estudiantado</b>
          </p>
        </ListItem>
      </div>

      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Briefcase color={color} />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Ayudas económicas</h5>
          <p className="text-normal text-subnormal">
            Para la realización de <b>prácticas externas curriculares</b> en Departamentos, Servicios o Unidades de la UAM
          </p>
        </ListItem>
      </div>

      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <RobotArm color={color} />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Premios</h5>
          <p className="text-normal text-subnormal">
            Premios a los mejores <b>trabajos de fin de Máster y Grado</b> basados en el Portal de Datos Abiertos de la UAM
          </p>
        </ListItem>
      </div>

      <div className={`item-block ${color}`}>
        <div className="icon-container">
          <Football color={color} />
        </div>
        <ListItem color={secondary}>
          <h5 className="text-semi-normal heroic italic uppercase">Convocatoria de ayudas</h5>
          <p className="text-normal text-subnormal">
            Al estudiantado <b>deportista</b> de la UAM
          </p>
        </ListItem>
      </div>
    </div>
  )

  const lendings = (
    <div className="desplegable-container lendings">
      <p className="text-normal">
        La Biblioteca cuenta con <b>máquinas de autopréstamo</b> y buzones de <b>devolución de libros</b>, podrás encontrarlos en el vestíbulo de las Facultades
        o de la Escuela Politécnica Superior.
      </p>
      <p className="text-normal">
        Los libros que tengas en préstamo se <b>renovarán automáticamente hasta 5 veces</b>, siempre y cuando no los solicite otra persona. Si compruebas que
        necesitas un libro que está prestado, Accede a nuestro catálogo a través de la página web de la biblioteca:
      </p>

      <ExternalLink url={'https://www.uam.es/uam/vida-universitaria/bibliotecas'} color={secondary} secondary={color} />

      <p className="text-normal">
        En nuestro catálogo <b>Bun!</b> Buscador único podrás realizar <b>reservas</b> de libros, acceder al <b>contenido electrónico</b>, consultar la
        <b>bibliografía recomendada</b> de una asignatura, etc.
      </p>
      <p className="text-normal">A través de la web de la biblioteca puedes reservar libros o renovar los préstamos:</p>

      <ExternalLink url="https://www.uam.es/uam/vida-universitaria/bibliotecas" color={secondary} secondary={color} text="¡Visita nuestra web!" />

      <p className="text-normal">
        Recuerda consultar <b>posibles cambios</b> en cuanto a horarios y accesos en periodos de exámenes.
      </p>
    </div>
  )

  const opening_times = (
    <div className="desplegable-container opening_times">
      <p className="text-normal">
        El horario habitual de las bibliotecas UAM es de <b>9 a 20,30 horas</b>, salvo la de <b>Medicina, que abre de 8 a 20 horas</b>.
      </p>
    </div>
  )

  const workRooms = (
    <div className="desplegable-container work-rooms">
      <p className="text-normal">
        Son espacios adaptados para el estudio, la investigación y el <b>trabajo en equipo</b> con acceso a herramientas tecnológicas. Tienes más información
        en:
      </p>
      <ExternalLink color={secondary} secondary={color} url="https://biblioguias.uam.es/salas_trabajo/inicio" text="Más info aquí" />
      <p className="text-normal">
        Puedes ver las salas de trabajo disponibles en cada biblioteca y reservarlas en la <a href="https://biblioagenda.uam.es/">Biblioagenda</a>, donde además
        encontrarás <b>eventos</b> y <b>cursos de formación</b> que ofrece la Biblioteca.
      </p>
    </div>
  )

  const owl = (
    <div className="desplegable-container owl">
      <p className="text-normal">
        En la Biblioteca de Ciencias hay una sala de estudio que está disponible durante gran parte del curso, especialmente en periodos de exámenes, con
        horarios extendidos <b>(hasta la 1:00 y fines de semana)</b>.
      </p>
      <ExternalLink color={secondary} secondary={color} url="https://biblioguias.uam.es/sala_buho/inicio" text="Consulta los horarios" />
    </div>
  )

  const matricula = (
    <div className="desplegable-container matricula">
      <div className="center-icon">
        <File width={40} />
      </div>
      <p className="text-normal">
        <b>Para participar en los cursos</b> de alemán, francés, inglés, italiano, chino, portugués y español para extranjeros{' '}
        <b>deberás realizar una prueba de nivel</b> y después proceder a <b>automatricularte</b> siguiendo las instrucciones de la página web. Presta{' '}
        <b>atención a los plazos</b>, son cursos con mucha demanda.
      </p>
    </div>
  )

  const titles = (
    <div className="desplegable-container titles">
      <div className="center-icon">
        <Diplom />
      </div>
      <p className="text-normal">
        Por otro lado, el Servicio de Idiomas también realiza <b>exámenes oficiales</b> para la obtención de títulos. Podrás acreditar los niveles B1 y B2 para
        inglés e italiano y C1 para inglés, de acuerdo a las directrices del Marco Común Europeo de Referencia para las Lenguas a través de la certificación
        CertAcles. Además, a través del propio Servicio, podrás examinarte del nivel B1 del MCERL en alemán, B1 y B2 en francés y B1 en italiano. Si por el
        contrario lo que te interesa es acreditar el dominio de la lengua española, el Servicio de Idiomas pone a tu disposición la realización del examen
        SIELE.
      </p>
      <p className="text-normal">
        ¡Y esto no es todo! Con el objetivo de que puedas familiarizarte con el modelo de evaluación, podrás apuntarte a los <b>talleres</b> breves de{' '}
        <b>estrategias para la realización de</b> algunos de los <b>exámenes de certificación.</b> Sin duda te serán de gran ayuda para superar con éxito la
        prueba.
      </p>
    </div>
  )

  // const platform = (
  //   <div className="desplegable-container platform">
  //     <div className="center-icon">
  //       <Computer />
  //     </div>
  //     <p className="text-normal">
  //       Si tienes poco tiempo para clases presenciales o no dispones de los recursos necesarios, la UAM pone a tu disposición otra alternativa con la que
  //       aprender siete idiomas diferentes, de forma simultánea y compatibles entre sí, totalmente gratis: la <b>Plataforma Online de Idiomas.</b> Esta te
  //       proporcionará las habilidades y herramientas necesarias para demostrar tu dominio de la <b>oralidad</b>, la <b>escritura</b>, el <b>vocabulario</b> y la{' '}
  //       <b>gramática</b>.
  //     </p>
  //     <p className="text-normal">
  //       Es muy sencillo; <b>diseña tu propio programa</b> de aprendizaje, <b>realiza un test inicial</b> para conocer tu nivel y después <b>tests mensuales</b>{' '}
  //       para comprobar tu progresión. No te preocupes, si lo necesitas, podrás ajustarlo las veces que consideres necesarias ;)
  //     </p>
  //     <p className="text-normal">
  //       Además, la Plataforma cuenta con un sistema de <b>reconocimiento de voz</b> que sin duda será de gran ayuda en el proceso de aprendizaje, ¡tan solo
  //       necesitarás un ordenador con micrófono y tus propios auriculares! Podrás <b>identificar los fallos en la pronunciación</b> y practicar hasta{' '}
  //       <b>conseguir el nivel adecuado.</b>
  //     </p>
  //     <p className="text-normal">Puedes acceder a la plataforma a través de:</p>

  //     <ExternalLink url="https://idiomas.uam.es/" color={secondary} secondary={color} />

  //     <p className="text-normal">¡Aprovecha la oportunidad y apúntate pronto, las plazas vuelan!</p>
  //   </div>
  // )

  const interchange = (
    <div className="desplegable-container interchange">
      <p className="text-normal">
        La UAM tiene un elevado número de <b>acuerdos internacionales</b> con las mejores universidades del mundo para la movilidad académica de los
        estudiantes. Vive una experiencia que te enriquecerá a nivel académico y personal, adquiriendo nuevas capacidades y habilidades que te permitirían a ser
        más competitivo de cara a tu futuro laboral, mejorando tu currículum.
      </p>

      <Kites />

      <p className="text-normal">
        Los programas de movilidad te permitirán realizar una <b>estancia de estudios</b> en instituciones de educación superior de <b>España</b> o de un{' '}
        <b>gran número de países del mundo</b>, matricularte en universidades con las que la UAM tiene firmados convenios y que los estudios que realices allí
        te sean reconocidos a tu regreso a la Autónoma. Gracias a estos programas de intercambio, podrás internacionalizar tus estudios durante{' '}
        <b>un año académico o un cuatrimestre</b> participando en el Programa <b>Erasmus+</b>, el Programa de Movilidad de Convenios Internacionales, el
        Programa <b>CEAL</b>, el <b>SEMP</b> u otros. También puedes realizar una movilidad en España con el Programa <b>SICUE</b> (Sistema de Intercambio entre
        Centros Universitarios Españoles).
      </p>

      <DidYouKnow color={secondary}>
        <p className="text-normal">
          A lo largo de tus estudios podrás realizar <b>más de una movilidad.</b>
        </p>
      </DidYouKnow>

      <p className="text-normal">
        Otra posibilidad será la de cursar una <b>Doble o Múltiple Titulación Internacional</b> por la que, además del título que curses en la UAM, podrás
        obtener otro título de una universidad extranjera.
      </p>
      <p className="text-normal">
        También puedes cursar un <b>Título Conjunto Internacional</b> como los programas <b>Erasmus Mundus.</b>
      </p>
      <p className="text-normal">
        Y, por supuesto, no olvides la posibilidad de participar en alguna modalidad de estudios en alguna de nuestras universidades de la Red <b>CIVIS</b>,
        cursos de corta duración, <b>CIVIS Students Info Days o movilidad virtual CIVIS.</b>
      </p>

      <DidYouKnow color={secondary}>
        <p className="text-normal">
          La UAM forma parte de <b>CIVIS</b>, la iniciativa europea que une a once universidades líderes del continente para formar un{' '}
          <b>espacio común de educación superior</b> en el que los miembros de la comunidad universitaria podrán desarrollar sus estudios y carreras, generar
          colaboraciones y beneficiarse de recursos compartidos como si estuviesen en su propia universidad. Si quieres conocer más detalles sobre el proyecto,
          visita{' '}
          <a href="https://civis.uam.es/" target="_blank" rel="noopener noreferrer" className="bold">
            civis.uam.es
          </a>
        </p>
      </DidYouKnow>

      <p className="text-normal">
        Por otra parte, a través del Programa de Prácticas internacional, podrás realizar <b>prácticas en empresas o instituciones internacionales</b> y
        reconocer los créditos correspondientes o como <b>prácticas extracurriculares.</b>
      </p>
    </div>
  )

  const requirements = (
    <div className="desplegable-container requirements">
      <p className="text-normal">
        Para poder participar en estos programas de movilidad de estudios o prácticas necesitas haber <b>superado 60 ECTS</b> y contar con un nivel de{' '}
        <b>conocimiento de idiomas adecuado</b>, acreditado con algún <b>certificado oficial.</b> Consulta las opciones que ofrece nuestro Servicio de Idiomas.
      </p>
      <p className="text-normal">
        Además, contamos con varios programas de <b>becas</b> o ayudas financieras para ayudarte a <b>cubrir la totalidad o parte de los gastos</b> de estas
        estancias.
      </p>
      <p className="text-normal">
        Pásate por el Servicio de Relaciones Internacionales y Movilidad (la oficina central está en el edificio Plaza Mayor) o por la Oficina de Relaciones
        Internacionales de tu centro y te informarán de los requisitos y las fechas en que se puede formalizar tu solicitud.
      </p>
      <p className="text-normal">Más información:</p>

      <ExternalLink url="https://www.uam.es/uam/internacional/movilidad-estudiantes" color={secondary} secondary={color} text="Más información" />
    </div>
  )

  const intercozy = (
    <div className="desplegable-container intercozy">
      <p className="text-normal">
        Si eres estudiante internacional y necesitas <b>ayuda</b> con los <b>trámites de visados, permisos de residencia y trabajo</b> o si quieres que se te
        asigne un Buddy (estudiante local que te ayude con tu incorporación a la UAM y a Madrid) pásate por la Oficina de Acogida Internacional (OAI) que se
        encuentra en el Servicio de Relaciones Internacionales (SERIM) en la Plaza Mayor del campus de Cantoblanco.
      </p>
      {lottieIntercozy}
    </div>
  )

  const contest = (
    <div className="desplegable-container contest">
      <p className="text-normal">
        Para apoyar la campaña de encuestas, cada año se organiza un <b>concurso de carteles</b> abierto a toda la comunidad.{' '}
        <b>¿Te atreves a presentar una idea?</b>
      </p>
      <p className="text-normal">
        Diseña un cartel para promover la participación en las encuestas de actividad docente del <b>curso 2024-2025.</b>
      </p>
      <p className="text-normal">
        Puedes participar si eres <b>estudiante, AlumniUAM, PDI, PDIF, o PTGAS de la UAM</b>, sus centros adscritos o de la FUAM. Consigue un{' '}
        <b>premio de 900€</b> o uno de los <b>8 premios de 200€</b> que se reparten entre los participantes. <b>A finales de septiembre</b> suelen publicarse en
        las redes sociales UAM las <b>bases del concurso.</b> Tendrás varias semanas para elaborar una propuesta y los carteles ganadores se conocerán poco
        tiempo después.
      </p>
      <p className={`colored ${secondary} uppercase urbana text-medium`}>Be the next winner!</p>
      <div className="contest-container">
        <img src="/svg/front-pages/who-can-help/cartel_encuestas_2025.png" alt="Cartel de encuestas 2025" />
        <ExternalLink
          text={'Más info sobre encuestas de evaluación docente'}
          url={'https://www.uam.es/uam/en/uce/encuestas-actividad-docente'}
          color={secondary}
          secondary={color}
        />
      </div>
    </div>
  )

  // Modals area
  const helps = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Becas, ayudas y premios</h3>
          <div className="actual-text">
            <p className="text-normal">Durante tus estudios puedes beneficiarte de ayudas, becas y premios de muy diversos tipos.</p>
            <p className="text-normal">
              Además de las <b>becas y subvenciones generales</b> (por ejemplo, las becas del Ministerio de Educación, las becas de colaboración, las becas
              autonómicas, los premios nacionales para estudiantes universitarios, los programas ERASMUS, SEMP, Becas Santander Progreso, etc.), la UAM tiene un{' '}
              <b>programa propio de becas, ayudas y premios.</b>
            </p>

            <div className="questions-container-list">
              <Desplegable forceHiding secondary={secondary} color={color} title="Ayudas socioeconómicas">
                {socioeconomic}
              </Desplegable>
            </div>

            <p className="text-normal">
              <b>Además,</b> existen otras muchas ayudas propias de la UAM, entre las que destacamos:
            </p>
            <div className="questions-container-list">
              <Desplegable forceHiding secondary={secondary} color={color} title="Ayudas y premios para estudiantes de grado">
                {grade}
              </Desplegable>
              <Desplegable forceHiding secondary={secondary} color={color} title="Ayudas propias de la UAM para la internacionalización del estudiantado">
                {internalization}
              </Desplegable>
              <Desplegable forceHiding secondary={secondary} color={color} title="Otras ayudas">
                {other}
              </Desplegable>
            </div>
            <p className="text-normal">
              En el documento de cada convocatoria encontrarás los requisitos de solicitud y los plazos para hacerlo, toda la información está disponible en
            </p>
            <ExternalLink url="https://www.uam.es/uam/estudios/becas-ayudas" color={secondary} secondary={color} />
            <p className="text-normal">
              En todos los casos es muy importante que conozcas bien las <b>convocatorias, sus requisitos y plazos</b>. La Sección de Becas y Ayudas te ofrecerá
              información sobre algunas de ellas, además de ayudarte a gestionar las de otras entidades (becas generales MEC y las mencionadas al inicio de esta
              página).
            </p>
            <p className="text-normal">
              Ante cualquier otra duda relacionada con ayudas que no sean gestionadas por la Sección, en <b>UAM Estudiantes</b> podremos darte la información
              necesaria.
            </p>
            <AddressBookComp color={color} data={contacts['becas-y-ayudas']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const libraries = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Bibliotecas</h3>

          {lottieLibrary}

          <div className="actual-text">
            <p className="text-normal">
              Las bibliotecas de la UAM son excelentes y debes tenerlas como punto de referencia a lo largo de tus estudios: en ellas no solo encontrarás{' '}
              <b>libros y revistas</b>, sino también <b>material técnico de todo tipo</b>.
            </p>
            <p className="text-normal">
              Cada centro dispone de su <b>propia biblioteca</b> dentro del edificio, excepto las Bibliotecas de Humanidades y Ciencias, que cuentan con
              edificios propios.
            </p>
            <p className="text-normal">
              Puedes acceder a cualquiera de ellas para <b>consultar sus fondos, estudiar, tomar en préstamo</b> un ordenador portátil, <b>reservar</b> una sala
              de trabajo en grupo o apuntarse a los cursos de formación para aprender a gestionar la información y los recursos bibliográficos. Muchos de los
              servicios requieren que hagas uso del <b>carné universitario</b>, disponible en formato digital en la app UAM.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Préstamos">
                {lendings}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Horarios">
                {opening_times}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Salas de trabajo">
                {workRooms}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Sala de estudio 24 horas (búho)">
                {owl}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const languages = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Idiomas</h3>

          {lottieLanguages}

          <div className="actual-text">
            <p className="text-normal">
              ¿Sabías que la Universidad apoya la <b>formación continua</b> en idiomas como parte del crecimiento personal?
            </p>
            <p className="text-normal">
              Si te interesa desarrollar tus <b>competencias lingüísticas</b>, aprovecha tu paso por la UAM para iniciar, afianzar y acreditar tu conocimiento
              de idiomas: algunos Grados lo requieren para conseguir el título y es necesario para algunas{' '}
              <b>becas de movilidad, posgrado, plazas y oposiciones.</b> En cualquier caso, aumentará tus oportunidades para acceder al <b>mundo profesional</b>{' '}
              o continuar tu <b>formación académica.</b>
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Matrícula">
                {matricula}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Títulos">
                {titles}
              </Desplegable>
              {/* <Desplegable secondary={secondary} color={color} title="Plataforma online">
                {platform}
              </Desplegable> */}
            </div>
            <AddressBookComp color={color} data={contacts['idiomas']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const movility = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Movilidad internacional y nacional</h3>
          <div className="actual-text">
            <p className="text-normal">
              ¿Te gustaría irte de <b>Erasmus</b> por Europa u otros países asociados? ¿Quieres estudiar parte de tu carrera en una universidad de{' '}
              <b>otro continente</b>? ¿o quizá prefieras hacerlo en <b>otra universidad española</b>? ¿te interesaría hacer prácticas en el <b>extranjero</b>?
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Programas de intercambio">
                {interchange}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Requisitos">
                {requirements}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Acogida internacional">
                {intercozy}
              </Desplegable>
            </div>
            <AddressBookComp color={color} data={contacts['relaciones-internacionales']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const technology = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Aulas de informática</h3>
          {lottieInformatica}
          <div className="actual-text">
            <p className="text-normal">
              Es una <b>red de aulas</b> distribuidas por los centros a las que puedes acudir si necesitas usar un <b>ordenador</b> (recuerda que también existe
              préstamo de portátiles en las bibliotecas).
            </p>
            <p className="text-normal">
              Si necesitas <b>imprimir o escanear documentos</b>, puedes utilizar las impresoras <b>disponibles en estas aulas.</b>
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const surveys = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Encuestas de evaluación docente</h3>
          <div className="actual-text">
            <p className="text-normal">
              Al final de cada semestre se hacen <b>encuestas online para evaluar la calidad de la docencia</b> (titulación, asignaturas y profesorado). Es una
              manera muy adecuada de contribuir a la mejora continua de la experiencia UAM. #TuVozUAM es imprescindible para para seguir mejorando los planes de
              estudio.
            </p>

            <div className="your-voice">
              <img src="/svg/front-pages/who-can-help/tu-voz-uam.svg" alt="" />
            </div>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Concurso para apoyar la campaña de encuestas">
                {contest}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'helps') isDesktop ? setModal(helps) : modalHandling(helps)
    else if (modalContent === 'libraries') isDesktop ? setModal(libraries) : modalHandling(libraries)
    else if (modalContent === 'languages') isDesktop ? setModal(languages) : modalHandling(languages)
    else if (modalContent === 'movility') isDesktop ? setModal(movility) : modalHandling(movility)
    else if (modalContent === 'technology') isDesktop ? setModal(technology) : modalHandling(technology)
    else if (modalContent === 'surveys') isDesktop ? setModal(surveys) : modalHandling(surveys)
    else isDesktop && setModalContent('helps')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/Resources_logo_back.svg" alt="" />
      </div>
      <div className="logo-front">
        <img src="/splash/svg/Resources_logo_front.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/Resources_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Resources.png" className="image" alt="" />
      </animated.div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop Resources ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Aprovecha los recursos de la UAM</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li className={`uppercase bold text-normal ${modalContent === 'helps' ? 'selected' : ''}`} onClick={() => setModalContent('helps')}>
                              1. Becas, ayudas y premios{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'libraries' ? 'selected' : ''}`}
                              onClick={() => setModalContent('libraries')}
                            >
                              2. Bibliotecas{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'languages' ? 'selected' : ''}`}
                              onClick={() => setModalContent('languages')}
                            >
                              3. Idiomas{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'movility' ? 'selected' : ''}`}
                              onClick={() => setModalContent('movility')}
                            >
                              4. Movilidad internacional y nacional{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'technology' ? 'selected' : ''}`}
                              onClick={() => setModalContent('technology')}
                            >
                              5. Aulas de informática{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'surveys' ? 'selected' : ''}`}
                              onClick={() => setModalContent('surveys')}
                            >
                              6. Encuestas de evaluación docente{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}
              <div className="front-logo-container-desktop" id="resources-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`Resources main-page ${color}`} id="resources">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header" id="resources-header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="resources-logo">
                {Splash}
              </div>

              <div className="footer" id="resources-footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Aprovecha los recursos de la UAM</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="resources-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Aprovecha los recursos de la UAM</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('helps')}>
                      <span>1. Becas, ayudas y premios</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('libraries')}>
                      <span>2. Bibliotecas</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('languages')}>
                      <span>3. Idiomas</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('movility')}>
                      <span>4. Movilidad internacional y nacional</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('technology')}>
                      <span>5. Aulas de informática</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('surveys')}>
                      <span>6. Encuestas de evaluación docente</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Resources
