import './WhoCanHelp.scss'

import * as lottie_balance from '../../lotties/WhoCanHelp/balance.json'
import * as lottie_department from '../../lotties/WhoCanHelp/department.json'
import * as lottie_help from '../../lotties/WhoCanHelp/help.json'
import * as lottie_psychology from '../../lotties/ManageDayByDay/psycology.json'
import * as lottie_siren from '../../lotties/WhoCanHelp/siren.json'
import * as lottie_tutor from '../../lotties/WhoCanHelp/tutor.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import DotsGrill from '../../components/DotsGrill/DotsGrill'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import House from '../../components/Icons/House'
import ListItem from '../../components/ListItem/ListItem'
import LocationPin from '../../components/Icons/LocationPin'
import Lottie from 'react-lottie'
import Whatsapp from '../../components/Icons/Whatsapp'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const WhoCanHelp = ({
  color = 'light-blue',
  secondary = 'purple',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties
  const lottiePsychology = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_psychology.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  // https://lottiefiles.com/16763-animated-mail-illustration
  const lottieBalance = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_balance.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieTutor = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_tutor.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={200}
      height={150}
      speed={0.5}
    />
  )
  const lottieDepartment = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_department.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieSiren = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_siren.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={50}
      height={30}
      speed={0.5}
    />
  )
  const lottieHelp = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_help.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={200}
      height={200}
      speed={0.5}
    />
  )

  // Desplegables area
  const doubts = (
    <div className="desplegable-container doubts">
      <p className="text-normal">
        En la UAM pensamos que es muy importante que tú, como estudiante de esta Universidad, tengas{' '}
        <b>un lugar de referencia centrado en ti y tus necesidades</b>; un lugar al que puedas dirigirte con toda confianza para realizar cualquier{' '}
        <b>consulta, resolver dudas o plantear proyectos</b> que te ilusionaría llevar a cabo. En UAM Estudiantes te ayudaremos a disfrutar del recorrido a lo
        largo de tu vida universitaria y en todo lo que necesites. <b>Podrás plantear tus dudas u obtener información sobre:</b>
      </p>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Servicios para estudiantes</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Asuntos académicos: permanencia, anulación de matrícula, compensación o convocatoria excepcional</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Becas, ayudas económicas y premios</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Asociacionismo</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Participación estudiantil</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Ayudas para actividades del estudiantado</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Cualquier otra cuestión que necesites o quieras proponer</p>
      </ListItem>
      <p className="text-normal">
        {' '}
        A continuación, te ayudaremos a identificar otras unidades o personas que pueden ayudarte a resolver necesidades concretas, pero no olvides que si no
        tienes claro cómo proceder, <b>en UAM Estudiantes siempre será un placer echarte una mano con cualquier cosa.</b>
      </p>
    </div>
  )

  const findInfo = (
    <div className="desplegable-container find-info">
      <p className="text-normal">
        Tan solo busca la pestaña de <b>información para estudiantes</b> y, dentro, la sección dedicada a <b>Asuntos Académico-Administrativos</b> (en alguna
        ocasión llamada <b>“Gestión de Alumnos”</b>), que te ayudará a consultar fechas de algunos procedimientos, así como los requisitos y la documentación
        necesaria.
      </p>
      <div className="boxes-container">
        <div className={`box ${secondary}`}>
          <p className="text-normal bold text-center">Información para estudiantes</p>
        </div>
        <div className="arrow-container">
          <img src="/svg/icons/long-arrow-down.svg" alt="" />
        </div>
        <div className={`box ${secondary}`}>
          <p className="text-normal bold text-center">
            Asuntos Académico-Administrativos
            <br />o<br />
            Gestión de Alumnos
          </p>
        </div>
      </div>
    </div>
  )

  const organs = (
    <div className="desplegable-container organs">
      <p className="text-normal">Estos son los tres órganos que forman el Consejo de Estudiantes</p>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal bold uppercase">Plenario</p>
        <p className="text-normal">
          Órgano principal, lo forman todos los representantes de estudiantes en el Claustro de la Universidad, dos representantes de cada Consejo de
          Estudiantes de Centro, la Mesa de portavoces, dos representantes de títulos propios y un representante de cada centro adscrito.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal bold uppercase">Coordinadora</p>
        <p className="text-normal">
          Órgano que coordina el trabajo del CEUAM. Está compuesta por la Portavocía de la Mesa de Portavoces, la Portavocía de cada Consejo de Estudiantes de
          Centro, un representante de los títulos propios de la UAM y un representante de entre los Centros Adscritos de la UAM.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal bold uppercase">Mesa de portavoces</p>
        <p className="text-normal">Incluye la Portavocía, Secretaría y las Viceportavocías.</p>
      </ListItem>
    </div>
  )

  const breakTheSilence = (
    <div className="desplegable-container break-the-silence">
      <p className="text-normal">
        La Unidad de Igualdad coordina una importante iniciativa para luchar contra el <b>acoso en el entorno universitario</b>. Esta estrategia pone en marcha
        los procedimientos necesarios de manera confidencial para prestar apoyo y atención a las víctimas de cualquier situación potencialmente constitutiva de{' '}
        <b>acoso sexual</b> y/o <b>por razón de sexo</b> y, en su caso, informar a los órganos competentes para que se adopten las <b>medidas oportunas.</b>
      </p>

      <div className="break-the-silence-image">
        <img src="/svg/front-pages/who-can-help/break-the-silence.jpg" alt="" />
      </div>

      <p className="text-normal">
        En la UAM existe un protocolo completo contra el acoso, puedes consultarlo y averiguar cómo activarlo, en caso de necesidad, en esta página:
      </p>

      <ExternalLink text="Protocolo Contra Acoso Sexual" url="https://www.uam.es/uam/contra-acoso-sexual" color={secondary} secondary={color} />

      <DidYouKnow color={color}>
        <p className="text-normal">
          <b>La Unidad de Igualdad de Género (UIG)</b> está firmemente comprometida con el desarrollo del principio de igualdad entre mujeres y hombres,
          ocupándose de actuar contra el <b>acoso sexual o sexista</b>, promover un <b>uso no sexista de la lengua</b> o impulsar la docencia y la investigación
          con perspectiva de género, entre otras acciones recogidas en el Tercer Plan de Igualdad de la UAM. En coordinación con los centros de la Autónoma, la
          UIG participa en la iniciativa <b>“Rompe el silencio”</b> para luchar contra el acoso sexual y/o por razón de sexo en el entorno universitario. Si te
          encuentras en una situación comprometida, dirígete a la Unidad y se activarán los procedimientos confidenciales para prestarte apoyo y atención, así
          como para informar a los órganos competentes para que se adopten las medidas oportunas.
        </p>
      </DidYouKnow>
    </div>
  )

  const services = (
    <div className="desplegable-container services">
      <p className="text-normal">
        El área de atención a la diversidad ofrece los siguientes <b>servicios y recursos</b>:
      </p>

      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal bold">Atención, información y asesoramiento.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Planes de atención a las <b>necesidades individuales.</b>
        </p>
        <p className="text-small">(ej. elaboración de informe de necesidades para informar al profesorado)</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Gestión de recursos y servicios al estudiantado <b>con diverisdad funcional y/o necesidades específicas de apoyo educativo</b>.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Mantenimiento de <b>puestos adaptados</b> en las bibliotecas.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          <b>Banco de productos</b> de apoyo para el desarrollo de las clases, prácticas y exámenes.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Colaboración con entidades para el <b>fomento de la empleabilidad</b>.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal bold">Acciones divulgativas y cursos.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Difusión de convocatorias de <b>becas económicas, prácticas y empleo</b>.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          <b>Seguimiento del estudiantado</b> a lo largo de la vida universitaria.
        </p>
      </ListItem>
    </div>
  )

  const activities = (
    <div className="desplegable-container activities">
      <div className="text-normal">
        La UIG, junto con el Instituto Universitario de Estudios de la Mujer, desarrolla numerosas actividades que buscan que la universidad sea un espacio
        igualitario, seguro, inclusivo y diverso. Estas solo son algunas de ellas:
      </div>

      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Acciones en torno a fechas clave como el 8M, el 25N o el 11F.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Espacios violeta contra el acoso en todas las facultades.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Asignaturas transversales sobre el género que puedes elegir sea cual sea la carrera que estés cursando. </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Concurso Fotográfico por la igualdad Pilar Pérez Cantó.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Premios Mayte Gallego a los mejores TFG y TFM con perspectiva de género.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Biblioteca del IUEM donde puedes acceder a fondos bibliográficos especializados en género, feminismos, etc.</p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">Máster y Doctorado en Estudios Interdisciplinares de Género.</p>
      </ListItem>

      <div className="text-normal">
        Además, en 2023 se ha aprobado el <span className="bold">IV Plan de Igualdad</span> de la UAM.
      </div>

      <DidYouKnow color={color} title="¡Implícate, participa!">
        <p className="text-normal">Haz de la UAM un lugar más igualitario donde no quepan las desigualdades ni las discriminaciones.</p>
      </DidYouKnow>
    </div>
  )

  const equality = (
    <div className="desplegable-container equality">
      <p className="text-normal">
        Más allá de la gestión de necesidades específicas, la Unidad de Equidad Social es la encargada de <span className="bold">impulsar políticas</span> y
        líneas de actuación orientadas a velar por los <span className="bold">derechos</span> de personas con diversidad funcional, pertenecientes a los
        colectivos <span className="bold">LGTBIQA+ y/o diversidad de procedencia y cultural</span>. Su objetivo es crear un espacio participativo para el
        diagnóstico de problemáticas relacionadas y ofrecer la respuesta adecuada a la diversidad de personas y colectivos
      </p>
      <p className="text-normal">Consulta el protocolo contra el acoso por diversidad aquí:</p>
      <ExternalLink
        color={secondary}
        secondary={color}
        url="https://www.uam.es/uam/compromiso-social-sostenibilidad-uam-contra-el-acoso"
        text="Compromiso contra el acoso"
      />
      <DidYouKnow color={color}>
        <p className="text-normal">
          En la UAM existen diferentes grupos motores, cuyo objetivo es identificar barreras a la equidad, la convivencia y la no discriminación desde
          diferentes ámbitos y así proponer planes de acción específicos. Actualmente existen grupos motores en Diversidad funcional, Diversidad de procedencia
          y cultural, Igualdad de género, LGTBIQA, Bienestar emocional y Transición ecosocial y sostenibilidad. Estos grupos están integrados por personas de
          los diferentes colectivos de la comunidad universitaria y puedes participar en ellos. Si te interesa, tan solo tienes que rellenar un formulario de
          solicitud online, pincha más abajo.
        </p>
      </DidYouKnow>
      <ExternalLink color={secondary} secondary={color} url="https://www.uam.es/uam/equidad-grupo-motor" text="Equidad grupo motor" />
    </div>
  )

  // Modals area
  const uamEstudiantes = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">UAM Estudiantes</h3>
          <div className="actual-text">
            <p className="text-normal">
              ¿Acabas de llegar y tienes algunas dudas? ¿No sabes qué pasos debes seguir para realizar un trámite? Quizá alguien que conoces también quiere
              estudiar en la UAM; ¿qué puede hacer y cómo? <b>Siempre puedes dirigirte a UAM Estudiantes</b> para resolver cualquier duda o realizar todo tipo
              de consultas. <b>Visítanos en la planta baja de la Plaza Mayor, ¡seguro que volverás!</b>
            </p>

            <div className={`box ${secondary}`}>
              <div className="icon-container">
                <LocationPin color={secondary} />
              </div>
              <div>
                <p className="bold text-normal">
                  Edificio Plaza Mayor UAM
                  <br />
                  Lunes a jueves: 10:00 a 14:00 y de 15:00 a 17:00
                  <br />
                  Viernes: 10:00 a 14:00
                  <br />
                </p>
                <p className="text-normal">
                  <i>Los meses de junio y julio la oficina no abre en horario de tarde. Agosto cerrado.</i>
                </p>
              </div>
            </div>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Dudas que podrás resolver">
                {doubts}
              </Desplegable>
            </div>
            <AddressBookComp color={color} data={contacts['uam-estudiantes']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const secretary = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Secretaría y administración de tu centro</h3>
          <div className="actual-text">
            <p className="text-normal">
              Será tu punto de referencia para exponer todas aquellas <b>consultas o dificultades</b> con las que te encuentres en relación con la{' '}
              <b>matrícula</b> de cada curso (anulaciones, devolución de tasas…) y algunos <b>otros procedimientos administrativos</b> (solicitud de
              certificados académicos, registro de documentos, peticiones de cambio de turno…). En la <b>web de tu centro</b> podrás encontrar información
              detallada sobre la mayoría de los trámites que se llevan a cabo en la secretaría.
            </p>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Cómo encontrar información en la web">
                {findInfo}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const departament = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Departamento</h3>
          {lottieDepartment}
          <div className="actual-text">
            <p className="text-normal">
              Recuerda que las <b>asignaturas</b> dependen de departamentos concretos: en la página <b>web</b> de tu centro puedes consultar qué departamentos
              existen y de qué asignaturas son responsables. Si tuvieras problemas con alguna asignatura habla en primer lugar con tu{' '}
              <b>profesor o profesora</b>, o con la <b>coordinación de la asignatura</b>. Si fuera necesario, también puedes ponerte en contacto con la persona
              o comisión responsable de la <b>coordinación y seguimiento del Grado</b>, así como con <b>el/la director/a del Departamento</b> al que pertenece
              la asignatura. Ellos pueden mediar entre docentes y estudiantes en caso de desacuerdos y, si no estuvieras conforme con alguna calificación,{' '}
              <b>ayudarte a tramitar una solicitud de tribunal de revisión.</b>
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const tutor = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Tutor/a del plan de acción tutelar</h3>
          {lottieTutor}
          <div className="actual-text">
            <p className="text-normal">
              Una figura muy importante a lo largo de todo el Grado es tu <b>tutor o tutora PAT (Plan de Acción Tutelar)</b>, que te asignarán desde comienzo
              del primer curso. Aprovecha su disponibilidad siempre que surja alguna duda y dirígete a esta persona en el caso de que tuvieras problemas de
              permanencia. <b>Para saber quién te tutela, entra en Sigma y consulta la opción «mi expediente» – «tutor asignado».</b> Además de tu tutor o
              tutora, cada grado tiene un <b>coordinador o coordinadora –o delegado o delegada–</b> que puede atender cualquier consulta o necesidad relacionada
              con tus estudios.
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const vicedecanato = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Vicedecanato o subdirección de estudiantes</h3>
          <div className="actual-text">
            <p className="text-normal">
              En el <b>equipo de dirección del centro</b>, es el área que vela por el cumplimiento de tus <b>deberes y derechos como estudiante</b>, al tiempo
              que fomenta tu <b>participación en la vida universitaria.</b> Está siempre a tu disposición y también puede ayudarte si está al corriente de
              cualquier problema. Consulta la página web de tu centro para saber a qué correo escribir o a qué despacho dirigirte.
            </p>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const defense = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Defensoría universitaria</h3>
          <div className="actual-text">
            {lottieBalance}
            <p className="text-normal">
              Es el órgano encargado de la garantía y defensa de los derechos de todos los miembros de la comunidad universitaria. Es importante que tengas en
              cuenta que la <b>Defensoría solo actúa cuando se han agotado las vías establecidas por la Universidad para solucionar los problemas.</b> Por ello
              es tan importante que acudas antes a la secretaría de tu centro, a tus profesoras o profesores, tutor o tutora PAT, dirección del departamento, la
              coordinación de la titulación y/o al equipo decanal del centro, según la necesidad. Si tras seguir los pasos adecuados persiste el problema,
              accede a:
            </p>
            <AddressBookComp color={color} data={contacts['defensoria-universitaria']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const council = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Consejo de estudiantes</h3>
          <div className="actual-text">
            <p className="text-normal">
              <b>El Consejo de Estudiantes</b> es el máximo órgano de representación estudiantil de la Universidad Autónoma de Madrid. Está formado por
              estudiantes de los diferentes centros de la universidad, compañeros y compañeras tuyas que, además de estudiar, se encargan de{' '}
              <b>defender los derechos</b> de todo el estudiantado, tanto individuales como colectivos, y hacen velar su aplicación frente a las autoridades
              competentes. El Consejo sirve de vía para manifestar los <b>intereses</b>, <b>motivaciones</b>, <b>peticiones</b> e <b>inquietudes</b> de todas
              las y los estudiantes de la UAM, representándoos en los órganos de consejo de gobierno e impulsando diferentes actividades y proyectos para
              fomentar la vida universitaria.
            </p>
            <p className="text-normal">
              Podrás encontrarles en la <b>Casa de Estudiantes</b> en la Plaza Mayor UAM , contactar por correo, Whatsapp o redes sociales.
            </p>
            <div className={`ExternalLink ${color}`}>
              <div className="icon-container">
                <Whatsapp color={color} />
              </div>
              <a href={'https://wa.me/34695072274'} target="_blank" className={`${secondary} tex-normal bold underline`} rel="noopener noreferrer">
                {`Whatsapp: +34 695 07 22 74`}
              </a>
            </div>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Órganos que forman parte del consejo de estudiantes">
                {organs}
              </Desplegable>
            </div>
            <DidYouKnow color={secondary} title="!">
              <p className="text-normal">
                Aunque el CEUAM tiene representantes en todos los centros, también puedes visitarles en la <b>Casa de Estudiantes</b>{' '}
                <House color={secondary} height={15} width={15} />, un espacio para todo el estudiantado donde, además de{' '}
                <b>conocer a gente y desconectar en tus ratos libres</b>, podrás encontrar a quien te <b>eche una mano</b> con cualquier duda.
              </p>
            </DidYouKnow>
            <DidYouKnow color={color}>
              <p className="text-normal">
                <b>Cualquier estudiante UAM</b> puede formar una candidatura y <b>presentarse a las elecciones</b> que se celebran <b>cada dos años</b> para
                representar al colectivo de estudiantes y participar en la gestión de los órganos de gobierno de la Universidad.
              </p>
              <p className="text-normal">
                Si todavía no te animas a dar ese paso, <b>también puedes presentarte a delegado o delegada de grupo</b>, curso o titulación.{' '}
                <b>Representarás a tus compañeros y compañeras ante el profesorado</b>, trasladándole las propuestas, peticiones, quejas, etc. plateadas por la
                mayoría y de interés para todo el estudiantado que representes,{' '}
                <b>difundirás y participarás en las campañas de encuestas de actividad docente</b> y participarás también en la{' '}
                <b>elaboración del Calendario Académico</b> y en la <b>Comisión de Titulación o Comisión de Seguimiento de la Titulación.</b> Aprenderás mucho
                sobre la Universidad y las competencias que hayas adquirido podrán quedar recogidas en un certificado, además de poder solicitar el{' '}
                <b>reconocimiento de créditos por tu labor.</b>
              </p>
            </DidYouKnow>
            <ExternalLink text="X @ceuam" url="https://x.com/ceuam" color={secondary} secondary={color} />
            <ExternalLink text="Instagram @ceuam" url="https://www.instagram.com/ceuam/" color={secondary} secondary={color} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const psychology = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Centro de psicología aplicada</h3>
          <div className="actual-text">
            {lottiePsychology}
            <p className="text-normal">
              Si en algún momento tienes que realizar alguna <span className="bold">consulta en el ámbito psicológico</span> o necesitas asesoramiento, el
              Centro de Psicología Aplicada de la UAM te puede ayudar. Sus servicios de <span className="bold">atención psicológica, a precios públicos</span>,
              abarcan tanto la evaluación con pruebas adaptadas como el tratamiento con técnicas de intervención. Obtén más info en su página web:
            </p>

            <ExternalLink secondary={color} color={secondary} url="https://www.uam.es/uam/cpa" text="¡Obtén más información!" />

            <AddressBookComp color={color} data={contacts['centro-psicologia-aplicada']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const listen = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Unidad de escucha y acompañamiento</h3>
          <div className="actual-text">
            {lottieHelp}
            <p className="text-normal">
              ¿Estás pasando por momentos difíciles? ¿Sientes confusión o desánimo al afrontar tu vida universitaria? ¿Algo está afectando a tu rendimiento
              académico o sientes soledad en el campus?
            </p>
            <p className="text-normal">
              La Unidad de Escucha y Acompañamiento no es un espacio de terapia psicológica, sino un canal de escucha donde podemos hablar de ti de manera
              segura y confidencial, tanto a modo de desahogo como para darte algo de orientación que pueda ayudarte a mejorar tu situación personal y
              académica.
            </p>
            <p className="text-normal">
              <b>TODOS</b> necesitamos <b>APOYO</b> alguna vez. Puedes escribir a la Unidad por Whatsapp, llamar por teléfono o escribir un correo.{' '}
              <b>En la UAM podemos ayudarte.</b>
            </p>

            <AddressBookComp color={color} data={contacts['unidad-escucha']} />

            <div className="danger">
              <div className="danger-title-container">
                {lottieSiren}
                <p className="danger-title-number">112</p>
                <p className="danger-title-text">EMERGENCIAS</p>
                <img className="danger-stars" src="/svg/logos/madrid-stars.svg" />
              </div>
              <div className="danger-text-container">
                Si observas alguna situación que requiera intervención inmediata en el campus, no dudes en llamar al 112, el teléfono para{' '}
                <span className="danger-bold">emergencias</span> de la Comunidad de Madrid. Tu actuación puede ser crucial.
              </div>
            </div>

            <DidYouKnow color={color}>
              <p className="text-normal">
                En la UAM existen diferentes grupos motores, cuyo objetivo es <b>identificar barreras</b> a la equidad, la convivencia y la no discriminación
                desde diferentes ámbitos y así <b>proponer planes de acción</b> específicos.
              </p>
              <p className="text-normal">
                Actualmente existen grupos en <b>Diversidad funcional</b>, <b>Diversidad de procedencia y cultural</b>, <b>Igualdad de género</b>,{' '}
                <b>LGTBIQA</b>, <b>Bienestar emocional o Transición ecosocial y sostenibilidad</b>. Están integrados por personas de los diferentes colectivos y
                puedes participar en ellos.
              </p>
              <p className="text-normal">Si te interesa, solo tienes que rellenar una solicitud en el siguiente link.</p>
              <ExternalLink color={secondary} secondary={color} url="https://www.uam.es/uam/equidad-grupo-motor" text="Rellenar solicitud" />
            </DidYouKnow>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const gender = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Unidad de igualdad de género</h3>
          <div className="actual-text">
            <p className="text-normal">
              ¿Crees que nuestra sociedad es igualitaria? ¿Sigue existiendo discriminación de género? Nuestra Universidad tiene un firme compromiso para
              erradicar cualquier forma de discriminación y avanzar hacia la igualdad efectiva entre mujeres y hombres, y desde hace diez años cuenta con una{' '}
              <b>Unidad de Igualdad de Género (UIG)</b> que se ocupa de actuar contra el acoso sexual y por razón de sexo, promover un uso no sexista de la
              lengua o impulsar la docencia y la investigación con perspectiva de género, entre otras cuestiones.
            </p>

            <div className="gender-equality-image">
              <img src="/svg/front-pages/who-can-help/gender-equality-unit.jpg" alt="" />
            </div>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Rompe el silencio">
                {breakTheSilence}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Actividades">
                {activities}
              </Desplegable>
            </div>

            <AddressBookComp color={color} data={contacts['unidad-igualdad']} />

            <ExternalLink color={secondary} secondary={color} url="https://www.instagram.com/uig_uam/" text="Instagram @uig_uam" />

            <ExternalLink color={secondary} secondary={color} url="https://x.com/UAM_UIgualdadG" text="X @UAM_UIgualdadG" />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const specials = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Atención a la diversidad</h3>
          <div className="actual-text">
            <p className="text-normal">
              Para fomentar la <b>igualdad de oportunidades</b> y la plena inclusión de todo el estudiantado de la UAM,{' '}
              <a href="https://www.uam.es/uam/diversidad-funcional" target="_blank" rel="noopener noreferrer" className="bold underline">
                el Área de Atención a la Diversidad Funcional
              </a>{' '}
              de la Oficina de Acción Solidaria y Cooperación presta apoyo individualizado al estudiantado con discapacidad o necesidades específicas de apoyo
              educativo, con o sin certificado de discapacidad
            </p>

            {services}

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Objetivo: equidad en la UAM">
                {equality}
              </Desplegable>
            </div>

            <AddressBookComp color={color} data={contacts['accion-solidaria']} />
            <AddressBookComp color={color} data={contacts['unidad-equidad']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'uam-estudiantes') isDesktop ? setModal(uamEstudiantes) : modalHandling(uamEstudiantes)
    else if (modalContent === 'secretaria') isDesktop ? setModal(secretary) : modalHandling(secretary)
    else if (modalContent === 'departamento') isDesktop ? setModal(departament) : modalHandling(departament)
    else if (modalContent === 'tutor') isDesktop ? setModal(tutor) : modalHandling(tutor)
    else if (modalContent === 'vicedecanato') isDesktop ? setModal(vicedecanato) : modalHandling(vicedecanato)
    else if (modalContent === 'defense') isDesktop ? setModal(defense) : modalHandling(defense)
    else if (modalContent === 'listen') isDesktop ? setModal(listen) : modalHandling(listen)
    else if (modalContent === 'psychology') isDesktop ? setModal(psychology) : modalHandling(psychology)
    else if (modalContent === 'specials') isDesktop ? setModal(specials) : modalHandling(specials)
    else if (modalContent === 'council') isDesktop ? setModal(council) : modalHandling(council)
    else if (modalContent === 'gender') isDesktop ? setModal(gender) : modalHandling(gender)
    else isDesktop && setModalContent('uam-estudiantes')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <DotsGrill color={secondary} />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/WhoCanHelp_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/WhoCanHelp.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <img src="/splash/svg/WhoCanHelp_logo_front.svg" alt="" />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop WhoCanHelp ${color} ${openDesktop ? 'open' : 'closed'}`} id="who-can-help">
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Averigua quién puede ayudarte</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'uam-estudiantes' ? 'selected' : ''}`}
                              onClick={() => setModalContent('uam-estudiantes')}
                            >
                              1. UAM Estudiantes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'secretaria' ? 'selected' : ''}`}
                              onClick={() => setModalContent('secretaria')}
                            >
                              2. Secretaría y administración de tu centro{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'departamento' ? 'selected' : ''}`}
                              onClick={() => setModalContent('departamento')}
                            >
                              3. Departamento{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'tutor' ? 'selected' : ''}`} onClick={() => setModalContent('tutor')}>
                              4. Tutor o tutora del plan de acción tutelar{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'vicedecanato' ? 'selected' : ''}`}
                              onClick={() => setModalContent('vicedecanato')}
                            >
                              5. Vicedecanato o subdirección de estudiantes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'defense' ? 'selected' : ''}`}
                              onClick={() => setModalContent('defense')}
                            >
                              6. Defensoría universitaria{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            {/* Start */}
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'listen' ? 'selected' : ''}`}
                              onClick={() => setModalContent('listen')}
                            >
                              7. Unidad de Escucha y Acompañamiento{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'psychology' ? 'selected' : ''}`}
                              onClick={() => setModalContent('psychology')}
                            >
                              8. Centro de psicología aplicada{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'specials' ? 'selected' : ''}`}
                              onClick={() => setModalContent('specials')}
                            >
                              9. Atención a la diversidad{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            {/* End*/}
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'council' ? 'selected' : ''}`}
                              onClick={() => setModalContent('council')}
                            >
                              10. Consejo de estudiantes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'gender' ? 'selected' : ''}`}
                              onClick={() => setModalContent('gender')}
                            >
                              11. Unidad de igualdad de género{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="who-can-help-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`WhoCanHelp main-page ${color}`} id="who-can-help">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="who-can-help">
                {Splash}
              </div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Averigua quién puede ayudarte</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="who-can-help-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Averigua quién puede ayudarte</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('uam-estudiantes')}>
                      <span>1. UAM Estudiantes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('secretaria')}>
                      <span>2. Secretaría y administración de tu centro</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('departamento')}>
                      <span>3. Departamento</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('tutor')}>
                      <span>4. Tutor o tutora del plan de acción tutelar</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('vicedecanato')}>
                      <span>5. Vicedecanato o subdirección de estudiantes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('defense')}>
                      <span>6. Defensoría universitaria</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('listen')}>
                      <span>7. Unidad de Escucha y Acompañamiento</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('psychology')}>
                      <span>8. Centro de psicología aplicada</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('specials')}>
                      <span>9. Atención a la diversidad</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('council')}>
                      <span>7. Consejo de estudiantes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('gender')}>
                      <span>8. Unidad de igualdad de género</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>

              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WhoCanHelp
