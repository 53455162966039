import React from 'react'
import { colorToHex } from '../../data'

const Forum = ({ color = 'orange' }) => {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9986 0L0.712891 7.14286V10H27.2843V7.14286L13.9986 0ZM20.2918 12.8571V22.8571H24.4873V12.8571H20.2918ZM0.712891 30H27.2843V25.7143H0.712891V30ZM11.9009 12.8571V22.8571H16.0963V12.8571H11.9009ZM3.50988 12.8571V22.8571H7.70537V12.8571H3.50988Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Forum
