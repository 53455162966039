import React from 'react'
import { colorToHex } from '../../data'

const Award = ({ color = 'red', width = 43 }) => {
  return (
    <svg width={width} height={width * (width / 51)} viewBox="0 0 43 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.5 43.8537L32.4631 40.4287L29.0089 50.4287L21.2998 35.4287L13.9911 50.4287L10.537 40.4287L0.5 43.8537L7.8087 28.8537C5.32309 25.7543 3.97199 21.8998 3.97914 17.9287C3.97914 13.2874 5.82508 8.83623 9.11088 5.55434C12.3967 2.27246 16.8532 0.428711 21.5 0.428711C26.1468 0.428711 30.6033 2.27246 33.8891 5.55434C37.1749 8.83623 39.0209 13.2874 39.0209 17.9287C39.0209 22.0537 37.5942 25.8537 35.1913 28.8537L42.5 43.8537ZM8.9851 17.9287L15.7181 21.2787L15.2426 28.7787L21.5 24.6287L27.7575 28.7537L27.3319 21.2787L34.0149 17.9287L27.3069 14.5537L27.7575 7.10371L21.5 11.2037L15.2426 7.05371L15.6681 14.5787L8.9851 17.9287Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Award
