import React from 'react'
import './MailBox.scss'
import Sendvelope from '../Icons/Sendvelope'

const MailBox = ({ color = 'orange', secondary = 'dark-green', mail = '', text }) => {
  return (
    <div className={`MailBox ${color}`}>
      <div className="icon-container">
        <Sendvelope color={color} />
      </div>
      <a className={`colored underline ${secondary}`} href={`mailto:${mail}`}>
        {text || mail}
      </a>
    </div>
  )
}

export default MailBox
