import React from 'react'
import { colorToHex } from '../../data'

function Football({ color = 'red', width = 40 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} fill="none" viewBox="0 0 256 256">
      <path
        fill={colorToHex(color)}
        d="M229.059 53.89a32.916 32.916 0 00-26.95-26.95c-32.37-5.49-93.39-8-138.27 36.9-44.88 44.9-42.39 105.9-36.9 138.27a32.919 32.919 0 0027 26.95A206.685 206.685 0 0088.27 232c32.09 0 72.05-8 103.89-39.84 44.89-44.88 42.39-105.9 36.9-138.27zm-61.61-14c10.726.038 21.43.974 32 2.8a16.938 16.938 0 0113.85 13.87 188.56 188.56 0 012.7 36.22L163.209 40c1.4-.08 2.79-.1 4.24-.1v-.01zM56.559 213.3a16.943 16.943 0 01-13.86-13.86 188.606 188.606 0 01-2.7-36.22L92.78 216a187.997 187.997 0 01-36.22-2.7zm124.3-32.44c-11.61 11.6-33.27 27.73-67.37 33.27l-71.62-71.62c5.54-34.1 21.67-55.76 33.27-67.37 11.6-11.61 33.26-27.74 67.36-33.28l71.63 71.63c-5.54 34.1-21.67 55.76-33.27 67.37zm-15.22-90.5a8.001 8.001 0 010 11.31L151.299 116l6.34 6.34a8 8 0 11-11.31 11.3l-6.33-6.33-12.69 12.69 6.34 6.34a8.015 8.015 0 011.869 2.596 8.009 8.009 0 01-1.637 8.935 8.01 8.01 0 01-5.813 2.344 8.014 8.014 0 01-5.719-2.565l-6.35-6.35-14.34 14.34a8.001 8.001 0 01-11.457.147 8.002 8.002 0 01.147-11.457L104.7 140l-6.34-6.34a8.002 8.002 0 01.223-11.087 8 8 0 0111.087-.213l6.34 6.34 12.68-12.7-6.34-6.34a8 8 0 0111.3-11.31l6.35 6.35 14.34-14.34a8.001 8.001 0 0111.3 0z"
      ></path>
    </svg>
  )
}

export default Football
