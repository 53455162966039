import './DidYouKnow.scss'

import React from 'react'

export default function DidYouKnow({ title = '¿Sabías que?', color, children }) {
  return (
    <div className={`did-you-know ${color}`}>
      <p className="text-normal urbana uppercase label">{title}</p>
      {children}
    </div>
  )
}
