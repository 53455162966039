import React from 'react'
import './VerticalWaves.scss'
import { colorToHex } from '../../data'

const VerticalWaves = ({ color = 'orange' }) => {
  return (
    <div className={`VerticalWaves ${color}`}>
      <svg width="84" height="96" viewBox="0 0 84 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M81.7086 40.4745L75.0182 36.1778C72.8969 34.8155 70.1502 34.9245 68.1436 36.4507L65.1643 38.7167C63.1721 40.2321 60.4484 40.3514 58.3312 39.0161L55.0575 36.9513C52.9551 35.6253 50.2528 35.7328 48.2625 37.2218L45.1878 39.522C43.1831 41.0216 40.458 41.119 38.3514 39.7661L35.1421 37.705C33.0315 36.3496 30.3005 36.45 28.2953 37.9569L25.1928 40.2883C23.1875 41.7952 20.4566 41.8957 18.346 40.5402L15.1665 38.4983C13.0452 37.136 10.2986 37.245 8.29199 38.7712L2 43.5569"
          stroke={colorToHex(color)}
          strokeWidth="4.3195"
          strokeMiterlimit="10"
        />
        <path
          d="M81.7711 54.3667L75.0807 50.07C72.9594 48.7077 70.2127 48.8167 68.2061 50.343L65.2268 52.609C63.2346 54.1243 60.5109 54.2436 58.3937 52.9083L55.12 50.8435C53.0176 49.5175 50.3153 49.6251 48.325 51.114L45.2503 53.4142C43.2456 54.9139 40.5205 55.0112 38.4139 53.6583L35.2046 51.5972C33.094 50.2418 30.363 50.3422 28.3578 51.8491L25.2553 54.1805C23.25 55.6874 20.5191 55.7879 18.4085 54.4324L15.229 52.3905C13.1077 51.0282 10.3611 51.1372 8.35449 52.6635L2.0625 57.4491"
          stroke={colorToHex(color)}
          strokeWidth="4.3195"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}

export default VerticalWaves
