import './MapCard.scss'

import { Document, Page, pdfjs } from 'react-pdf'
import React, { useEffect, useState } from 'react'

import LocationPin from '../Icons/LocationPin'
import MapCardContacts from './MapCard.contacts'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

const MapCard = ({ color, secondary, map, text, contacts = null, setFullScreen }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setFullScreen(open)
    return () => {
      setFullScreen(false)
    }
  }, [open])

  return (
    <>
      <div className={`MapCard ${color}`} onClick={() => setOpen(true)}>
        <div className="icon-container-maps">
          <LocationPin color={color} />
        </div>
        <p className={`heroic italic uppercase text-semi-normal ${secondary}`}>{text}</p>
      </div>
      {open && (
        <div className="full-screen-container">
          <div className="backdrop" onClick={() => setOpen(false)}></div>
          <div className="close" onClick={() => setOpen(false)}>
            x
          </div>
          <div className={`modal ${color}`}>
            <div className="map">
              <Document file={map} loading="Cargando plano en PDF" renderMode="svg">
                <Page loading="Cargando página" pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
              </Document>
            </div>

            {contacts && <MapCardContacts />}
          </div>
        </div>
      )}
    </>
  )
}

export default MapCard
