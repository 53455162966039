import React from 'react'
import './ListItem.scss'
import Kite from '../Icons/Kite'
import ExternalPage from '../Icons/ExternalPage'
import VisibilitySensor from 'react-visibility-sensor'

const ListItem = ({ color, children, custom, kiteColor, url }) => {
  const handleClick = () => {
    if (url?.length > 0) window.open(url, '_blank')
  }

  const kite = <div className="kite-container">{custom || <Kite color={kiteColor || color} />}</div>
  return (
    <VisibilitySensor>
      {({ isVisible }) => (
        <div
          onClick={() => handleClick()}
          className={`ListItem ${color} ${isVisible ? 'visible' : 'hidden'} ${custom ? 'custom' : ''} ${url?.length > 0 ? 'with-link' : ''}`}
        >
          {kite}
          <div className="children">{children}</div>
          {url?.length > 0 && (
            <div className="external-link">
              <ExternalPage />
            </div>
          )}
        </div>
      )}
    </VisibilitySensor>
  )
}

export default ListItem
