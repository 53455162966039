import './Norms.scss'

import * as lottie_academic_evaluation from '../../lotties/Norms/academic-evaluation.json'
import * as lottie_form_matricula from '../../lotties/Norms/form-matricula.json'
import * as lottie_matricula from '../../lotties/Norms/matricula.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import KiteTitle from '../../components/KiteTitle/KiteTitle'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import Quiz from '../../components/Quiz/Quiz'
import VisibilitySensor from 'react-visibility-sensor'
import useScreenSize from '../../hooks/screen-size'

const Norms = ({
  color = 'brown',
  secondary = 'light-blue',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  const lottieMatricula = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_matricula.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieFormMatricula = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_form_matricula.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )
  const lottieAcademicEvaluation = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_academic_evaluation.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // ======= Desplegables area ======= \\
  const ects = (
    <div className="desplegable-container ects">
      <p className="text-normal">
        Los créditos ECTS (European Credit Transfer and Accumulation System) son el <b>sistema europeo para contabilizar el valor de una asignatura</b> en la
        formación de los estudiantes. Cada <b>crédito ECTS</b> equivale a unas <b>25-30 horas de trabajo.</b> Estos créditos permiten comparar mucho más
        fácilmente la carga lectiva de las titulaciones y facilita la movilidad y el reconocimiento de los estudios en el extranjero.
      </p>

      <VisibilitySensor>
        {({ isVisible }) => (
          <div className={`schema-credits animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <img src="/svg/front-pages/norms/esquema_creditos.png" alt="" />
          </div>
        )}
      </VisibilitySensor>
      <DidYouKnow color={color} title="¡Atención!">
        <p className="text-normal">
          <b>El coste de los créditos se duplica si tienes que volver a matricular una asignatura.</b> Las Tasas y Precios Públicos no los establece la UAM,
          sino que se ajustan a la normativa de la Comunidad de Madrid que afecta a todas las universidades públicas de la región.{' '}
          <b>Revisa esa normativa para evitar sorpresas.</b>
        </p>
        <div className="urbana exclamation">!</div>
      </DidYouKnow>
    </div>
  )

  const formMatricula = (
    <div className="desplegable-container matricula">
      {lottieFormMatricula}
      <p className="text-normal">
        Debes <b>realizar la matrícula</b> para cada curso en la <b>fecha que te indique tu centro de estudios.</b> Tendrás la{' '}
        <b>posibilidad de matricular cada asignatura en dos ocasiones</b> y cada una de ellas te permitirá examinarte en <b>dos convocatorias</b> (ordinaria y
        extraordinaria). Revisa la sección <b>“Permanencia” dentro de Evaluación Académica</b> para ver en qué ocasiones puedes disponer de una tercera
        matrícula e, incluso, una convocatoria excepcional.
      </p>

      <VisibilitySensor>
        {({ isVisible }) => (
          <div className={`schema-matricula animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
            <img src="/svg/front-pages/norms/esquema-matricula.png" alt="" />
          </div>
        )}
      </VisibilitySensor>

      <p className="text-normal">
        En algunas titulaciones, al formalizar tu matrícula podrás elegir entre <b>turno de mañana o de tarde</b> según las plazas disponibles. En el supuesto
        de que no hubiera plazas en el turno que quieres matricular, podrás presentar una <b>solicitud de cambio de turno</b> en la administración de tu centro
        por <b>motivos debidamente justificados y documentados.</b>
      </p>
      <p className="text-normal">
        En el caso de que necesites o prefieras <b>anular la matrícula en alguna asignatura,</b> podrás solicitarlo en los <b>30 días siguientes</b> (Plazo de{' '}
        <b>45 días para nuevos estudiantes</b>) al comienzo del semestre en que se imparte, aunque también existen excepciones por las que podrías realizar una
        anulación incluso fuera del plazo marcado, las encontrarás en la normativa.
      </p>
      <p className="text-normal">
        Consulta en esta página una guía completa sobre <b>cómo realizar la matrícula paso a paso</b>:
        <ExternalLink text={'Guía completa matrícula'} url={'https://www.uam.es/uam/estudios/grado/matricula'} color={secondary} secondary={color} />
      </p>
    </div>
  )

  const whatMeans = (
    <div className="desplegable-container what-means">
      <p className="text-normal">Te vendrá bien conocer esta terminología:</p>

      <ul className="blocks-container">
        <VisibilitySensor>
          {({ isVisible }) => (
            <li className={`${isVisible ? 'visible' : 'hidden'}`}>
              <KiteTitle>Normativa</KiteTitle>
              <p className="text-normal">
                Conjunto de normas que establecen una regulación de diferentes actividades o recursos a los que puede tener acceso un colectivo. En la UAM, la
                mayoría de tus derechos y deberes como estudiante están recogidos en la normativa propia.
              </p>
            </li>
          )}
        </VisibilitySensor>
        <VisibilitySensor>
          {({ isVisible }) => (
            <li className={`${isVisible ? 'visible' : 'hidden'}`}>
              <KiteTitle>Bases reguladoras</KiteTitle>
              <p className="text-normal">
                Las bases reguladoras establecen las condiciones generales de acceso a los recursos y actividades que posibilita la normativa. Por citar un
                ejemplo, las Bases Reguladoras de las Ayudas para Actividades del Estudiantado establecen la finalidad de las mismas, el número de convocatorias
                o los criterios de concesión.
              </p>
            </li>
          )}
        </VisibilitySensor>
        <VisibilitySensor>
          {({ isVisible }) => (
            <li className={`${isVisible ? 'visible' : 'hidden'}`}>
              <KiteTitle>Convocatoria</KiteTitle>
              <p className="text-normal">
                Se trata de resoluciones por las cuales se inicia cada procedimiento administrativo. En ellas encontrarás la información más específica, como
                los plazos de presentación, la documentación a aportar, etc. Como ya sabrás a esta altura de la guía, la convocatoria se refiere a la cita que
                tienes con el profesorado de tus asignaturas para hacer un examen.
              </p>
            </li>
          )}
        </VisibilitySensor>
        <VisibilitySensor>
          {({ isVisible }) => (
            <li className={`${isVisible ? 'visible' : 'hidden'}`}>
              <KiteTitle>Resolución</KiteTitle>
              <p className="text-normal">
                Tras la consideración de una solicitud, la administración emite una resolución que puede ser provisional (permite al solicitante presentar
                alegaciones) o definitiva. En algunos casos, tras una resolución definitiva puede existir un derecho de reclamación ante el mismo órgano u otro
                superior.
              </p>
            </li>
          )}
        </VisibilitySensor>
        <VisibilitySensor>
          {({ isVisible }) => (
            <li className={`${isVisible ? 'visible' : 'hidden'}`}>
              <KiteTitle>Protocolo</KiteTitle>
              <p className="text-normal">
                Secuencia detallada de un proceso de instrucciones para la actuación administrativa, científica, técnica o médica, ante determinadas
                circunstancias. Como ejemplo, puedes consultar los protocolos contra el acoso de la UAM{' '}
                <a href="https://www.uam.es/uam/compromiso-social-sostenibilidad-uam-contra-el-acoso">aquí</a>.
              </p>
            </li>
          )}
        </VisibilitySensor>
      </ul>
    </div>
  )

  const importantAspects = (
    <div className="desplegable-container important-aspects">
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Dispondrás de <b>guías docentes</b> de las asignaturas antes de la apertura del período de matrícula. En ellas encontrarás{' '}
          <b>información de procedimientos</b> y de <b>pruebas de evaluación previstas</b>, la importancia de estos elementos en la <b>calificación final</b> y
          los <b>criterios</b> para llevar a cabo dicha <b>evaluación.</b>
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Si por motivos de <b>asistencia a reuniones de órganos colegiados u otras causas justificadas,</b> no puedes acudir a una prueba de evaluación,
          tendrás derecho a <b>realizar otra equivalente en día y hora diferentes</b>, o que se adopte otra medida que no cause perjuicio a tu evaluación
          global.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          En la publicación de las <b>calificaciones finales</b>, el docente hará constar el <b>día y hora</b> de la <b>revisión de la evaluación</b>, dejando
          un plazo entre la fecha de la publicación y aquella en la que se inicie la revisión.
        </p>
      </ListItem>
      <ListItem color={color} kiteColor={secondary}>
        <p className="text-normal">
          Si <b>no estás de acuerdo con una calificación</b> tras acudir a su revisión, puedes <b>interponer una reclamación en el Departamento</b> que gestiona
          la docencia de dicha asignatura.
        </p>
      </ListItem>
    </div>
  )

  const requirements = (
    <div className="desplegable-container requirements">
      <p className="text-normal">
        Si el número de asignaturas para las que necesitas una tercera matrícula
        <b>es superior a 3</b> o <b>no has superado el mínimo</b> de créditos exigido en el curso, la Universidad te deberá <b>conceder la permanencia</b> para
        continuar tus estudios, lo que deberás <b>solicitar en la Sede Electrónica</b> en los plazos establecidos.
      </p>

      <ExternalLink url="https://sede.uam.es" color={secondary} secondary="brown" text="Sede electrónica" />

      <DidYouKnow color={color} title="¡Atención!">
        <p className="text-normal">
          Si te encuentras en alguna de estas situaciones pídele ayuda a tu <b>tutor o tutora PAT.</b> Su informe será valorado en solicitudes de permanencia,
          convocatoria excepcional o compensación curricular.
        </p>
        <div className="urbana exclamation">!</div>
      </DidYouKnow>
    </div>
  )

  const quiz = (
    <div className="desplegable-container requirements">
      <p className="text-normal">Averigua en qué caso te encuentras:</p>
      <Quiz />
    </div>
  )

  // Modals area
  const matricula = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Matrícula</h3>
          <div className="actual-text">
            {lottieMatricula}
            <p className="text-normal">
              Tanto si eres estudiante de Grado como de Máster, puedes decidir estudiar a <b>tiempo parcial (de 24 a 36 ECTS)</b> o a{' '}
              <b>tiempo completo (de 37 a 60 ETCS).</b>
            </p>

            <div className={`box equal-padding ${secondary}`}>
              <p className="text-normal">
                Existen excepciones en relación al mínimo y máximo de créditos a matricular, te recomendamos{' '}
                <b>consultar la Normativa de Matrícula y Permanencia</b> para conocer todos los detalles.
              </p>
            </div>

            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Cómo realizarla antes de cada curso">
                {formMatricula}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Información sobre los créditos ECTS">
                {ects}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const academic = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Evaluación académica</h3>
          <div className="actual-text">
            <p className="text-normal">
              La Normativa de Evaluación Académica recoge tus <b>derechos y deberes</b> en lo relativo a la <b>evaluación de las asignaturas</b> (incluidas las
              prácticas externas y los trabajos de fin de Grado o Máster). Aquí incluimos algunos aspectos importantes como ejemplo, pero consulta dicha
              normativa para conocer todos los detalles.
            </p>

            {lottieAcademicEvaluation}

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Aspectos importantes">
                {importantAspects}
              </Desplegable>
            </div>

            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const perm = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Permanencia</h3>
          <div className="actual-text">
            <p className="text-normal">
              Continuar tus estudios en la UAM con normalidad exige un rendimiento mínimo. Para cumplirlo, tienes que asegurarte de:
            </p>

            <div className="kite-list-container">
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">
                  Superar al menos el <b>20%</b> de los créditos matriculados en cada curso de Grado y el <b>50%</b> en Máster (este requisito no aplicará
                  cuando hayas superado el <b>70%</b> de la titulación).
                </p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">
                  Superar las asignaturas en alguna de las dos matrículas permitidas (se te concederá una tercera matrícula de manera automática solo si el
                  número de asignaturas no superadas tras dos matrículas es de 3 o menos).
                </p>
              </ListItem>
            </div>

            <p className="text-normal">
              En el caso del estudiantado de Grado, además, deberás haber superado <b>al menos el 50%</b> de los créditos totales del primer curso (<b>25%</b>{' '}
              si has realizado <b>matrícula parcial</b>) en los dos primeros años matriculados.
            </p>

            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Qué sucede si no cumples alguno de los requisitos">
                {requirements}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="¿Todavía tienes dudas?">
                <ExternalLink text="Ayuda Permanencia" url="https://www.uam.es/Ayudapermanencia/" color={color} secondary={secondary} />
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const exceptional = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Convocatoria excepcional</h3>
          <div className="actual-text">
            <p className="text-normal">
              En circunstancias extraordinarias, cuando un estudiante ha <b>agotado las 3 matrículas</b> contempladas por la normativa en alguna asignatura,
              podrá <b>solicitar a la Rectora una convocatoria excepcional</b> justificando documentalmente la existencia de <b>circunstancias especiales</b> o
              situaciones que hayan podido afectar a su vida académica.
            </p>
            <p className="text-normal">
              En el caso de encontrarte en esta situación, deberás realizar la <b>solicitud a través de la Sede Electrónica</b>, donde también encontrarás
              información sobre la{' '}
              <b>
                documentación que es necesario presentar, los plazos de presentación de solicitudes y recursos, las vías de notificación y los tipos de
                resoluciones que puedes recibir.
              </b>
            </p>
            <p className="text-normal">
              La convocatoria excepcional se te <b>concederá automáticamente</b>, no teniendo que ser solicitada,{' '}
              <b>cuando hayas superado el 70% de los créditos</b> de la titulación y siempre que el <b>número de asignaturas</b> por las que deba solicitarse
              sea <b>3 o menos</b>.
            </p>

            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />

            <DidYouKnow color={color} title="¡Atención!">
              <p className="text-normal">
                Cuando se te concede <b>permanencia o convocatoria excepcional</b>, debes <b>hacer efectiva la matrícula en el curso académico siguiente</b> a
                menos que, excepcionalmente, solicites por Registro un aplazamiento y te sea concedido.
              </p>
              <div className="urbana exclamation">!</div>
            </DidYouKnow>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="¿Todavía tienes dudas?">
                <p className="text-normal">Utiliza esta herramienta para determinar que pasos debes tomar para continuar con tus estudios</p>
                <ExternalLink text="Ayuda Permanencia" url="https://www.uam.es/Ayudapermanencia/" color={color} secondary={secondary} />
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const credits = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Reconocimiento de créditos</h3>
          <div className="actual-text">
            <p className="text-normal">
              Aunque existen algunos límites según la titulación, puedes <b>solicitar que se te reconozcan créditos</b> obtenidos en titulaciones de la UAM o de
              cualquier otra universidad, de enseñanzas superiores oficiales, por experiencia profesional acreditada o por{' '}
              <b>actividades de carácter extracurricular</b> tales como:
            </p>

            <div className="kite-list-container">
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Cursos y seminarios.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Idiomas distintos al castellano (que no estudies en la titulación actual).</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Coro y orquesta.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Actividades deportivas.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Cursos y programas de universidades de la Alianza CIVIS.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Actividades solidarias y de cooperación.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Becas de formación.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Participación en tareas académicas y de gestión.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Colaboración activa en eventos académicos y/o de investigación.</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Actividades formativas asociadas a la participación voluntaria en investigaciones.</p>
              </ListItem>
            </div>

            <DidYouKnow color={color}>
              <p className="text-normal">
                Si necesitas realizar un <b>cambio de género</b> en tu documentación académica, en la UAM <b>existe un procedimiento que puedes solicitar</b> a
                través del Vicerrectorado de Estudiantes y Empleabilidad o UAM Estudiantes.
              </p>
            </DidYouKnow>

            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const compensation = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Compensación curricular</h3>
          <div className="actual-text">
            <p className="text-normal">
              Si <b>solo te falta una asignatura</b> para obtener el Grado y cumples determinados requisitos, puedes <b>presentar una solicitud</b> para que un
              tribunal valore tu caso y decida si conceder un <b>“aprobado por compensación”</b> para completar tu titulación. En el caso de que tu solicitud
              sea concedida, esto será reflejado en tu expediente. <b>Solo podrás compensar asignaturas que sean básicas u obligatorias</b>, no así optativas,
              prácticas externas o el Trabajo de Fin de Grado. Ten en cuenta que la compensación es un <b>procedimiento excepcional</b> y para el que deben
              darse una serie de circunstancias especiales.
            </p>
            <ExternalLink
              url="https://www.uam.es/uam/estudios/grado/matricula/normativa-academica"
              text="Revisa toda la normativa aquí"
              color={secondary}
              secondary={color}
            />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const terminology = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Terminología importante</h3>
          <div className="actual-text">{whatMeans}</div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'matricula') isDesktop ? setModal(matricula) : modalHandling(matricula)
    else if (modalContent === 'academic') isDesktop ? setModal(academic) : modalHandling(academic)
    else if (modalContent === 'perm') isDesktop ? setModal(perm) : modalHandling(perm)
    else if (modalContent === 'exceptional') isDesktop ? setModal(exceptional) : modalHandling(exceptional)
    else if (modalContent === 'credits') isDesktop ? setModal(credits) : modalHandling(credits)
    else if (modalContent === 'compensation') isDesktop ? setModal(compensation) : modalHandling(compensation)
    else if (modalContent === 'terminology') isDesktop ? setModal(terminology) : modalHandling(terminology)
    else isDesktop && setModalContent('matricula')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/Norms_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/Norms_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/Norms.png" className="image" alt="" />
      </animated.div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop Norms ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Normativa</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'matricula' ? 'selected' : ''}`}
                              onClick={() => setModalContent('matricula')}
                            >
                              1. Matrícula{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'perm' ? 'selected' : ''}`} onClick={() => setModalContent('perm')}>
                              2. Permanencia{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'exceptional' ? 'selected' : ''}`}
                              onClick={() => setModalContent('exceptional')}
                            >
                              3. Convocatoria excepcional{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'credits' ? 'selected' : ''}`}
                              onClick={() => setModalContent('credits')}
                            >
                              4. Reconocimiento de créditos{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'compensation' ? 'selected' : ''}`}
                              onClick={() => setModalContent('compensation')}
                            >
                              5. Compensación curricular{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'academic' ? 'selected' : ''}`}
                              onClick={() => setModalContent('academic')}
                            >
                              6. Evaluación académica{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'terminology' ? 'selected' : ''}`}
                              onClick={() => setModalContent('terminology')}
                            >
                              7. Terminología importante{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="norms-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`Norms main-page ${color}`} id="norms">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container">{Splash}</div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Normativa</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="norms-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Normativa</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('matricula')}>
                      <span>1. Matrícula</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('perm')}>
                      <span>2. Permanencia</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('exceptional')}>
                      <span>3. Convocatoria excepcional</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('credits')}>
                      <span>4. Reconocimiento de créditos</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('compensation')}>
                      <span>5. Compensación curricular</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('academic')}>
                      <span>6. Evaluación académica</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('terminology')}>
                      <span>7. Terminología importante</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Norms
