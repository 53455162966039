import React from 'react'
import { colorToHex } from '../../data'

function RobotArm({ color = 'red', width = 40 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} fill="none" viewBox="0 0 48 48">
      <path
        stroke={colorToHex(color)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M42 18h-8l-6-6 6-6h8M5 35a2 2 0 012-2h34a2 2 0 012 2v7H5v-7z"
      ></path>
      <path stroke={colorToHex(color)} strokeWidth="4" d="M8 16a4 4 0 100-8 4 4 0 000 8z"></path>
      <path stroke={colorToHex(color)} strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M12 12h16m-18 4l8 17"></path>
    </svg>
  )
}

export default RobotArm
