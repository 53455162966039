import React from 'react'
import { colorToHex } from '../../data'

const Santander = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 39)} viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1551 18.0125C29.1019 16.6203 28.7024 15.2547 28.01 14.0231L21.7517 3.15264C21.2723 2.32262 20.9527 1.43906 20.7663 0.501953L20.5 0.957121C18.9554 3.66135 18.9554 6.9814 20.5 9.68563L25.5067 18.4141C27.0513 21.1184 27.0513 24.4384 25.5067 27.1427L25.2403 27.5978C25.0539 26.6607 24.7344 25.7772 24.255 24.9739L19.6744 16.9683L16.745 11.8811C16.2656 11.0511 15.9461 10.1676 15.7597 9.25724L15.4933 9.71241C13.9487 12.3899 13.9487 15.7099 15.4667 18.4141L20.5 27.1427C22.0446 29.8469 22.0446 33.1669 20.5 35.8712L20.2337 36.3263C20.0473 35.3892 19.7011 34.5057 19.2483 33.7024L12.99 22.7784C12.1644 21.3326 11.765 19.6993 11.8449 18.0393C5.13382 19.7796 0.5 23.5281 0.5 27.8656C0.5 33.8899 9.44807 38.7628 20.5 38.7628C31.5519 38.7628 40.5 33.8899 40.5 27.8656C40.5 23.5281 35.8662 19.7796 29.1551 18.0125Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Santander
