import React from 'react'
import { colorToHex } from '../../data'

const Kite = ({ color = 'orange', width = 22 }) => {
  return (
    <svg width={width} height={width * (19 / 22)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0049 2.28471L3.27015 3.63667L2.11989 3.73295L2.03019 4.895L1.15503 15.2566L1.01611 16.6195L2.39296 16.741L12.4182 17.5965L13.3181 17.6702L13.7368 16.8619L20.3203 4.31127L21.4904 2.08497L19.0049 2.28471Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Kite
