import React from 'react'
import { colorToHex } from '../../data'

const Medical = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1664 5.02441C22.6084 5.02441 23.0324 5.20001 23.3449 5.51257C23.6575 5.82513 23.8331 6.24905 23.8331 6.69108V14.2494L30.3797 10.4711C30.7625 10.2501 31.2175 10.1902 31.6444 10.3046C32.0714 10.419 32.4354 10.6983 32.6564 11.0811L34.3231 13.9677C34.5441 14.3505 34.604 14.8055 34.4896 15.2324C34.3752 15.6594 34.0959 16.0234 33.7131 16.2444L27.1681 20.0244L33.7131 23.8061C34.0959 24.0271 34.3752 24.3911 34.4896 24.8181C34.604 25.245 34.5441 25.6999 34.3231 26.0827L32.6564 28.9694C32.4354 29.3522 32.0714 29.6315 31.6444 29.7459C31.2175 29.8603 30.7625 29.8004 30.3797 29.5794L23.8331 25.7977V33.3577C23.8331 33.7998 23.6575 34.2237 23.3449 34.5363C23.0324 34.8488 22.6084 35.0244 22.1664 35.0244H18.8331C18.3911 35.0244 17.9671 34.8488 17.6546 34.5363C17.342 34.2237 17.1664 33.7998 17.1664 33.3577V25.7977L10.6197 29.5777C10.2369 29.7988 9.78204 29.8586 9.35508 29.7442C8.92813 29.6298 8.5641 29.3505 8.34308 28.9677L6.67641 26.0811C6.45541 25.6983 6.39552 25.2434 6.50991 24.8164C6.62431 24.3895 6.90362 24.0254 7.28641 23.8044L13.8314 20.0244L7.28641 16.2444C6.90362 16.0234 6.62431 15.6594 6.50991 15.2324C6.39552 14.8055 6.45541 14.3505 6.67641 13.9677L8.34308 11.0811C8.5641 10.6983 8.92813 10.419 9.35508 10.3046C9.78204 10.1902 10.2369 10.2501 10.6197 10.4711L17.1664 14.2494V6.69108C17.1664 6.24905 17.342 5.82513 17.6546 5.51257C17.9671 5.20001 18.3911 5.02441 18.8331 5.02441H22.1664Z"
        stroke={colorToHex(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Medical
