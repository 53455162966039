import React from 'react'
import { colorToHex } from '../../data'

const Microscope = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.2425 30.0247C32.9892 28.2326 33.1894 26.2597 32.818 24.3541C32.4466 22.4485 31.5201 20.6952 30.155 19.3147C30.5306 18.3682 30.6019 17.3281 30.3588 16.3392C30.1157 15.3503 29.5704 14.4617 28.7987 13.7972L32.2688 10.3272C32.7372 9.85803 33.0003 9.22211 33.0003 8.5591C33.0003 7.89608 32.7372 7.26017 32.2688 6.79097L28.7337 3.25597C28.2646 2.78752 27.6286 2.52441 26.9656 2.52441C26.3026 2.52441 25.6667 2.78752 25.1975 3.25597L8.7325 19.721C8.26436 20.1903 8.00146 20.8262 8.00146 21.4891C8.00146 22.152 8.26436 22.7879 8.7325 23.2572L12.2675 26.7922C12.7367 27.2607 13.3726 27.5238 14.0356 27.5238C14.6986 27.5238 15.3346 27.2607 15.8038 26.7922L21.7725 20.8235C22.6189 21.8023 23.8171 22.408 25.1072 22.5091C26.3973 22.6101 27.6752 22.1985 28.6637 21.3635C29.6914 22.5392 30.3226 24.0088 30.4675 25.5636C30.6125 27.1184 30.2639 28.6793 29.4713 30.0247H20.5V35.0247H5.5V37.5247H35.5V30.0247H32.2425ZM14.035 25.0247L10.5 21.4885L12.8487 19.141L16.3837 22.676L14.035 25.0247ZM20.5 17.5247C20.5018 17.8404 20.5336 18.1551 20.595 18.4647L18.1513 20.9085L14.6163 17.3735L26.9663 5.02347L30.5 8.55847L26.4387 12.621C25.7156 12.4775 24.9697 12.4966 24.2549 12.677C23.5401 12.8574 22.8744 13.1944 22.3059 13.6638C21.7375 14.1332 21.2805 14.7231 20.9682 15.3909C20.6558 16.0587 20.4959 16.7875 20.5 17.5247ZM25.5 20.0247C25.0055 20.0247 24.5222 19.8781 24.1111 19.6034C23.7 19.3287 23.3795 18.9382 23.1903 18.4814C23.0011 18.0246 22.9516 17.5219 23.048 17.037C23.1445 16.552 23.3826 16.1066 23.7322 15.757C24.0819 15.4073 24.5273 15.1692 25.0123 15.0728C25.4972 14.9763 25.9999 15.0258 26.4567 15.215C26.9135 15.4042 27.304 15.7247 27.5787 16.1358C27.8534 16.5469 28 17.0303 28 17.5247C27.9993 18.1876 27.7357 18.8231 27.267 19.2918C26.7983 19.7605 26.1628 20.0241 25.5 20.0247ZM33 35.0247H23V32.5247H33V35.0247Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Microscope
