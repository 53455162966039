import React from 'react'

const Bike = ({ width = 42 }) => {
  return (
    <svg width={width} height={width * (width / 31)} viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0004 0.500199C19.1194 0.472927 19.1194 3.25473 21.0004 3.22745H25.1743V6.68016L17.3747 13.0456L14.6644 5.95471H15.4352C17.3163 5.98198 17.3163 3.20018 15.4352 3.22745H9.86999C7.98895 3.20018 7.98895 5.98198 9.86999 5.95471H11.6843L14.1636 12.4319C12.833 11.7685 11.3628 11.4183 9.86999 11.4092C4.5079 11.4092 0.130859 15.6992 0.130859 20.9546C0.130859 26.21 4.5079 30.5 9.86999 30.5C15.2321 30.5 19.6091 26.21 19.6091 20.9546C19.6091 19.1273 19.0526 17.4337 18.1343 15.9801L25.9841 9.57105L27.2724 12.7347C24.3674 14.3928 22.3917 17.4392 22.3917 20.9546C22.3917 26.21 26.7688 30.5 32.1309 30.5C37.4929 30.5 41.87 26.21 41.87 20.9546C41.87 15.6992 37.4929 11.4092 32.1309 11.4092C31.3434 11.4092 30.5893 11.5265 29.8547 11.701L27.9569 7.05106V1.86383C27.9569 1.50217 27.8104 1.15533 27.5494 0.899597C27.2885 0.643867 26.9346 0.500199 26.5656 0.500199H21.0004ZM9.86999 14.1365C11.4457 14.1403 12.9726 14.6725 14.1969 15.6446L8.97956 19.9046C7.55764 21.0692 9.33573 23.1637 10.7604 22.0046L15.9778 17.7419C16.5009 18.7019 16.8265 19.7873 16.8265 20.9546C16.8265 24.7373 13.7295 27.7727 9.86999 27.7727C6.01051 27.7727 2.91347 24.7373 2.91347 20.9546C2.91347 17.1719 6.01051 14.1365 9.86999 14.1365ZM32.1309 14.1365C35.9903 14.1365 39.0874 17.1719 39.0874 20.9546C39.0874 24.7373 35.9903 27.7727 32.1309 27.7727C28.2714 27.7727 25.1743 24.7373 25.1743 20.9546C25.1743 18.5628 26.4182 16.4765 28.3048 15.2601L30.8369 21.4619C31.5215 23.161 34.1204 22.1437 33.4248 20.4473L30.8982 14.2565C31.2961 14.191 31.7079 14.1365 32.1309 14.1365Z"
        fill="#EFA92C"
      />
    </svg>
  )
}

export default Bike
