import React from 'react'
import { colorToHex } from '../../data'

const Statistics = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 41)} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.834 20.0247C33.4333 20.0276 33.0364 20.1027 32.6623 20.2464L28.6657 16.9747C28.764 16.6181 28.834 16.2481 28.834 15.8581C28.834 13.5481 26.9773 11.6914 24.6673 11.6914C22.3573 11.6914 20.5007 13.5481 20.5007 15.8581C20.5007 16.5181 20.6673 17.1331 20.9373 17.6864L16.089 23.4164C15.8949 23.3801 15.6981 23.3606 15.5007 23.3581C15.2023 23.3581 14.9207 23.4081 14.6473 23.4814L10.3757 19.2097C10.4507 18.9381 10.5007 18.6564 10.5007 18.3581C10.5007 16.5114 9.01398 15.0247 7.16732 15.0247C5.32065 15.0247 3.83398 16.5114 3.83398 18.3581C3.83398 20.2047 5.32065 21.6914 7.16732 21.6914C7.46565 21.6914 7.74732 21.6414 8.02065 21.5681L12.2923 25.8397C12.2122 26.1166 12.1702 26.4032 12.1673 26.6914C12.1673 28.5381 13.654 30.0247 15.5007 30.0247C17.3473 30.0247 18.834 28.5381 18.834 26.6914C18.834 26.2964 18.754 25.9247 18.629 25.5731L23.484 19.8364C23.8623 19.9464 24.254 20.0247 24.6673 20.0247C25.3251 20.0211 25.9724 19.8605 26.5557 19.5564L30.554 22.8281C30.5226 23.0031 30.5048 23.1803 30.5007 23.3581C30.5007 25.2047 31.9873 26.6914 33.834 26.6914C35.6807 26.6914 37.1673 25.2047 37.1673 23.3581C37.1673 21.5114 35.6807 20.0247 33.834 20.0247Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Statistics
