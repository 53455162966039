import React from 'react'
import { colorToHex } from '../../data'

const Phone = ({ color = 'orange', width = 20 }) => {
  return (
    <svg width={width} height={width * (width / 22)} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1276 3.4627L16.4651 0.554797C16.1401 0.199508 15.7051 0 15.2451 0C14.7851 0 14.3501 0.196775 14.0251 0.554797L11.1575 3.68407C10.8325 4.03936 10.65 4.51764 10.65 5.02051C10.65 5.52611 10.83 5.99892 11.1575 6.35694L13.3976 8.80843C12.8873 10.1082 12.1541 11.2892 11.2375 12.2875C10.32 13.296 9.24754 14.0913 8.05753 14.6543L5.81753 12.2028C5.49252 11.8475 5.05752 11.648 4.59752 11.648C4.37063 11.6471 4.14585 11.6957 3.93636 11.791C3.72686 11.8862 3.53687 12.0262 3.37751 12.2028L0.507502 15.3321C0.182501 15.6874 0 16.1656 0 16.6685C0 17.1741 0.180001 17.6469 0.507502 18.0049L3.16751 20.9129C3.72252 21.5196 4.48752 21.8667 5.27252 21.8667C5.43502 21.8667 5.59252 21.853 5.75253 21.8229C9.06254 21.2271 12.3476 19.3004 15.0001 16.4034C17.6501 13.501 19.4101 9.90983 19.9601 6.28861C20.1176 5.26101 19.8026 4.20334 19.1276 3.4627ZM18.1876 5.96339C17.7001 9.18559 16.1151 12.3996 13.7276 15.0096C11.34 17.6196 8.40254 19.3523 5.45502 19.8852C5.08502 19.9536 4.70502 19.8169 4.43502 19.5245L1.82251 16.6685L4.59252 13.6376L7.58753 16.9172L7.61003 16.9418L8.15003 16.7232C9.78736 16.0651 11.2742 15.0257 12.5075 13.6769C13.7409 12.3282 14.6912 10.7024 15.2926 8.91229L15.4926 8.32196L12.4726 5.02324L15.2426 1.99235L17.8551 4.84833C18.1251 5.14349 18.2501 5.55891 18.1876 5.96339Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Phone
