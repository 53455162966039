import './Menu.scss'

import React, { useEffect } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

const Menu = ({ open, handle }) => {
  useEffect(() => {
    if (open) disableBodyScroll()
    else clearAllBodyScrollLocks()
    return () => clearAllBodyScrollLocks()
  }, [open])

  const kiteIcon = (number, color) => (
    <div className="kite-icon-container">
      <div className="img-container">
        <img src="/svg/icons/kite-border.svg" alt="" />
      </div>
      <div className={`number-container bold montserrat ${color}`}>{number}</div>
    </div>
  )

  return (
    <div className={`Menu ${open ? 'open' : 'close'}`}>
      <div className="menu-background">
        <div className="img-container big">
          <img src="/svg/menu-background-1.svg" alt="" />
        </div>
        <div className="img-container small">
          <img src="/svg/menu-background-2.svg" alt="" />
        </div>
      </div>
      <div className="menu-container">
        <ul className="menu-list uppercase text-title">
          <li
            onClick={() => handle('first-steps')}
            style={{
              animationDelay: 0.2 + 's'
            }}
          >
            {kiteIcon(1, 'orange')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored orange">Primeros</span> pasos en la UAM
            </p>
          </li>
          <li
            onClick={() => handle('digital-environment')}
            style={{
              animationDelay: 0.3 + 's'
            }}
          >
            {kiteIcon(2, 'dark-blue')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored dark-blue">Configura</span> tu entorno digital
            </p>
          </li>
          <li
            onClick={() => handle('studies-center')}
            style={{
              animationDelay: 0.4 + 's'
            }}
          >
            {kiteIcon(3, 'green')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored green">Conoce</span> tu centro de estudios
            </p>
          </li>
          <li
            onClick={() => handle('who-can-help')}
            style={{
              animationDelay: 0.5 + 's'
            }}
          >
            {kiteIcon(4, 'light-blue')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored light-blue">Averigua</span> quién puede ayudarte
            </p>
          </li>
          <li
            onClick={() => handle('resources')}
            style={{
              animationDelay: 0.6 + 's'
            }}
          >
            {kiteIcon(5, 'red')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored red">Aprovecha</span> los recursos de la UAM
            </p>
          </li>
          <li
            onClick={() => handle('live-autonoma')}
            style={{
              animationDelay: 0.7 + 's'
            }}
          >
            {kiteIcon(6, 'dark-green')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored dark-green">Vive</span> la Autónoma
            </p>
          </li>
          <li
            onClick={() => handle('manage-day-by-day')}
            style={{
              animationDelay: 0.8 + 's'
            }}
          >
            {kiteIcon(7, 'purple')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored purple">Gestiona</span> tu día a día
            </p>
          </li>
          <li
            onClick={() => handle('professional-profile')}
            style={{
              animationDelay: 0.9 + 's'
            }}
          >
            {kiteIcon(8, 'dark-teal')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored dark-teal">Completa</span> tu perfil profesional
            </p>
          </li>
          <li
            onClick={() => handle('norms')}
            style={{
              animationDelay: 1.0 + 's'
            }}
          >
            {kiteIcon(9, 'brown')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored brown">Normativa</span>
            </p>
          </li>
          <li
            onClick={() => handle('after-grade')}
            style={{
              animationDelay: 1.1 + 's'
            }}
          >
            {kiteIcon(10, 'orange')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored orange">Más allá</span> del grado
            </p>
          </li>
          <li
            onClick={() => handle('utilities')}
            style={{
              animationDelay: 1.2 + 's'
            }}
          >
            {kiteIcon(11, 'blue')}
            <p className="heroic text-medium italic menu-item">
              <span className="colored blue">Utilidades</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Menu
