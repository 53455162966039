import React from 'react'
import { colorToHex } from '../../data'

const Plane = ({ color = 'red', width = 53 }) => {
  return (
    <svg width={width} height={width * (width / 54)} viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.6111 15.3214C32.4509 15.4816 31.7652 16.1767 31.5296 16.4395L28.2161 19.9323L10.8249 18.2366L7.12487 21.9367L21.2262 27.25L15.5787 33.0673L9.78285 32.4884L6.71313 35.5581L13.4812 39.8979C13.5219 39.9209 13.5637 39.9416 13.6062 39.9605C13.6099 39.9625 13.613 39.9656 13.615 39.9693C13.6334 40.0119 13.6541 40.0534 13.677 40.0937L18.0192 46.8972L21.0695 43.8469L20.5034 37.926L26.332 32.3535L31.6566 46.4695L35.3678 42.7583L33.6639 25.3848L37.1579 22.0725C37.4241 21.8333 38.1187 21.1482 38.2754 20.9915C39.7098 19.5571 40.6863 18.1867 41.1772 16.9174C42.0118 14.7665 41.2001 13.5095 40.6422 12.9516C38.8695 11.1789 35.8676 12.0649 32.6111 15.3214Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Plane
