import './AfterGrade.scss'

import * as lottie_posgrade from '../../lotties/AfterGrade/posgrade.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const AfterGrade = ({
  color = 'yellow',
  secondary = 'dark-blue',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  // https://assets7.lottiefiles.com/private_files/lf30_Pc7YxP.json
  const lottiePosgrade = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_posgrade.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const threeMins = (
    <div className="desplegable-container three-mins">
      <p className="text-normal">
        Es una serie de <span className="bold">micro-entrevistas en vídeo</span> en las que <span className="bold">titulados UAM</span> nos cuentan cómo han
        llegado a dónde están después de terminar los estudios que tú acabas de comenzar. Sus <span className="bold">consejos</span> pueden ser una gran ayuda
        durante tus años de estudiante.
      </p>
      <div className="video-container">
        <iframe
          title="Micro Entrevistas"
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/?listType=playlist&list=PLGvERyy5OJeaR1wmrI44i4SCeXb2MofRa"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )

  // Modals area
  const posgrado = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Estudios de máster</h3>
          <div className="actual-text">
            {lottiePosgrade}
            <p className="text-normal">
              La UAM oferta más de <b>80 Másteres oficiales</b> entre los que, a buen seguro, podrás encontrar el que te aportará la formación de calidad que
              potencie e impulse tu perfil.
            </p>
            <p className="text-normal">
              Todos ellos están diseñados para sacar el máximo partido a los estudios de Grado, ofreciendo capacidades y un nivel de especialización que sin
              duda representarán un gran valor añadido en tu trayectoria. De hecho, <b>existen másteres recomendados desde cada Grado o Doble Grado UAM</b> que
              puedes consultar en la ficha web de los mismos.
            </p>
            <DidYouKnow color={color}>
              <p className="text-normal">
                Los máster Erasmus Mundus son programas de estudio <b>internacionales</b> impartidos conjuntamente por un consorcio internacional de
                instituciones de educación superior y cuentan con un <b>gran prestigio</b>.
              </p>
            </DidYouKnow>
            <p className="text-normal">
              Los másteres oficiales, por lo general, incluyen distintas <b>especialidades o itinerarios</b> y pueden tener un enfoque bien hacia la{' '}
              <b>capacitación profesional</b> o hacia la <b>formación investigadora</b>. Estas son opciones que tendrás que elegir de acuerdo a tus intereses.
            </p>
            <ExternalLink
              url="https://uam.es/CentroEstudiosPosgrado/Home/1446755956185.htm?language=es_ES"
              text="Más información"
              color={secondary}
              secondary={color}
            />
            <AddressBookComp color={color} data={contacts['posgrado']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const own_titles = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Títulos propios</h3>
          <div className="actual-text">
            <p className="text-normal">
              Los títulos o estudios propios son programas diseñados por la Universidad Autónoma de Madrid en colaboración con profesionales de alto prestigio,
              para dar respuesta a necesidades laborales y sociales. Los estudios propios <b>no dan acceso a programas de doctorado</b>, pero ofrecen una
              formación muy <b>valorada y avalada por el prestigio y la calidad UAM</b>.
            </p>
            <DidYouKnow color={color} title="!">
              <p className="text-normal">
                Recuerda que el Centro de Formación Continua UAM también ofrece <b>cursos de corta duración</b> que puedes cursar en cualquier momento de la
                vida.
              </p>
            </DidYouKnow>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const doctorate = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Doctorado</h3>
          <div className="actual-text">
            <p className="text-normal">
              La Escuela de <b>Doctorado UAM</b> es un centro clave en la estrategia de la universidad para impulsar la formación de investigadores.
            </p>
            <p className="text-normal">
              Desde su creación, la Autónoma ha venido desarrollando una <b>actividad investigadora</b> de muy alta calidad, que la ha convertido en una
              <b>universidad de referencia internacional</b> con una significativa relevancia en los rankings más prestigiosos.
            </p>
            <p className="text-normal">
              La oferta académica agrupa <b>36 programas de doctorado</b> en las áreas de Artes y Humanidades, Ciencias, Ciencias de la Salud, Ciencias Sociales
              y Jurídicas e Ingeniería.
            </p>
            <DidYouKnow color={color}>
              <p className="text-normal">
                El Programa{' '}
                <b>
                  <i>Aula Abierta</i>
                </b>{' '}
                te permite cursar de manera independiente <b>casi cualquier materia</b> impartida en titulaciones oficiales de la Universidad. Esta alternativa
                te dará la posibilidad de <b>profundizar en áreas específicas</b> del conocimiento y de mantener el desarrollo formativo en cualquier momento de
                la vida. Además, al completar asignaturas en el marco de este programa, podrás obtener un <b>certificado acreditativo</b>.
              </p>
            </DidYouKnow>
            <AddressBookComp color={color} data={contacts['doctorado']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const alumniuam = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Alumniuam</h3>
          <div className="actual-text">
            <p className="text-normal">
              ... y cuando finalices tus estudios en la UAM podrás formar parte de AlumniUAM, un{' '}
              <span className="bold">programa para todas las personas tituladas en la Autónoma</span> que ofrece interesantes{' '}
              <span className="bold">beneficios y oportunidades:</span>{' '}
            </p>

            <div className="kite-list-items">
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Te pondrá en contacto con otros profesionales</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Podrás participar en actividades exclusivas</p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">Nos permitirá mantener una relación continuada</p>
              </ListItem>
            </div>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="3 min con...">
                {threeMins}
              </Desplegable>
            </div>
            <AddressBookComp color={color} data={contacts['alumniuam']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'posgrado') isDesktop ? setModal(posgrado) : modalHandling(posgrado)
    else if (modalContent === 'own_titles') isDesktop ? setModal(own_titles) : modalHandling(own_titles)
    else if (modalContent === 'doctorate') isDesktop ? setModal(doctorate) : modalHandling(doctorate)
    else if (modalContent === 'alumniuam') isDesktop ? setModal(alumniuam) : modalHandling(alumniuam)
    else isDesktop && setModalContent('posgrado')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/AfterGrade_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/AfterGrade_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/AfterGrade.png" className="image" alt="" />
      </animated.div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop AfterGrade ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Más allá del grado</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'posgrado' ? 'selected' : ''}`}
                              onClick={() => setModalContent('posgrado')}
                            >
                              1. Estudios de máster{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'own_titles' ? 'selected' : ''}`}
                              onClick={() => setModalContent('own_titles')}
                            >
                              2. Títulos propios{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'doctorate' ? 'selected' : ''}`}
                              onClick={() => setModalContent('doctorate')}
                            >
                              3. Doctorado{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'alumniuam' ? 'selected' : ''}`}
                              onClick={() => setModalContent('alumniuam')}
                            >
                              4. Alumniuam{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="after-grade-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`AfterGrade main-page ${color}`} id="after-grade">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container">{Splash}</div>
              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Más allá del grado</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="after-grade-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Más allá del grado</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('posgrado')}>
                      <span>1. Estudios de posgrado</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('own_titles')}>
                      <span>2. Títulos propios</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('doctorate')}>
                      <span>3. Doctorado</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('alumniuam')}>
                      <span>4. Alumniuam</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AfterGrade
