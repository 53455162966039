import './LiveAutonoma.scss'

import * as lottie_associations from '../../lotties/LiveAutonoma/associations.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import Associations from '../../components/Association/Associations'
import BackContainer from '../../components/BackContainer/BackContainer'
import Desplegable from '../../components/Desplegable/Desplegable'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import ListItem from '../../components/ListItem/ListItem'
import LocationPin from '../../components/Icons/LocationPin'
import Lottie from 'react-lottie'
import MailBox from '../../components/MailBox/MailBox'
import Running from '../../components/Icons/Running'
import VisibilitySensor from 'react-visibility-sensor'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const LiveAutonoma = ({
  color = 'dark-green',
  secondary = 'orange',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const activities = [
    'Aikido',
    'Aquagym',
    'Bádminton',
    'Bailes latinos',
    'Tono stretching',
    'Boxeo',
    'Ciclo indoor',
    'Danza contemporánea',
    'Esgrima (espada)',
    'Flamenco',
    'Functional fitness',
    'Karate',
    'Kenpo karate',
    'Kick boxing',
    'Nado universitario',
    'Natación máster',
    'Natación aprendizaje',
    'Natación infantil',
    'Natación terapeútica',
    'Pádel',
    'Pilates',
    'Preparación física',
    'Rugby',
    'Tenis',
    'Tiro con arco',
    'Tonificación',
    'Trabajo de alta intensidad',
    'Triatlón',
    'Voleibol',
    'Waterpolo',
    'Yoga Hatha',
    'Yoga Iyengar',
    'Zumba'
  ]
  const culturalList = [
    {
      url: 'https://www.uam.es/uam/oficina-actividades-culturales/aulas-talleres/aula-teatro',
      name: 'Aula de Teatro'
    },
    { url: '', name: 'Aula de Cerámica' },
    { url: '', name: 'Celebración del Día del Libro' },
    {
      url: 'https://www.uam.es/uam/csipm/ciclo-grandes-autores-interpretes-musica',
      name: 'Ciclo de Grandes Autores e Intérpretes de la Música en el Auditorio Nacional'
    },
    {
      url: 'https://eventos.uam.es/71543/detail/musicalogos.-dialogos-de-musica-y-humanidades..html',
      name: 'Ciclo de conferencias "Musicálogos"'
    },
    { url: '', name: 'Aula de Música' },
    {
      url: 'https://www.uam.es/uam/oficina-actividades-culturales',
      name: 'Concursos de creación: Cuento, Poesía, Teatro breve, Microrrelato, Humor Gráfico'
    },
    {
      url: 'https://www.uam.es/uam/orquesta-coro',
      name: 'Coro y Orquesta'
    },
    { url: '', name: 'Celebración del Día Europeo del Patrimonio Universitario' },
    { url: '', name: 'Noche Europea de las Investigadoras e Investigadores' },
    { url: '', name: 'Semana de la Ciencia y la Innovación' },
    { url: '', name: 'Día Internacional de la Mujer y la Niña en la Ciencia' }
  ]
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [change, setChange] = useState(false)
  const [showBackContainer, setShowBackContainer] = useState(true)
  // Desktop states
  const [openDesktop, setOpenDesktop] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties
  const lottieAssociations = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_associations.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const guidedClasses = (
    <div className="desplegable-container guided">
      <p className="text-normal bold">Cuotas mensuales:</p>
      <div className="prices-container">
        <div className="row">
          <p className="text-normal">Una hora semanal</p>
          <div className="right">
            <div className="arrow-container">
              <img src="/svg/icons/arrow-right.svg" alt="" />
            </div>
            <p className={`text-normal bold colored ${secondary}`}>desde 11,80€</p>
          </div>
        </div>
        <div className="row">
          <p className="text-normal">Dos horas semanales</p>
          <div className="right">
            <div className="arrow-container">
              <img src="/svg/icons/arrow-right.svg" alt="" />
            </div>
            <p className={`text-normal bold colored ${secondary}`}>desde 23,50€</p>
          </div>
        </div>
      </div>

      <div className="activities-container">
        {activities.map((a, i) => (
          <VisibilitySensor key={`activity-${a}-${i}`}>
            {({ isVisible }) => (
              <div className={`activity-box animated-visibility ${a.length > 18 ? 'full' : 'half'} ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
                <p className="text-normal colored dark-green">{a}</p>
              </div>
            )}
          </VisibilitySensor>
        ))}
      </div>

      <p className="text-normal">
        <b>La Tarjeta Deportiva Anual (TDA)</b> es necesaria para tener acceso a las actividades, abonos y competiciones.
      </p>
      <p className="text-normal">Si lo necesitas, realizar estas clases guiadas, te permite solicitar créditos.</p>
    </div>
  )

  const internal = (
    <div className="desplegable-container internal">
      <p className="text-normal">
        También puedes representar a la UAM en <b>ligas internas o campeonatos universitarios de Madrid</b> y el resto de España. La participación en las
        competiciones deportivas es gratuita. Para más información ponte en contacto con el SEFyD a través de:
      </p>
      <MailBox color={secondary} secondary={color} mail="servicio.deportes.interna@uam.es" />
      <MailBox color={secondary} secondary={color} mail="servicio.deportes.externa@uam.es" />
    </div>
  )

  const pitchRental = (
    <div className="desplegable-container pitch-rental">
      <p className="text-normal">
        También puedes hacer uso de las instalaciones <b>por tu cuenta.</b> La <b>reserva y alquiler</b> de pistas que así lo requieran puede hacerse en los{' '}
        <b>polideportivos</b> o bien a través de la <b>oficina virtual:</b>
      </p>
      <ExternalLink color={secondary} secondary={color} url="https://servdep.sefd.uam.es/" text="¡Reserva y alquila pistas!" />

      <p className="text-normal">
        Necesitarás la <b>Tarjeta Deportiva Anual (TDA)</b> o el <b>carné de estudiante</b> y podrás realizar el pago mediante tarjeta de crédito o en efectivo.
        Más información sobre deporte en la UAM:
      </p>
      <ExternalLink color={secondary} secondary={color} url="https://www.uam.es/uam/sefyd" text="¡Obtén más información!" />
    </div>
  )

  const culturalActivities = (
    <div className="desplegable-container cultural">
      {culturalList.map((c) => (
        <ListItem key={c.name} color={color} kiteColor={secondary} url={c.url}>
          <p className="text-normal bold">{c.name}</p>
        </ListItem>
      ))}
    </div>
  )

  const museums = (
    <div className="desplegable-container cultural-sign">
      <p className="text-normal">
        La UAM atesora colecciones patrimoniales de carácter cultural, algunas musealizadas, como el <b>Museo de Mineralogía</b> o el{' '}
        <b>Museo de Artes y Tradiciones Populares</b>. La Autónoma tiene en su Plaza Mayor una Sala de Exposiciones que acoge, entre otras muestras, proyectos
        realizados por artistas contemporáneos sobre la investigación que se realiza en la Universidad.
      </p>

      <ExternalLink url="https://uam.es/uam/vida-uam-cultura" color={secondary} secondary={color} text="Consulta todos los detalles y actividades" />
    </div>
  )

  const howToJoin = (
    <div className="desplegable-container join">
      <p className="text-normal">
        La creación y gestión de asociaciones <b>debe cumplir la normativa</b> y respetar principios básicos de participación y representación democráticas, así
        como <b>no tener ánimo de lucro.</b> Los <b>fines</b> de las asociaciones de la UAM deben ser: la educación superior, la promoción de la cultura, la
        defensa de valores culturales y universitarios, el desarrollo científico, artístico o deportivo, o la preparación para el ejercicio de actividades
        profesionales y de interés social. Y es importante que sean <b>independientes de cualquier grupo político, partido político o confesión religiosa.</b>
      </p>
      <p className="text-normal">
        Las asociaciones son una manera fantástica de participar en la vida universitaria y conocer a gente, además de una oportunidad para adquirir nuevos
        conocimientos y desarrollar intereses paralelos a tus estudios.{' '}
        <b>¡No dejes de echar un vistazo a las posibilidades y unirte a la que más te interese!</b>
      </p>
      {/* <ExternalLink
                url="http://www.uam.es/estudiantes/acceso/participacion/"
                color={secondary}
                secondary={color}
                text="¡Únete a una asociación!"
            /> */}
      <p className="text-normal">
        Para unirte a una asociación, busca su contacto en la página web de tu centro o en los anexos de la versión física de esta guía si has conseguido un
        ejemplar.
      </p>
      {/* <p className="text-normal">Puedes consultar el listado de asociaciones en activo en los anexos de esta guía.</p> */}
    </div>
  )

  const associationsSection = (
    <div className="desplegable-container associations">
      {/* <p className='text-normal'>En esta sección encontrarás una lista de todas las asociaciones a las que puedes unirte y disfrutar de la UAM, haz click en una asociación para saber más y poder inscribirte.</p> */}
      <p className="text-normal">
        A continuación, puedes ver fichas de algunas de las asociaciones que existen en la UAM. En la web de tu centro o de UAM Estudiantes podrás encontrar
        algunas más.
      </p>
      <Associations showBackButton={setShowBackContainer} color={color} secondary={secondary} />
    </div>
  )

  useEffect(() => {
    setChange(!change)
  }, [showBackContainer])

  const dormitory = (
    <div className="desplegable-container dormitory">
      <p className="text-normal">
        Si has llegado a Madrid para estudiar en la UAM puedes vivir en nuestro Colegio Mayor “Juan Luis Vives”, situado <b>junto a Plaza de Castilla.</b>{' '}
        Comprobarás que todo en él está preparado para que <b>te sientas como en casa.</b> Las instalaciones, reconocidas por favorecer la accesibilidad
        universal, los servicios y la actividad diaria están pensadas para convertir tu estancia en una gran experiencia que marcará tu vida para siempre.
        Alojarte en nuestro colegio es disfrutar de <b>espacios acogedores</b> para el estudio, con una biblioteca cálida y luminosa, y{' '}
        <b>habitaciones amplias.</b> Es habitar en un lugar diseñado para la convivencia y el intercambio de experiencias, con un número de{' '}
        <b>espacios comunes de encuentro</b> difícil de encontrar en residencias de estudiantes similares.
      </p>
      <AddressBookComp color={color} data={contacts['juan-luis-vives']} />
    </div>
  )

  const erasmo = (
    <div className="desplegable-container erasmo">
      <p className="text-normal">
        Aunque también está destinada a alojar estudiantes, la Residencia Erasmo, situada en el Campus de Cantoblanco, ofrece habitaciones para{' '}
        <b>estancias diarias y de grupos durante todo el año.</b> En verano todas sus plazas se ponen a disposición de grupos diversos y al público en general
        para alojarse por unos días y/o semanas.
      </p>
      <AddressBookComp color={color} data={contacts['residencia-erasmo']} />
    </div>
  )

  const house = (
    <div className="desplegable-container house">
      <div className="house-of-students-image">
        <img src="/svg/front-pages/live-autonoma/house-of-students.jpeg" alt="Casa de Estudiantes" />
      </div>
      <p className="text-normal">
        En la plaza mayor se encuentra la casa de estudiantes, un espacio <b>gestionado por los propios estudiantes</b>. Cuenta con diversos espacios donde
        reunirte con tus compañeros para jugar a <b>juegos de mesa</b> o al <b>billar</b>, trabajar en la <b>sala de reuniones</b>, descansar en el{' '}
        <b>vagón del silencio</b> e incluso grabar podcasts en la <b>cabina de streaming</b>. Además, a menudo se imparten charlas y talleres.
      </p>
      <p className="text-normal">
        Si una vez conoces la Casa de Estudiantes quieres solicitar la reserva de alguno de sus espacios por una necesidad específica, puedes hacerlo a través
        de este formulario.
        <ExternalLink
          text={'Formulario de reserva'}
          url={'https://outlook.office365.com/owa/calendar/ReservasCasadeEstudiantes@dauam.onmicrosoft.com/bookings/'}
          color={secondary}
          secondary={color}
        />
      </p>
    </div>
  )

  const scientificCulture = (
    <div className="desplegable-container scientific-culture">
      <p className="text-normal">
        La <b>UCCUAM</b> es la encargada de fomentar y apoyar actividades que contribuyan a la comunicación social del conocimiento científico mediante la{' '}
        <b>publicación de contenidos divulgativos</b> y la realización de <b>actividades de divulgación</b>, entre otras.
      </p>
      <AddressBookComp color={color} data={contacts['cultura-cientifica']} />
    </div>
  )

  const sustainability = (
    <div className="desplegable-container scientific-culture">
      <p className="text-normal">
        La Oficina de Sostenibilidad promueve la <b>transición ecológica de la UAM</b> por medio del fomento de la <b>renaturalización</b>, la{' '}
        <b>agroecología</b> y la <b>alimentación sostenible</b> tanto a nivel de campus como de la comunidad universitaria.
      </p>
      <p className="text-normal">
        Estos programas promueven valores como la <b>solidaridad</b>, la <b>responsabilidad</b> y el <b>respeto</b>, al tiempo que fomentan el pensamiento
        crítico y la transformación social. Te permitirán acercarte a otras realidades y vivir una experiencia enriquecedora de trabajo y aprendizaje colectivo
        mientras contribuyes activamente a la construcción de un mundo mejor, según establece el plan de acción para la implementación de la Agenda 2030
        adoptado por la UAM.
      </p>
      <AddressBookComp color={color} data={contacts['sostenibilidad']} />
    </div>
  )

  // Modals area
  const sports = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Deportes</h3>
          <div className="actual-text">
            <div className="running-icon-container">
              <Running />
            </div>
            <p className="text-normal">
              ¿Te gusta el deporte? Tanto si ya lo practicas como si te quieres iniciar, en el Servicio de Deportes de la UAM te lo ponemos fácil:{' '}
              <b>piscina cubierta y al aire libre, polideportivo, gimnasios, pistas de tenis, vóley, rugby</b> ¡y un muy largo etcétera! Además de los deportes
              que puedes practicar por tu cuenta o en equipos universitarios.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Clases guiadas">
                {guidedClasses}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Ligas internas y campeonatos universitarios">
                {internal}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Alquiler y reserva de pistas">
                {pitchRental}
              </Desplegable>
            </div>
            <AddressBookComp color={color} data={contacts['educacion-fisica-y-deporte']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const cultural = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Actividades culturales</h3>
          <div className="actual-text">
            <p className="text-normal">
              La UAM te ofrece un <b>amplio programa</b> de actividades culturales con las que podrás{' '}
              <b>nutrirte de nuevos conocimientos, explorar nuevos ámbitos o desarrollar tus aficiones en el ámbito de la cultura.</b> En el campus siempre
              encontrarás una gran <b>diversidad de actividades</b> en las que participar, algunas surgen cada año y otras ya forman parte de la programación
              continua.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Listado de actividades">
                {culturalActivities}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Museos, colecciones y arte">
                {museums}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Cultura Científica">
                {scientificCulture}
              </Desplegable>
            </div>
            <AddressBookComp color={color} data={contacts['actividades-culturales']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const volunteering = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Voluntariado, Cooperación y Solidaridad</h3>
          <div className="actual-text">
            <VisibilitySensor>
              {({ isVisible }) => (
                <div className={`sostenible-img animated-visibility ${isVisible ? 'show-visible' : 'hidden-visible'}`}>
                  <img src="/svg/logos/ods.png" alt="" />
                </div>
              )}
            </VisibilitySensor>

            <p className="text-normal">
              La <b>Oficina de Acción Solidaria y Cooperación</b> te ofrece la posibilidad de implicarte en proyectos solidarios fomentando el pensamiento
              crítico y la transformación social:
            </p>

            <div className="desplegable-container">
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">
                  Programa de <b>Acción Formativa en Voluntariado Universitario</b>, uniéndote a un grupo de voluntariado. Esa formación incorpora el sello
                  Certifica Plus.
                </p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">
                  Programa de <b>Voluntariado Internacional</b>, colaborando con instituciones en países del Sur Global.
                </p>
              </ListItem>
              <ListItem color={color} kiteColor={secondary}>
                <p className="text-normal">
                  Programa de <b>Prácticas en Cooperación al Desarrollo</b> realizando las prácticas externas curriculares de Grado en el marco de un proyecto
                  de Cooperación al Desarrollo en un país del Sur Global.
                </p>
              </ListItem>
            </div>

            <p className="text-normal">
              Además, podrás informarte de alojamientos solidarios, de proyectos de cooperación al desarrollo, de apoyos a estudiantes refugiados, de proyectos
              y campañas solidarias, etc.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Oficina de Sostenibilidad">
                {sustainability}
              </Desplegable>
            </div>

            <AddressBookComp color={color} data={contacts['accion-solidaria']} />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const associations = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Asociaciones de estudiantes</h3>

          {lottieAssociations}

          <div className="actual-text">
            <p className="text-normal">
              El asociacionismo es un aspecto importante en la vida universitaria. Como estudiante puedes <b>hacerte socio/a de una asociación o crearla</b> si
              todavía no existe (deberás reunir al menos 15 miembros). Las asociaciones de la Universidad reúnen a personas que tienen un{' '}
              <b>interés o afición común</b>, permitiéndoles compartir experiencias y organizar actividades relacionadas. <b>Cada semestre</b>, la UAM abre una{' '}
              <b>convocatoria para la solicitud de ayudas económicas</b> gracias a las cuales asociaciones y estudiantes pueden presentar proyectos para llevar
              a cabo actividades propias y promover su área de interés.
            </p>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Normativa, fines y cómo unirse">
                {howToJoin}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Asociaciones">
                {associationsSection}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && showBackContainer && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const liveTheUam = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Vive la UAM</h3>
          <div className="actual-text">
            <p className="text-normal">
              También puedes vivir en alojamientos de la UAM si lo necesitas en algún momento de tu etapa universitaria, son los siguientes:
            </p>

            <a href="https://g.page/uam_vives?share" target="_blank" rel="noopener noreferrer" className="location-container">
              <div className="pin">
                <LocationPin />
              </div>
              <div className="location-text">
                <p className={`bold colored ${color}`}>Colegio Mayor "Juan Luis Vives"</p>
                {/* <p className="text-normal bold">Calle Francisco Suárez, 7, 28036 Madrid</p> */}
              </div>
            </a>

            <a href="https://goo.gl/maps/DHzKDn1ms3PXd3d68" target="_blank" rel="noopener noreferrer" className="location-container">
              <div className="pin">
                <LocationPin />
              </div>
              <div className="location-text">
                <p className={`bold colored ${color}`}>Residencia ERASMO</p>
                {/* <p className="text-normal bold">Campus Universitario de Cantoblanco, Calle de Erasmo de Rotterdam, 5-7, 28049 Madrid</p> */}
              </div>
            </a>

            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title='Colegio Mayor "Juan Luis Vives"'>
                {dormitory}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Residencia Erasmo">
                {erasmo}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Casa de Estudiantes">
                {house}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'sports') isDesktop ? setModal(sports) : modalHandling(sports)
    else if (modalContent === 'cultural') isDesktop ? setModal(cultural) : modalHandling(cultural)
    else if (modalContent === 'volunteering') isDesktop ? setModal(volunteering) : modalHandling(volunteering)
    else if (modalContent === 'associations') isDesktop ? setModal(associations) : modalHandling(associations)
    else if (modalContent === 'live-the-uam') isDesktop ? setModal(liveTheUam) : modalHandling(liveTheUam)
    else isDesktop && setModalContent('sports')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/LiveAutonoma_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/LiveAutonoma_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/LiveAutonoma.png" className="image" alt="" />
      </animated.div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop LiveAutonoma ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>
          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Vive la Autónoma</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'sports' ? 'selected' : ''}`}
                              onClick={() => setModalContent('sports')}
                            >
                              1. Deportes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'cultural' ? 'selected' : ''}`}
                              onClick={() => setModalContent('cultural')}
                            >
                              2. Actividades culturales{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'volunteering' ? 'selected' : ''}`}
                              onClick={() => setModalContent('volunteering')}
                            >
                              3. Voluntariado{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'associations' ? 'selected' : ''}`}
                              onClick={() => setModalContent('associations')}
                            >
                              4. Asociaciones de estudiantes{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'live-the-uam' ? 'selected' : ''}`}
                              onClick={() => setModalContent('live-the-uam')}
                            >
                              5. Vive en la UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="live-autonoma-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`LiveAutonoma main-page ${color}`} id="live-autonoma">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="live-autonoma">
                {Splash}
              </div>
              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Vive la Autónoma</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="live-autonoma-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Vive la Autónoma</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('sports')}>
                      <span>1. Deportes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('cultural')}>
                      <span>2. Actividades culturales</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('volunteering')}>
                      <span>3. Voluntariado</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('associations')}>
                      <span>4. Asociaciones de estudiantes</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('live-the-uam')}>
                      <span>5. Vive en la UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>
              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LiveAutonoma
