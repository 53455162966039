import React from 'react'
import { colorToHex } from '../../data'

const FolderNur = ({ color = 'yellow', width = 44 }) => {
  return (
    <svg width={width} height={width * (34 / 44)} viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.3004 4.16667H21.9993L17.6741 0H4.69831C2.31941 0 0.394673 1.875 0.394673 4.16667L0.373047 29.1667C0.373047 31.4583 2.31941 33.3333 4.69831 33.3333H39.3004C41.6793 33.3333 43.6256 31.4583 43.6256 29.1667V8.33333C43.6256 6.04167 41.6793 4.16667 39.3004 4.16667ZM26.3246 25H9.02357V20.8333H26.3246V25ZM34.9751 16.6667H9.02357V12.5H34.9751V16.6667Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default FolderNur
