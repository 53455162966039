import React from 'react'
import './KiteTitle.scss'
import Kite from '../Icons/Kite'

const KiteTitle = ({ color = 'light-blue', children }) => {
  return (
    <div className="KiteTitle">
      <p className="text-normal uppercase">{children}</p>
      <div className="kite-container">
        <Kite width={37} color={color} />
      </div>
    </div>
  )
}

export default KiteTitle
