import React from 'react'
import { colorToHex } from '../../data'

const User = ({ color = 'green-uam', width = 44 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66667 44C3.66667 44 0 44 0 40.3333C0 36.6667 3.66667 25.6667 22 25.6667C40.3333 25.6667 44 36.6667 44 40.3333C44 44 40.3333 44 40.3333 44H3.66667ZM22 22C24.9174 22 27.7153 20.8411 29.7782 18.7782C31.8411 16.7153 33 13.9174 33 11C33 8.08262 31.8411 5.28473 29.7782 3.22183C27.7153 1.15893 24.9174 0 22 0C19.0826 0 16.2847 1.15893 14.2218 3.22183C12.1589 5.28473 11 8.08262 11 11C11 13.9174 12.1589 16.7153 14.2218 18.7782C16.2847 20.8411 19.0826 22 22 22Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default User
