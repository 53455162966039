import './ExternalLink.scss'

import Cursor from '../Icons/Cursor'
import Envelope from '../Icons/Envelope'
import Facebook from '../Icons/Facebook'
import Instagram from '../Icons/Instagram'
import Network from '../Icons/Network'
import React from 'react'
import X from '../Icons/X'

const ExternalLink = ({ color = 'purple', secondary = 'yellow', url, text }) => {
  const getIcon = (url) => {
    if (url.includes('x.com')) {
      return <X color={color} />
    }
    if (url.includes('facebook')) {
      return <Facebook color={color} />
    }
    if (url.includes('instagram')) {
      return <Instagram color={color} />
    }
    if (url.includes('@')) {
      return <Envelope color={color} />
    }
    return <Network color={color} />
  }
  return (
    <div className={`ExternalLink ${color}`}>
      <div className="icon-container">{getIcon(url)}</div>
      <a href={url} target="_blank" className={`${secondary} tex-normal bold underline`} rel="noopener noreferrer">
        {text || url}
      </a>
      <div className="cursor-container">
        <Cursor color={secondary} />
      </div>
    </div>
  )
}

export default ExternalLink
