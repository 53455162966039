import Check from '../Icons/Check'
import ContactCard from '../ContactCard/ContactCard'
import React from 'react'
import { contacts } from '../../data'

export default function MapCardContacts() {
  return (
    <div className="contact-block">
      <ContactCard data={contacts['registro-general']} plegable={<Check width={16} color='white'/>} />
      <ContactCard data={contacts['docencia-digital-uam']} plegable="1" />
      <ContactCard data={contacts['uam-estudiantes']} plegable="2" />
      <ContactCard data={contacts['defensoria-universitaria']} plegable="3" />
      <ContactCard data={contacts['unidad-igualdad']} plegable="4" />
      <ContactCard data={contacts['unidad-escucha']} plegable="5" />
      <ContactCard data={contacts['centro-psicologia-aplicada']} plegable="6" />
      <ContactCard data={contacts['unidad-equidad']} plegable="7">
        <ContactCard data={contacts['diversidad-funcional']} />
      </ContactCard>
      <ContactCard data={contacts['becas-y-ayudas']} plegable="8" />
      <ContactCard data={contacts['idiomas']} plegable="9" />
      <ContactCard data={contacts['relaciones-internacionales']} plegable="10" />
      <ContactCard data={contacts['educacion-fisica-y-deporte']} plegable="11">
        <ContactCard data={contacts['competiciones']} />
      </ContactCard>
      <ContactCard data={contacts['actividades-culturales']} plegable="12" />
      <ContactCard data={contacts['cultura-cientifica']} plegable="13" />
      <ContactCard data={contacts['accion-solidaria']} plegable="14" />
      <ContactCard data={contacts['sostenibilidad']} plegable="15" />
      <ContactCard data={contacts['juan-luis-vives']} plegable="16" />
      <ContactCard data={contacts['residencia-erasmo']} plegable="17" />
      <ContactCard data={contacts['cauti']} plegable="18" />
      <ContactCard data={contacts['reprografia']} plegable="19" />
      <ContactCard data={contacts['libreria']} plegable="20" />
      <ContactCard data={contacts['formacion-continua']} plegable="21" />
      <ContactCard data={contacts['practicas-empleabilidad']} plegable="22" />
      <ContactCard data={contacts['uam-emprende']} plegable="23" />
      <ContactCard data={contacts['posgrado']} plegable="24" />
      <ContactCard data={contacts['doctorado']} plegable="25" />
      <ContactCard data={contacts['alumniuam']} plegable="26" />
    </div>
  )
}
