import React from 'react'
import { colorToHex } from '../../data'

const Facebook = ({ width = 11, color = 'orange' }) => {
  return (
    <svg width={width} height={width * (width / 20)} viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.65122 19.4109H7.16722V10.6509H10.1072L10.5512 7.24294H7.16722V5.05894C7.16722 4.07494 7.44322 3.40294 8.85922 3.40294H10.6712V0.342937C10.3592 0.306937 9.29122 0.210938 8.04322 0.210938C5.42722 0.210938 3.65122 1.80694 3.65122 4.72294V7.24294H0.699219V10.6509H3.65122V19.4109Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Facebook
