import React from 'react'
import { colorToHex } from '../../data'

const LocPin = ({ color = 'orange', width = 19 }) => {
  return (
    <svg width={width} height={width * (width / 26)} viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.50191 12.1423C11.3642 12.1423 12.8739 10.6073 12.8739 8.71373C12.8739 6.82018 11.3642 5.28516 9.50191 5.28516C7.63959 5.28516 6.12988 6.82018 6.12988 8.71373C6.12988 10.6073 7.63959 12.1423 9.50191 12.1423Z"
        stroke={colorToHex(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2441 12.1429C13.715 18.1429 9.5 25 9.5 25C9.5 25 5.28497 18.1429 2.75595 12.1429C0.226929 6.14286 4.44196 1 9.5 1C14.558 1 18.7731 6.14286 16.2441 12.1429Z"
        stroke={colorToHex(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocPin
