import './Desplegable.scss'

import React, { useEffect, useRef, useState } from 'react'

import useScreenSize from '../../hooks/screen-size'
import AngleLeft from '../Icons/AngleLeft'

/**
 * Desplegable Component
 * @param {string} color Color of the block
 * @param {boolean=} forceHiding Set to true when you want in Desktop to have the same behaviour than in Mobile
 */
const Desplegable = ({ color, title, children, secondary, forceHiding = false }) => {
  const { isDesktop } = useScreenSize()
  const [desplegable, setDesplegable] = useState(!forceHiding && isDesktop)
  const containerRef = useRef(null)
  const scrollRef = useRef(null)

  useEffect(() => {
    if (desplegable && !isDesktop)
      scrollRef.current.parentElement.scrollTo({
        top: scrollRef.current.offsetTop,
        behavior: 'smooth'
      })
  }, [desplegable, isDesktop])

  return (
    <div ref={scrollRef} className="desplegable">
      <div className={`question ${desplegable ? 'open ' + color : 'close'}`} onClick={() => setDesplegable(!desplegable)}>
        <h4 className="bold text-medium heroic italic uppercase nosearch">{title}</h4>
        <div className={`arrow-container-right`}>
          <AngleLeft color={secondary} />
        </div>
      </div>
      <div className={`question-container ${desplegable ? 'open' : 'closed'}`} ref={containerRef}>
        {children}
      </div>
    </div>
  )
}

export default Desplegable
