import React from "react";
import { colorToHex } from "../../data";

function Check({ width = 41, color = 'gray' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width} height={width * (41 / 41)}
      version="1.1"
      viewBox="0 0 17.837 17.837"
      xmlSpace="preserve"
    >
      <path
        fill={colorToHex(color)}
        d="M16.145 2.571a.7.7 0 00-.99 0L6.92 10.804l-4.241-4.27a.698.698 0 00-.989 0L.204 8.019a.703.703 0 000 .99l6.217 6.258a.704.704 0 00.99 0L17.63 5.047a.7.7 0 000-.994l-1.485-1.482z"
      ></path>
    </svg>
  );
}

export default Check;
