import React, { useState } from 'react'
import './SocialCard.scss'

const SocialCard = ({ title, color, children }) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className={`SocialCard ${color}`} onClick={() => setCollapsed(!collapsed)}>
      <div className="title-container">
        <div className={`uppercase bold montserrat ${!collapsed && 'open'}`}>{title}</div>
      </div>
      <div className={`data-container ${!collapsed && 'open'}`}>{children}</div>
    </div>
  )
}

export default SocialCard
