import React from 'react'
import './CardMockup.scss'
import User from '../Icons/User'

const CardMockup = () => {
  return (
    <div className="CardMockup">
      <p className="university-name text-center text-normal">Universidad Autónoma de Madrid</p>
      <div className="band-container">
        <div className="blue-band" />
        <div className="orange-band" />
      </div>
      <div className="dirt-container">
        <div className="chip">
          <img src="/svg/first-steps/chip.svg" alt="" />
        </div>
        <div className="dirt">
          <User />
        </div>
      </div>
      <div className="student-data">
        <p className="text-small uppercase">Carné de estudiante</p>
        <p className="code text-small">NC: 082</p>
      </div>
    </div>
  )
}

export default CardMockup
